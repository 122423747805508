import React, { useContext, useEffect, useState } from "react";
import { Offcanvas, Button, Form, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  addCustomerAddress,
  updateCustomerAddress,
} from "../../../../application/actions/customerAction";
import Environment from "../../../../infrastructure/core/Environment";
import {
  restrictConsecutiveSpace,
  validateRequired,
  validateRequiredDropdown,
} from "../../../../infrastructure/core/validationUtils";
import AuthContext from "../../../../infrastructure/core/helpers/AuthContext";

export default function AddNewAddress(props) {
  const dispatch = useDispatch();
  const { user, branch } = useContext(AuthContext);
  const [formData, setFormData] = useState({});
  const branchData = JSON.parse(localStorage.getItem("branch"));
  const addressTypeCommonList = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "ADDTY"
      )
    : [];
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (props?.operationValue === "AddNewAddress") {
      setFormData({
        branchId: branch?.value,
        tenantId: user?.tenanatId,
        status: formData?.status,
        isVisible: true,
        isDeleted: false,
        addressType: Environment.defaultValue,
        userId: props?.getCustomerDetails?.id,
        roomNo: "",
        buildingName: "",
        streetName: "",
        landmark: "",
        //location: "",
        zipCode: "",
        city: "",
        state: "",
        country: "",
        isDefault: props?.custDefaultAddress?.id ? formData.isDefault : true,
      });
    } else if (props?.operationValue === "UpdateAddress") {
      if (props?.custDefaultAddress) {
        setFormData({
          branchId: props?.custDefaultAddress?.branchId,
          tenantId: props?.custDefaultAddress?.tenantId,
          status: props?.custDefaultAddress?.status,
          isVisible: props?.custDefaultAddress?.isVisible,
          isDeleted: props?.custDefaultAddress?.isDeleted,
          addressType: props?.custDefaultAddress?.addressType,
          userId: props?.custDefaultAddress?.userId,
          roomNo: props?.custDefaultAddress?.roomNo,
          buildingName: props?.custDefaultAddress?.buildingName,
          streetName: props?.custDefaultAddress?.streetName,
          landmark: props?.custDefaultAddress?.landmark,
          //location: props?.custDefaultAddress?.location,
          zipCode: props?.custDefaultAddress?.zipCode,
          state: props?.custDefaultAddress?.state,
          city: props?.custDefaultAddress?.city,
          country: props?.custDefaultAddress?.country,
          isDefault: props?.custDefaultAddress?.isDefault,
        });
      }
    }
  }, [
    formData.isDefault,
    formData?.status,
    props?.custDefaultAddress?.id,
    props?.getCustomerDetails?.id,
    props?.operationValue,
    props?.custDefaultAddress,
  ]);

  const validateInput = () => {
    const newErrors = {};
    newErrors.addressType = validateRequiredDropdown(formData.addressType) ? "" : "Please Select Address Type";
    newErrors.buildingName = validateRequired(formData.buildingName) ? "" : "Building Name is required";
    newErrors.roomNo = validateRequired(formData.roomNo) ? "" : "Room No. is required";
    newErrors.streetName = validateRequired(formData.streetName) ? "" : "Street or Sector Name is required";
    //newErrors.landmark = validateRequired(formData.landmark) ? "" : "Town / Locality is required";
    //newErrors.location = validateRequired(formData.location) ? "" : "Location is required";
    newErrors.zipCode = validateRequired(formData.zipCode) ? "" : "Zip Code is required";
    newErrors.city = validateRequired(formData.city) ? "" : "City is required";
    newErrors.state = validateRequired(formData.state) ? "" : "State is required";
    newErrors.country = validateRequired(formData.country) ? "" : "Country is required";

    setErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "addressType" && value === Environment.defaultValue) {
      setErrors({
        ...errors,
        [name]: `Please Select Address Type`,
      });
    } else if (value?.trim() === "") {
      setErrors({
        ...errors,
        [name]: `${
          name === "buildingName" ? "Building Name" : 
          name === "roomNo" ? "Room No" : 
          name === "streetName" ? "Street or Sector Name" : 
          name === "landmark" ? "Town / Locality" : 
          //name === "location" ? "Location" : 
          name === "zipCode" ? "Zip Code" : 
          name === "city" ? "City" : 
          name === "state" ? "State" : 
          name === "country" ? "Country" : "This field"
        } is required`,
      });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate the form
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    const formObject = {
      ...(props?.custDefaultAddress?.id && { id: props.custDefaultAddress.id }),
      branchId: branchData?.value,
      status: formData.status,
      isVisible: true,
      isDeleted: false,
      addressType: formData.addressType,
      userId: props.getCustomerDetails.id,
      roomNo: formData.roomNo,
      buildingName: formData.buildingName,
      streetName: formData.streetName,
      landmark: formData.landmark,
      //location: formData.location,
      zipCode: formData.zipCode,
      state: formData.state,
      city: formData.city,
      country: formData.country,
      tenantId: formData.tenantId,
      isDefault: props?.custDefaultAddress?.id ? formData.isDefault : true,
    };
    props?.operationValue === "UpdateAddress"
      ? dispatch(updateCustomerAddress(formObject))
      : dispatch(addCustomerAddress(formObject));
    props.closeFunction();
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-30"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Create New Address
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col sm={12}>
              <div className="mb-3">
                <Form.Label>Address Type <sup class="text-danger">*</sup></Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="addressType"
                  value={formData.addressType}
                  onChange={handleChange}
                >
                  <option value={Environment.defaultValue} disabled>Choose Address Type</option>
                  {addressTypeCommonList?.map((option, index) => (
                    <option key={index} value={option.id}>{option.fieldValue}</option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.addressType}
                </Form.Text>
              </div>
            </Col>

            <Col sm={12}>
              <div className="mb-3">
                <Form.Label>Building Name <sup class="text-danger">*</sup></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Building Name"
                  name="buildingName"
                  value={formData.buildingName}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                  }}
                />
                <Form.Text className="text-danger">
                  {errors.buildingName}
                </Form.Text>
              </div>
            </Col>

            <Col sm={12}>
              <div className="mb-3">
                <Form.Label>Room No <sup class="text-danger">*</sup></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Room No"
                  name="roomNo"
                  value={formData.roomNo}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                  }}
                />
                <Form.Text className="text-danger">{errors.roomNo}</Form.Text>
              </div>
            </Col>

            <Col sm={12}>
              <div className="mb-3">
                <Form.Label>Street or Sector Name <sup class="text-danger">*</sup></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Street or Sector Name"
                  name="streetName"
                  value={formData.streetName}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                  }}
                />
                <Form.Text className="text-danger">
                  {errors.streetName}
                </Form.Text>
              </div>
            </Col>

            <Col sm={12}>
              <div className="mb-3">
                <Form.Label>Town / Locality</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Town / Locality"
                  name="landmark"
                  value={formData.landmark}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                  }}
                />
                <Form.Text className="text-danger">{errors.landmark}</Form.Text>
              </div>
            </Col>

            {/* <Col sm={12}>
              <div className="mb-3">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                  }}
                />
                <Form.Text className="text-danger">{errors.location}</Form.Text>
              </div>
            </Col> */}

            <Col sm={12}>
              <div className="mb-3">
                <Form.Label>Zip Code <sup class="text-danger">*</sup></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Zip Code"
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                  }}
                />
                <Form.Text className="text-danger">{errors.zipCode}</Form.Text>
              </div>
            </Col>

            <Col sm={12}>
              <div className="mb-3">
                <Form.Label>City <sup class="text-danger">*</sup></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter City"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                  }}
                />
                <Form.Text className="text-danger">{errors.city}</Form.Text>
              </div>
            </Col>

            <Col sm={12}>
              <div className="mb-3">
                <Form.Label>State <sup class="text-danger">*</sup></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter State"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                  }}
                />
                <Form.Text className="text-danger">{errors.state}</Form.Text>
              </div>
            </Col>

            <Col sm={12}>
              <div>
                <Form.Label>Country <sup class="text-danger">*</sup></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                  }}
                />
                <Form.Text className="text-danger">{errors.country}</Form.Text>
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>
        <div className="offcanvas-footer justify-content-start">
          <Button
            type="submit"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            onClick={(e) => handleSubmit(e)}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">
              {props?.custDefaultAddress
                ? "Update Address"
                : "Create New Address"}
            </span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
