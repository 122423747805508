import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Form, Row, Col } from "react-bootstrap";
import {
  loadTax,
} from "../../../../application/actions/loaderAction";
import { useDispatch, useSelector } from "react-redux";
import {
  getCatalogFeature,
  getTaxList,
} from "../../../../application/selectors/indexSelector";
import Select from "react-select";
import {
  UpdateCatalogFeatures,
  UpdateItemTaxGroup,
} from "../../../../application/actions/ItemAction";
import { useParams } from "react-router-dom";
import AuthContext from "../../../../infrastructure/core/helpers/AuthContext";

export default function TAXComponent(props) {
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [showItemTax, setShowItemTax] = useState(false);
  const CatalogFeature = useSelector(getCatalogFeature);

  useEffect(() => {
    if (CatalogFeature !== undefined) {
      setFormData({
        ...formData,
        mappingId:CatalogFeature?.id,
        catalogueId: CatalogFeature?.catalogueId,
        isTaxOverride: CatalogFeature?.isTaxOverride,
        isKOT: CatalogFeature?.isKOT,
        isDedicatedKitchen: CatalogFeature?.isDedicatedKitchen,
        isAddOn: CatalogFeature?.isAddOn,
        isCustomized: CatalogFeature?.isCustomized,
        isRecipe: CatalogFeature?.isRecipe,
        isBOM: CatalogFeature?.isBOM,
      });
      setShowItemTax(CatalogFeature?.isTaxOverride);
    }
  }, [CatalogFeature?.isTaxOverride]);

  const handleItemTaxFree = (state) => {
    if (state == true) {
      setFormData({ ...formData, isTaxOverride: true });
      setShowItemTax(true);
      let updatedFormObject = { ...formData, isTaxOverride: true };
      dispatch(UpdateCatalogFeatures(updatedFormObject));
    } else {
      setShowItemTax(false);
      setFormData({ ...formData, isTaxOverride: false });
      let updatedFormObject = { ...formData, isTaxOverride: false };
      dispatch(UpdateCatalogFeatures(updatedFormObject));
    }
  };
  useEffect(() => {
    dispatch(loadTax);
  }, []);
  const taxList = useSelector(getTaxList);
  useEffect(() => {
    if (CatalogFeature && taxList?.length !== 0) {
      const filteredList = taxList?.filter(
        (tax) => tax?.id === CatalogFeature?.taxId
      );
      const selectedTax = filteredList?.map((option) => ({
        value: option.id,
        label: option.taxGroupName,
      }));
      setSelectedOptions(selectedTax);
      if (filteredList) {
        setTaxRate(filteredList[0]?.taxRate);
      }
    }
  }, [CatalogFeature, props?.taxId, taxList]);

  const taxArray = taxList?.map((tax) => ({
    value: tax.id,
    label: tax.taxGroupName,
  }));
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [taxRate, setTaxRate] = useState([]);
  const handleChange = (selectedTax) => {
    setSelectedOptions(selectedTax);
    const filteredList = taxList.find((tax) => tax.id === selectedTax.value);
    setTaxRate(filteredList.taxRate);
    const newObj = {
      id: formData.mappingId,
      tenantId: user?.tenanatId,
      branchId:  filteredList.branchId,
      // status: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      isVisible:  filteredList.isVisible,
      isDeleted:  filteredList.isDeleted,
      modifiedBy: user.id,
      catalogueId:id,
      taxGroupId:  filteredList.id,
      taxId: filteredList.id,
      taxTypeId:  filteredList.taxTypeId,
      taxMethod: filteredList.taxMethodId,
      taxRate:  filteredList.taxRate,
    };
    dispatch(
      UpdateItemTaxGroup(newObj)
    );
  };
  return (
    <React.Fragment>
      <div className="mb-2 d-flex">
        <Form.Check
          type="switch"
          className="fs-16 fw-semibold"
          checked={formData.isTaxOverride}
          onChange={(e) => handleItemTaxFree(e.target.checked)}
          label={ "Tax Not Applicable"}
        />
      </div>

      {showItemTax && (
        <div className="price-variant-bg">
          <Row className="g-2">
            <Col xl={3} sm={6}>
              <Form.Label>Tax Group Name</Form.Label>
              {/* <Form.Control
                  type="text"
                  placeholder="Enter Tax Group Name"
                />
              */}
              <Select
                options={taxArray}
                isSearchable={true}
                value={selectedOptions}
                onChange={handleChange}
                placeholder="Select Tax Group"
              />
            </Col>
            <Col xl={3} sm={6}>
              <Form.Label>IGST</Form.Label>
              <Form.Control
                aria-label="Default select example"
                value={taxRate}
                disabled
              ></Form.Control>
            </Col>
            <Col xl={3} sm={6}>
              <Form.Label>SGST</Form.Label>
              <Form.Control
                type="number" min="1"
                value={taxRate / 2}
                placeholder="Enter SGST %"
                disabled
              />
            </Col>
            <Col xl={3} sm={6}>
              <Form.Label>
                CGST <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="number" min="1"
                value={taxRate / 2}
                placeholder="Enter CGST %"
                disabled
              />
            </Col>
          </Row>
        </div>
      )}
    </React.Fragment>
  );
}
