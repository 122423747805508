import React, { useEffect, useState, useRef, useContext } from "react";
import Prism from "prismjs";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import { getLoading } from "../../../application/selectors/indexSelector";
import { Imgaeupload } from "../../../application/actions/ItemAction";
import { Link } from "react-router-dom";
import PlaceholderImage from "../../../assets/img/empty-image/placeholde-image.svg";
import Environment from "../../../infrastructure/core/Environment";
import { PrintContext } from "../../../infrastructure/core/helpers/PrintContext";
import ParameterControl from "./PrinterTemplate/parameter-control";


export default function StoreBill() {
  const { setData } = useContext(PrintContext)
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { loading } = useSelector(getLoading);

  const [imagePath, setImagePath] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imagePath = e.target.result;
        setImagePath(imagePath);
        const image = imagePath.split(",")[1];
        const id = 2;
        const data = {
          image: image,
          id: id,
          foldername: "Icon",
        };
        dispatch(Imgaeupload(data));
      };

      reader.readAsDataURL(file);
    }
  };

  const initialState = {
    branchId: Environment.defaultValue,
    status: Environment.defaultValue,
    isVisible: true,
    isDeleted: true,
    templateName: "",
    templateTypeId: Environment.defaultValue,
    isLogo: true,
    isBrandName: true,
    isBrandNameBold: true,
    isBrandNameItalic: true,
    isBrandNameUnderlined: true,
    brandNameFontSize: "",
    isLegelEntityName: true,
    isLegelEntityNameBold: true,
    isLegelEntityNameItalic: true,
    isLegelEntityNameUnderlined: true,
    legelEntityNameFontSize: "",
    isMailingAddress: true,
    isMailingAddressBold: true,
    isMailingAddressItalic: true,
    isMailingAddressUnderlined: true,
    mailingAddressFontSize: "",
    isTaxNumber: true,
    isTaxNumberBold: true,
    isTaxNumberItalic: true,
    isTaxNumberUnderlined: true,
    taxNumberFontSize: "",
    isInvoiceNumber: true,
    isInvoiceNumberBold: true,
    isInvoiceNumberItalic: true,
    isInvoiceNumberUnderlined: true,
    invoiceNumberFontSize: "",
    isOrdeerCreatedDate: true,
    isOrdeerCreatedDateBold: true,
    isOrdeerCreatedDateItalic: true,
    isOrdeerCreatedDateUnderlined: true,
    ordeerCreatedDateFontSize: "",
    isCashierName: true,
    isCashierNameBold: true,
    isCashierNameItalic: true,
    isCashierNameUnderlined: true,
    cashierNameFontSize: "",
    isOrderType: true,
    isOrderTypeBold: true,
    isOrderTypeItalic: true,
    isOrderTypeUnderlined: true,
    orderTypeFontSize: "",
    isKOTId: true,
    isKOTIdBold: true,
    isKOTIdItalic: true,
    isKOTIdUnderlined: true,
    kotIdFontSize: "",
    isKitchenName: true,
    isKitchenNameBold: true,
    isKitchenNameItalic: true,
    isKitchenNameUnderlined: true,
    kitchenNameFontSize: "",
    isCustomerName: true,
    isCustomerNameBold: true,
    isCustomerNameItalic: true,
    isCustomerNameUnderlined: true,
    customerNameFontSize: "",
    isCustomerAddress: true,
    isCustomerAddressBold: true,
    isCustomerAddressItalic: true,
    isCustomerAddressUnderlined: true,
    customerAddressFontSize: "",
    isSection: true,
    isSectionBold: true,
    isSectionItalic: true,
    isSectionUnderlined: true,
    sectionFontSize: "",
    isTableNumber: true,
    isTableNumberBold: true,
    isTableNumberItalic: true,
    isTableNumberUnderlined: true,
    tableNumberFontSize: "",
    isServedByName: true,
    isServedByNameBold: true,
    isServedByNameItalic: true,
    isServedByNameUnderlined: true,
    servedByNameFontSize: "",
    isItemName: true,
    isItemNameBold: true,
    isItemNameItalic: true,
    isItemNameUnderlined: true,
    itemNameFontSize: "",
    isItemCustomization: true,
    isItemCustomizationBold: true,
    isItemCustomizationItalic: true,
    isItemCustomizationUnderlined: true,
    itemCustomizationFontSize: "",
    isUnitPrice: true,
    isUnitPriceBold: true,
    isUnitPriceItalic: true,
    isUnitPriceUnderlined: true,
    unitPriceFontSize: "",
    isUnitQuantity: true,
    isUnitQuantityBold: true,
    isUnitQuantityItalic: true,
    isUnitQuantityUnderlined: true,
    unitQuantityFontSize: "",
    isItemTaxAmount: true,
    isItemTaxAmountBold: true,
    isItemTaxAmountItalic: true,
    isItemTaxAmountUnderlined: true,
    itemTaxAmountFontSize: "",
    isTotalPrice: true,
    isTotalPriceBold: true,
    isTotalPriceItalic: true,
    isTotalPriceUnderlined: true,
    totalPriceFontSize: "",
    isItemRemark: true,
    isItemRemarkBold: true,
    isItemRemarkItalic: true,
    isItemRemarkUnderlined: true,
    itemRemarkFontSize: "",
    isTitleText: true,
    isTitleTextBold: true,
    isTitleTextItalic: true,
    isTitleTextUnderlined: true,
    titleTextFontSize: "",
    isCancelledItems: true,
    isCacelledItemsBold: true,
    isCacelledItemsItalic: true,
    isCacelledItemsUnderlined: true,
    isCancelledItemStriked: true,
    cacelledItemsFontSize: "",
    isPaymentCreackUpSummary: true,
    isQrOption: true,
    isPrintedOn: true,
    isPrintedOnBold: true,
    isPrintedOnItalic: true,
    isPrintedOnUnderlined: true,
    printedOnFontSize: "",
    isReprintedIndicator: true,
    isReprintedIndicatorBold: true,
    isReprintedIndicatorItalic: true,
    isReprintedIndicatorUnderlined: true,
    reprintedIndicatorFontSize: ""
  };
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    console.log(formData);
    setData(formData)
  }, [formData])
  console.log(formData);

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const [selectedPaymentOption, setSelectedPaymentOption] = useState("UPI");
  const handlePaymentOption = (e) => {
    setSelectedPaymentOption(e.target.value);
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleSwitchChange = () => {
    setIsChecked(!isChecked); // Toggle the state when the switch changes
  };

  return (
    <React.Fragment>
      <div>
        <div className="d-flex mt-3 mb-4">
          <div className="me-3">
            <div className="item-manage-thumbnail">
              <div className="text-center bg-white thumbnail h-100">
                <Form.Group className="h-100" controlId="formFile">
                  <div className="custom-update-image">
                    <Link className="edit-image bg-dark text-white">
                      <i className="ri-pencil-line fs-16"></i>
                    </Link>
                    <div className={`${loading ? "placeholder" : ""}uploaded-image`}
                    >
                      <Form.Control
                        type="file"
                        className="custom-file-input"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        accept=".jpg, .jpeg, .png"
                      />
                      <img
                        src={PlaceholderImage}
                        onError={(e) => { e.target.src = "https://fudeasy.oss-me-east-1.aliyuncs.com/FE/catalogueThumbnail/noImage.png" }}
                        className={`${loading ? "placeholder" : ""}img-fluid w-100 h-100`}
                        alt="thumbnail"
                        srcSet={imagePath}
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>
          <div>
            <h3 className="fs-16 text-dark mb-1 fw-semibold">Brand Logo</h3>
            <p className="fs-14 text-dark">Set the logo image. Only <br /> *.png, *.jpg, and *.jpeg image file are accepted.</p>
          </div>
        </div>

        <div className="divider divider-start">
          <span>Header Content</span>
        </div>
        <Row className="mb-3 g-3">
          <Col md={4} sm={12}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Logo"
              onChange={(e) => {
                setFormData({ ...formData, isLogo: e.target.checked });
              }}
              checked={formData.isLogo}
              name="isLogo"
            />
          </Col>
          <Col></Col>
        </Row>
        <ParameterControl
          label="Brand Name"
          name="isBrandName"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Legal Entity Name"
          name="isLegelEntityName"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Mailing Address"
          name="isMailingAddress"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Invoice Number"
          name="isInvoiceNumber"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Order Created Date"
          name="isOrdeerCreatedDate"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Cashier Name"
          name="isCashierName"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Order Type"
          name="isOrderType"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Section Name"
          name="isSection"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Table Number"
          name="isTableNumber"
          formData={formData}
          setFormData={setFormData}
        />
       
      </div>

      <div>
        <div className="divider divider-start">
          <span>Sub Header Content</span>
        </div>
        <ParameterControl
          label="Customer Name"
          name="isCustomerName"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Customer Address"
          name="isCustomerAddress"
          formData={formData}
          setFormData={setFormData}
        />
      </div>

      <div>
        <div className="divider divider-start">
          <span>KOT Item Detail</span>
        </div>
        <ParameterControl
          label="Item Name"
          name="isItemName"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Item Customization"
          name="isItemCustomization"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Unit Price"
          name="isUnitPrice"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Item Quality Colunm"
          name="isUnitQuantity"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Item Tax Amount Colunm"
          name="isItemTaxAmount"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Item Total Price Colunm"
          name="isTotalPrice"
          formData={formData}
          setFormData={setFormData}
        />

      </div>

      <div>
        <div className="divider divider-start">
          <span>Payment</span>
        </div>
        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Payment Breakup Summary"
              name="paymentSummary"
              // disabled
            />
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="QR Code Option"
              name="qrCodeOption"
              checked={isChecked}
              onChange={handleSwitchChange}
            />
            {isChecked === true && (
              <Row className="align-items-center mt-2">
                <Col>
                  <Form.Select
                    name="chooseOption"
                    value={selectedPaymentOption}
                    onChange={handlePaymentOption}
                  >
                    <option disabled>Choose Option</option>
                    <option value="UPI">UPI</option>
                    <option value="E-Bill">E-Bill</option>
                  </Form.Select>
                </Col>

                <Col>
                  {selectedPaymentOption === "UPI" && (
                    <Form.Control
                      type="text"
                      placeholder="Enter UPI ID"
                      name="upiID"
                    />
                  )}

                  {selectedPaymentOption === "E-Bill" && (
                    <Form.Control
                      type="text"
                      placeholder="Enter Number"
                      name="number"
                    />
                  )}
                </Col>
              </Row>
            )}
          </Col>
          {isChecked === true && (
            <Col sm={8}>
              <Row className="justify-content-end align-items-center">
                <Col sm={8}>
                  <div className="text-right">
                    <label className="font-style-bttns">
                      <input type="checkbox" name="bold" />
                      <span>
                        <i className="ri-align-left"></i>
                      </span>
                    </label>
                    <label className="font-style-bttns">
                      <input type="checkbox" name="bold" />
                      <span>
                        <i className="ri-align-center"></i>
                      </span>
                    </label>
                    <label className="font-style-bttns">
                      <input type="checkbox" name="bold" />
                      <span>
                        <i className="ri-align-right"></i>
                      </span>
                    </label>
                    <label className="font-style-bttns">
                      <input type="checkbox" name="bold" />
                      <span>
                        <i className="ri-bold"></i>
                      </span>
                    </label>
                    <label className="font-style-bttns">
                      <input type="checkbox" name="italic" />
                      <span>
                        <i className="ri-italic"></i>
                      </span>
                    </label>
                    <label className="font-style-bttns">
                      <input type="checkbox" name="underline" />
                      <span>
                        <i className="ri-underline"></i>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>
    </React.Fragment>
  );
}
