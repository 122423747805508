import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Row,
  Col,
  Button,
  Form,
  Card,
  Table,
  Collapse,
  Spinner,
} from "react-bootstrap";
import "./price-location.scss";
import MappedLocation from "./mapped-location";
import CopyPriceVariant from "./copy-price-branch";
import AddNewVariantModal from "./add-new-variant";
import emptyFolder from "../../../../assets/img/empty-state/empty-folder.png";
import { useDispatch, useSelector } from "react-redux";
import {
  BranchVarianceFeaturesUpdate,
  GetCommonFieldsTypeByGroupCode,
  //GetVarianceByItemId,
  ResetAllBranches,
  ResetAllByBranchId,
  UnMapBranchVariance,
  UnMapGlobalVarianceById,
} from "../../../../application/actions/varianceAction";
import {
  getBranchList,
  getCatalogDataById,
  getCatalogFeature,
  getLoading,
  getVarianceList,
} from "../../../../application/selectors/indexSelector";
import EditVariant from "./edit-variant";
import { useParams } from "react-router-dom";
import CardShimmerLoader from "../../../../layouts/ShimmerTable";
import Environment from "../../../../infrastructure/core/Environment";
import { UpdateCatalogFeatures } from "../../../../application/actions/ItemAction";
import Swal from "sweetalert2";

export default function PriceLocation(props) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const VarianceList = useSelector(getVarianceList);
  const { loading, value } = useSelector(getLoading);
  const CatalogFeature = useSelector(getCatalogFeature);
  const CatalogDataById = useSelector(getCatalogDataById);
  const branchList = useSelector(getBranchList);
  const Catalogue = CatalogDataById?.catalogue;
  const globalVarince = VarianceList?.globalVariances || [];
  const BranchData = branchList
  const commonfields = JSON.parse(localStorage.getItem("CommonFields"));
  const SizeList = JSON.parse(localStorage.getItem("CommonFields")).filter(
    (item) => item.fieldTypeCode === "SIZVAR"
  );
  const locations = Array.isArray(VarianceList?.locations)
    ? VarianceList.locations
    : [];

  const [varianceData, setVarianceData] = useState({});
  const [globalFeature, setGlobalFeature] = useState({});
  const [openSections, setOpenSections] = useState([]);
  const [global, setGlobal] = useState(null);
  const [openMappedLocation, setOpenMappedLocation] = useState(false);
  const [openCopyVariant, setOpenCopyVariant] = useState(false);
  const [openAddNewVariant, setOpenAddNewVariant] = useState(false);
  const [openEditVariant, setOpenEditVariant] = useState(false);
  const [selectedBranchId, setBranchId] = useState("");
  const [featureType, setFeatureType] = useState("");
  useEffect(() => {
    if (Catalogue !== undefined) {
      setGlobal(Catalogue.isGlobal);
    }
  }, [Catalogue]);
  useEffect(() => {
    dispatch(GetCommonFieldsTypeByGroupCode("CVT"));
  }, []);
  useEffect(() => {
    if (CatalogFeature !== undefined) {
      setGlobalFeature({
        mappingId: CatalogFeature?.id,
        catalogueId: CatalogFeature?.catalogueId,
        branchId: CatalogFeature?.branchId,
        isFeatured: VarianceList?.isFeatured,
        isBestSeller: VarianceList?.isBestSeller,
        isDiscount: VarianceList?.isDiscount,
        isVisible: VarianceList?.isVisible,
        isBOM: CatalogFeature?.isBOM,
        isAddOn: CatalogFeature?.isAddOn,
        isCustomized: CatalogFeature?.isCustomized,
        isRecipe: CatalogFeature?.isRecipe,
        isKOT: CatalogFeature?.isKOT,
        isDedicatedKitchen: CatalogFeature?.isDedicatedKitchen,
        hasVariance: CatalogFeature?.hasVariance
      });
      // debugger
      setMode(CatalogFeature.hasVariance);
    }
  }, [CatalogFeature, VarianceList]);

  useEffect(() => {
    if (!loading) {
      setFeatureType("");
    }
  }, [loading]);

  const toggleSection = (sectionId) => {
    if (openSections.includes(sectionId)) {
      setOpenSections(openSections.filter((id) => id !== sectionId));
    } else {
      setOpenSections([...openSections, sectionId]);
    }
  };
  const handleMappedLocation = (state) => {
    setOpenMappedLocation(state);
  };
  const handleDeleteBranch = (item) => {
    dispatch(ResetAllBranches(id));
  };
  const handleCopyVariant = (id, state) => {
    setOpenCopyVariant(state);
    setBranchId(id);
  };
  const handleAddNewVariant = (state) => {
    setOpenAddNewVariant(state);
  };
  const resetByBranch = (data) => {
    const Data = {
      branchId: data.branchId,
      catalogueId: id,
    };
    dispatch(ResetAllByBranchId(Data));
  };
  const handleEditVariant = (state, data) => {
    setOpenEditVariant(state);
    setVarianceData(data);
  };
  const updateGlobalVarianceFeatures = (e) => {
    const { name, value, checked, type } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    const newObj = { ...globalFeature, [name]: newValue };
    setFeatureType(name);
    dispatch(BranchVarianceFeaturesUpdate(newObj));
  };
  const updateBranchVarianceFeatures = (branch, e) => {
    const { name, value, checked, type } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    const newObj = { ...branch, branchId: branch.branchId, [name]: newValue };
    const finalObj = {
      mappingId: newObj.mappingId,
      catalogueId: id,
      branchId: newObj.branchId,
      isFeatured: newObj.isFeatured,
      isBestSeller: newObj.isBestSeller,
      isDiscount: newObj.isDiscount,
      isVisible: newObj.isVisible,
    };
    dispatch(BranchVarianceFeaturesUpdate(finalObj));
  };
  useEffect(() => {
    if (!loading) {
      setFeatureType("");
    }
  }, [loading]);

  const [searchTerm, setSearchTerm] = useState("");
  const searchFunction = (searchTerm) => {
    setSearchTerm(searchTerm);
    const filteredGlobalVarince = globalVarince.filter((item) =>
      item.variantName?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
    console.log("filteredGlobalVarince", filteredGlobalVarince);

    const filteredLocations = locations.filter((branch) =>
      branch.branchName?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
    const localVar = searchLocalVariance(searchTerm);
    console.log("filteredLocations", filteredLocations);
    console.log("localVar", localVar);
  };
  const searchLocalVariance = (searchTerm) => {
    const filteredLocations = locations
      .map((branch) => {
        const filteredVariances = branch.variances.filter((variance) =>
          Object.values(variance).some((value) =>
            value.variantName?.toLowerCase().includes(searchTerm?.toLowerCase())
          )
        );
        return { ...branch, variances: filteredVariances };
      })
      .filter((branch) => branch.variances.length > 0);
    return filteredLocations;
  };
  const [mode, setMode] = useState(false);
  const updateAddonsMode = (state) => {

    let updatedFormObject = { ...globalFeature, hasVariance: state };

    if (globalVarince?.length !== 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "This action will remove all variants associated with this item across all branches. This cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete them!",
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            dispatch(UpdateCatalogFeatures(updatedFormObject));
            dispatch(ResetAllBranches(id));
            setMode(state); // Update mode only after confirmation
            Swal.fire("Deleted!", "The variants have been successfully removed.", "success");
          } catch (error) {
            console.error("An error occurred while processing the request:", error);
          }
        }
      });
    } else {
      // Update mode if there are no variants to confirm deletion
      setMode(state);
      dispatch(UpdateCatalogFeatures(updatedFormObject));
    }
  };
  console.log(VarianceList, global, globalVarince);
  return (
    <React.Fragment>
      <CopyPriceVariant
        show={openCopyVariant}
        closeFunction={handleCopyVariant}
        branchId={selectedBranchId}
      />
      <MappedLocation
        show={openMappedLocation}
        closeFunction={handleMappedLocation}
        setGlobal={(e) => setGlobal(e)}
      />
      <AddNewVariantModal
        show={openAddNewVariant}
        closeFunction={handleAddNewVariant}
        SizeList={SizeList}
        IsGlobal={global}
        hasVariant={mode}
        Catalogue={VarianceList}
        BranchData={BranchData}
      />
      <EditVariant
        show={openEditVariant}
        closeFunction={handleEditVariant}
        variantData={varianceData}
      />


      {(global) ? (
        <>
          <div className="mb-2 d-flex  justify-content-start align-items-center gap-1">
            <Button
              variant="dark"
              size="sm"
              className="d-flex align-items-center gap-1 me-4"
              onClick={() => handleAddNewVariant(true)}
              disabled={!CatalogFeature?.hasVariance && globalVarince.length > 0}
            >
              <i className="ri-add-line fs-14 lh-1 align-middle"></i>
              <span className="d-sm-inline align-middle">
                Add New Variant
              </span>
            </Button>


            <Form.Check
              type="switch"
              className="fs-12 fw-semibold fw-bold text-dark"
              label="Allowed to add multiple variants?"
              checked={mode}
              onChange={(e) => updateAddonsMode(e.target.checked)}
            />
            {/* <span className="text-danger"> *</span> */}

          </div>
          <div className="mb-4 d-flex ">

          </div>
          <div className="mb-2">
            <div className="d-flex align-items-center justify-content-between applicabled-view mt-3 mb-4 gap-4">
              <h3 className="fs-12 d-flex justify-content-start align-items-center gap-1">
                <span className="text-danger fw-bold">Price applied globally for all locations.</span>
              </h3>
              <Button
                variant="outline-dark fs-12 "
                className={`d-flex align-items-center gap-1 ${globalVarince?.length === 0 ? "disabled" : ""
                  }`}
                onClick={() => {
                  setGlobal(false);
                  setOpenMappedLocation(true);
                }}
              >
                <i className="ri-pencil-line fs-10 lh-1"></i>
                <span className="d-sm-inline">Override</span>
              </Button>
            </div>

            <div className=" text-center pb-5">




              {VarianceList !== undefined && globalVarince?.length === 0 && (
                <>
                  <h3 className="fs-14 text-black-50 fw-semibold mt-3">
                    You havent added any price variant!
                  </h3>
                  <div className="empty-folder mt-2">
                    <img src={emptyFolder} alt="empty-folder" />
                  </div>
                </>
              )}
              {globalVarince?.length !== 0 && (
                <>
                  <Col>
                    <div className="d-flex align-items-center justify-content-end">
                      {featureType !== "isBestSeller" && (
                        <Form.Check
                          type="switch"
                          className="me-3 fs-12 fw-semibold mb-0 top-view"
                          name="isBestSeller"
                          label="Best Seller"
                          checked={globalFeature?.isBestSeller}
                          onChange={(e) => {
                            updateGlobalVarianceFeatures(e);
                          }}
                        />
                      )}
                      {loading &&
                        featureType === "isBestSeller" &&
                        value === "varFeatureUpdate" && (
                          <Spinner
                            animation="border"
                            size="sm"
                            variant="primary"
                            className="mx-4"
                          />
                        )}

                      {featureType !== "isFeatured" && (
                        <Form.Check
                          type="switch"
                          className="fs-12 fw-semibold me-4 mb-0 top-view "
                          name="isFeatured"
                          label="Featured"
                          checked={globalFeature?.isFeatured}
                          onChange={(e) => {
                            updateGlobalVarianceFeatures(e);
                          }}
                        />
                      )}
                      {loading &&
                        featureType === "isFeatured" &&
                        value === "varFeatureUpdate" && (
                          <Spinner
                            animation="border"
                            size="sm"
                            variant="primary"
                            className="mx-4"
                          />
                        )}

                      {featureType !== "isDiscount" && (
                        <Form.Check
                          type="switch"
                          size="sm"
                          className="fs-12 fw-semibold me-4 mb-0 top-view "
                          name="isDiscount"
                          label="Discount Allowed"
                          checked={globalFeature?.isDiscount}
                          onChange={(e) => {
                            updateGlobalVarianceFeatures(e);
                          }}
                        />
                      )}
                      {loading &&
                        featureType === "isDiscount" &&
                        value === "varFeatureUpdate" && (
                          <Spinner
                            animation="border"
                            size="sm"
                            variant="primary"
                            className="mx-4"
                          />
                        )}
                    </div>
                  </Col>
                  <Table className="mb-0">
                    <thead>
                      <tr>
                        {CatalogFeature?.hasVariance ? <th>Variant Name</th> : ''}
                        <th>Selling Price</th>
                        <th>Preperation Cost</th>
                        <th>Markup Price</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {globalVarince?.map((list, index) => (
                        <tr key={index}>
                          {CatalogFeature?.hasVariance && (<td>{list.variantName} </td>)
                          }
                          <td>
                            <b>{list.sellingPrice}</b>
                          </td>
                          <td>
                            <b>{list.preparationPrice}</b>
                          </td>
                          <td>
                            <b>{list.markupPrice}</b>
                          </td>
                          <td className="w-0">
                            <div className="d-flex">
                              <Button
                                variant="primary"
                                className="btn-icon me-2"
                                onClick={() => handleEditVariant(true, list)}
                              >
                                <i className="ri-pencil-line"></i>
                              </Button>

                              <Button
                                variant="danger"
                                className="btn-icon"
                                onClick={() => {
                                  dispatch(UnMapGlobalVarianceById(list.id));
                                }}
                              >
                                <i className="ri-delete-bin-line"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}

            </div>
          </div>
        </>
      ) : (
        <>
          {locations?.length !== 0 && (<Row className="align-items-center justify-content-end mb-4 g-3">
            <div className="d-flex">
              <Button
                variant="dark"
                size="sm"
                className="d-flex align-items-center gap-1 justify-content-center me-3"
                onClick={() => handleAddNewVariant(true)}
              >
                <i className="ri-add-line fs-14 lh-1"></i>{" "}
                <span className="d-sm-inline">Add New Variant</span>
              </Button>
              <Button
                variant="primary"
                size="sm"
                className="d-flex align-items-center gap-1 justify-content-center"
                onClick={() => handleMappedLocation(true)}
              >
                <i className="ri-map-pin-line fs-14 lh-1"></i>{" "}
                <span className="d-sm-inline">Manage Locations</span>
              </Button>
            </div>
            {/* <Col xl={4} md={4} xs={6}>
              
            </Col>
            <Col xl={4} md={4} xs={6}>
              
            </Col> */}
            <Col>
              <div className="custom-dropdown-wrapper">
                <div className="custom-drop-down z-index-2 w-100">
                  <span className="dropdown-icon bg-white">
                    <i className="ri-search-line"></i>
                  </span>
                  <Form.Control
                    type="text"
                    placeholder="Search Locations..."
                    value={searchTerm}
                    onChange={(e) => searchFunction(e.target.value)}
                  />
                </div>
              </div>
            </Col>
          </Row>)}


          <div className="price-variant-collapse">
            {locations?.map((branch, index) => (
              <>
                {branch.variances.length !== 0 && (
                  <Card
                    className={`card-one ht-auto border mb-3 ${!branch.isVisible ? "disbaled-collapse" : ""
                      }`}
                    key={index}
                  >
                    <React.Fragment key={index}>
                      <Card.Header>
                        <Row className="w-100 align-items-center">
                          <Col xl={2} md={2} xs={12}>
                            <Form.Check
                              type="switch"
                              name={`isVisible`}
                              onChange={(e) => {
                                updateBranchVarianceFeatures(branch, e);
                              }}
                              checked={branch.isVisible}
                              label={branch.isVisible ? branch.branchName : ""}
                              className={`fs-14 fw-semibold mb-0 top-view branch-name-toggle
                           ${branch.mappingId === Environment.defaultValue
                                  ? "d-none"
                                  : ""
                                } 
                          `}
                            />
                            {!branch.isVisible && (
                              <span
                                className={`fs-10 fw-semibold mb-0 top-view branch-name-toggle`}
                              >
                                {branch.branchName}
                              </span>
                            )}
                            <span className="unavailable-branch text-danger fw-semibold">
                              Unavailable
                            </span>
                          </Col>

                          <Col xl={10} md={10} xs={12}>
                            <div className="d-flex sm-d-block align-items-center justify-content-end">
                              <Form.Check
                                type="switch"
                                className={`me-3 fs-12 fw-semibold mb-0 top-view ${!branch.isVisible ? "d-none" : ""
                                  }`}
                                name="isBestSeller"
                                checked={branch.isBestSeller}
                                label="Best Seller"
                                onChange={(e) => {
                                  updateBranchVarianceFeatures(branch, e);
                                }}
                              />

                              <Form.Check
                                type="switch"
                                className={`fs-12 fw-semibold me-4 mb-0 top-view ${!branch.isVisible ? "d-none" : ""
                                  }`}
                                name="isDiscount"
                                checked={branch.isDiscount}
                                onChange={(e) => {
                                  updateBranchVarianceFeatures(branch, e);
                                }}
                                label="Discount"
                              />

                              <Form.Check
                                type="switch"
                                className={`fs-12 fw-semibold me-4 mb-0 top-view ${!branch.isVisible ? "d-none" : ""
                                  }`}
                                name="isFeatured"
                                checked={branch.isFeatured}
                                onChange={(e) => {
                                  updateBranchVarianceFeatures(branch, e);
                                }}
                                label="Featured"
                              />

                              <Button
                                variant="dark"
                                className={`d-flex align-items-center fs-12 gap-1 me-4 pt-2 pb-2 top-view ${!branch.isVisible ? "d-none" : ""
                                  }`}
                                onClick={() =>
                                  handleCopyVariant(branch.branchId, true)
                                }
                              >
                                <i className="ri-file-copy-line fs-12 lh-1"></i>
                                <span className="d-sm-inline">
                                  Copy Price
                                </span>
                              </Button>
                              <Button
                                variant="danger"
                                className={`btn-icon me-2 top-view d-flex ${!branch.isVisible ? "d-none" : ""
                                  }`}
                                onClick={() => resetByBranch(branch)}
                              >
                                <i className="ri-delete-bin-line"></i>
                              </Button>
                            </div>
                          </Col>
                        </Row>

                        <Button
                          variant="link"
                          className={`collapse-toggle-bttn ${!branch.isVisible ? "d-none" : ""
                            }`}
                          onClick={() => toggleSection(branch.branchId)} // Use branch.id as the unique identifier
                          aria-controls={`collapse-section-${branch.branchId}`}
                          aria-expanded={openSections.includes(branch.branchId)}
                        >
                          <i className="ri-arrow-down-s-line"></i>
                        </Button>
                      </Card.Header>
                      <Collapse in={openSections.includes(branch.branchId)}>
                        <div id={`collapse-section-${branch.branchId}`}>
                          <Card.Body>
                            <Table className="mb-0" responsive>
                              <thead>
                                <tr>
                                  <th>Variant Name</th>
                                  <th>Selling Price</th>
                                  <th>Preperation Cost</th>
                                  <th>Markup Price</th>
                                  <th>Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                {branch.variances?.map((list, index) => (
                                  <tr key={index}>
                                    {CatalogFeature?.hasVariance && (<td>{list.variantName} </td>)
                                    }
                                    <td>
                                      <b>{list.sellingPrice}</b>
                                    </td>
                                    <td>
                                      <b>{list.preparationPrice}</b>
                                    </td>
                                    <td>
                                      <b>{list.markupPrice}</b>
                                    </td>
                                    <td className="w-0">
                                      <div className="d-flex">
                                        <Button
                                          variant="primary"
                                          className="btn-icon me-2"
                                          onClick={() =>
                                            handleEditVariant(true, list)
                                          }
                                        >
                                          <i className="ri-pencil-line"></i>
                                        </Button>

                                        <Button
                                          variant="outline-danger"
                                          className="btn-icon"
                                          onClick={() => {
                                            dispatch(
                                              UnMapBranchVariance(
                                                list.branchMappingId
                                              )
                                            );
                                          }}
                                        >
                                          <i className="ri-delete-bin-line"></i>
                                        </Button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Card.Body>
                        </div>
                      </Collapse>
                    </React.Fragment>
                  </Card>
                )}
              </>
            ))}
          </div>
        </>
      )}
    </React.Fragment>
  );
}
