import { Navigate } from "react-router-dom";
import * as Loading from "../actions/loaderAction";
import {
  ADD_TO_FAVOURITES,
  APPLY_CHARGES,
  APPLY_DISCOUNT,
  CANCEL_ORDER,
  CHANGE_ORDERSTATUS,
  CLOSE_ORDER,
  CREATE_ORDER,
  CreateOrderSuccess,
  DELETE_ALL_ORDERED_ITEM,
  DELETE_ORDERED_ITEM,
  GETITEM_DETAIL_ON_POS,
  GET_ALL_FAVOURITES,
  GET_CHARGE_BY_BRANCHID,
  GET_DISCOUNT_BY_BRANCHID,
  GET_WAITERLIST_POS,
  GetItemDetailOnPOSSuccess,
  GetWaiterListPOSSuccess,
  INCRE_KOTPRINTCOUNT,
  MERGE_TABLE,
  ORDERS_BY_ORDERTYPE,
  ORDER_DETAILS,
  ORDER_SETTLE_PAYMENT,
  ORDER_WAITER_CHANGE,
  POST_MENUDATA_BY_BRANCHID,
  REMOVE_CHARGES,
  REMOVE_DISCOUNT,
  REMOVE_FROM_FAVOURITES,
  TABLESECTION_BY_BRANCH,
  TRANSFER_ORDER,
  UPDATE_ORDER,
  getChargeByBranchIdSuccess,
  getDiscountByBranchIdSuccess,
  orderRelatedChanges,
  ordersByOrderTypeSuccess,
  tableSectionByOrderTypeSuccess,
  transferMergeChanges,
  transferedTableOrderId,
} from "../actions/posAction";
import ToastifyService from "../../View/_common/ToastifyService";

const GetItemDetailOnPOS =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GETITEM_DETAIL_ON_POS) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "GetItemDetailOnPOS" })
        );
        const itemDetailOnPOS = await api.posAPI.GetItemDetailOnPOS(
          action.payload.branchId,
          action.payload.id
        );
        dispatch(GetItemDetailOnPOSSuccess(itemDetailOnPOS));
      } catch (error) {
        // ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "GetItemDetailOnPOS" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "GetItemDetailOnPOS" })
        );
      }
    }
    next(action);
  };
const GetPOSMenuDataByBranchId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === POST_MENUDATA_BY_BRANCHID) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "posMenuData" }));
        const POSMenuDataByBranchId = await api.posAPI.GetPOSMenuDataByBranchId(
          action.payload.branchId
        );
        const favList=await api.posAPI.getAllFavourites(action.payload.userId)
        localStorage.setItem(
          "Catalogues",
          JSON.stringify(POSMenuDataByBranchId?.result?.catalogues)
        );
        localStorage.setItem(
          "SubMenuList",
          JSON.stringify(POSMenuDataByBranchId?.result?.categories)
        );
        localStorage.setItem(
          "MenuList",
          JSON.stringify(POSMenuDataByBranchId?.result?.mainCategories)
        );
        localStorage.setItem(
          "Favourites",
          JSON.stringify(favList?.result)
        );
        ToastifyService.success("Item Catalogue Loaded Successfully !");
      } catch (error) {
        ToastifyService.error("Something Went Wrong While Loading Menu Data!");
        dispatch(Loading.setLoading({ loading: false, value: "posMenuData" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(Loading.setLoading({ loading: false, value: "posMenuData" }));
      }
    }
    next(action);
  };
const GetWaiterListPOS =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_WAITERLIST_POS) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "waiterListPOS" }));
        const waiterListPOS = await api.posAPI.GetWaiterListPOS(action.payload);
        dispatch(GetWaiterListPOSSuccess(waiterListPOS));
      } catch (error) {
        dispatch(
          Loading.setLoading({ loading: false, value: "waiterListPOS" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "waiterListPOS" })
        );
      }
    }
    next(action);
  };
const getDiscountByBranchId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_DISCOUNT_BY_BRANCHID) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "getDiscountByBranchId" })
        );
        const discountListPOS = await api.posAPI.getDiscountByBranchId(
          action.payload
        );
        dispatch(getDiscountByBranchIdSuccess(discountListPOS));
      } catch (error) {
        // ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "getDiscountByBranchId" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "getDiscountByBranchId" })
        );
      }
    }
    next(action);
  };
const getChargeByBranchId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_CHARGE_BY_BRANCHID) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "getChargeByBranchId" })
        );
        const chargeListPOS = await api.posAPI.getChargeByBranchId(
          action.payload
        );
        dispatch(getChargeByBranchIdSuccess(chargeListPOS));
      } catch (error) {
        // ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "getChargeByBranchId" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "getChargeByBranchId" })
        );
      }
    }
    next(action);
  };
const orderDetails =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ORDER_DETAILS) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "orderDetails" }));
        const orderDetails = await api.posAPI.orderDetails(action.payload);
        dispatch(CreateOrderSuccess(orderDetails));
      } catch (error) {
        // ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "orderDetails" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(Loading.setLoading({ loading: false, value: "orderDetails" }));
      }
    }
    next(action);
  };
const CreateOrder =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CREATE_ORDER) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "createOrder" }));
        const orderDetails = await api.posAPI.CreateOrder(action.payload);
        dispatch(orderRelatedChanges(true));
        dispatch(transferedTableOrderId(action.payload.orderTypeId))
        dispatch(CreateOrderSuccess(orderDetails));
        ToastifyService.success("Order Created Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "createOrder" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(Loading.setLoading({ loading: false, value: "createOrder" }));
      }
    }
    next(action);
  };
const UpdateOrder =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_ORDER) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "updateOrder" }));
        const orderDetails = await api.posAPI.UpdateOrder(action.payload);
        dispatch(transferedTableOrderId(action.payload.orderTypeId))
        dispatch(CreateOrderSuccess(orderDetails));
        dispatch(orderRelatedChanges(true));
        ToastifyService.success("Order Updated Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "updateOrder" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(Loading.setLoading({ loading: false, value: "updateOrder" }));
      }
    }
    next(action);
  };
const cancelOrder =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CANCEL_ORDER) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "cancelOrder" }));
        const responseData = await api.posAPI.cancelOrder(
          action.payload.orderId
        );
        if (action.payload.hasSectionTable) {
          dispatch(tableSectionByOrderTypeSuccess(responseData.result));
        } else {
          dispatch(ordersByOrderTypeSuccess(responseData.result));
        }
        dispatch(transferedTableOrderId(action.payload.orderTypeId))
        dispatch(orderRelatedChanges(true))
        ToastifyService.success("Order Cancelled Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "cancelOrder" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(Loading.setLoading({ loading: false, value: "cancelOrder" }));
      }
    }
    next(action);
  };
const applyCharges =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === APPLY_CHARGES) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "applyCharges" }));
        const orderDetails = await api.posAPI.applyCharges(action.payload);
        dispatch(transferedTableOrderId(action.payload.orderTypeId))
        dispatch(CreateOrderSuccess(orderDetails));
        dispatch(orderRelatedChanges(true));
        ToastifyService.success("Charge Applied Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "applyCharges" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(Loading.setLoading({ loading: false, value: "applyCharges" }));
      }
    }
    next(action);
  };
const applyDiscount =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === APPLY_DISCOUNT) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "applyDiscount" }));
        const orderDetails = await api.posAPI.applyDiscount(action.payload.formObject);
        dispatch(transferedTableOrderId(action.payload.orderTypeId))
        dispatch(CreateOrderSuccess(orderDetails));
        dispatch(orderRelatedChanges(true));
        ToastifyService.success("Discount Applied Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "applyDiscount" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "applyDiscount" })
        );
      }
    }
    next(action);
  };
const removeDiscount =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === REMOVE_DISCOUNT) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "removeDiscount" })
        );
        const orderDetails = await api.posAPI.removeDiscount(action.payload.orderId);
        dispatch(transferedTableOrderId(action.payload.orderTypeId))
        dispatch(CreateOrderSuccess(orderDetails));
        dispatch(orderRelatedChanges(true));
        ToastifyService.success("Discount Removed Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "removeDiscount" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "removeDiscount" })
        );
      }
    }
    next(action);
  };
const removeCharges =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === REMOVE_CHARGES) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "removeCharges" }));
        const orderDetails = await api.posAPI.removeCharges(action.payload);
        dispatch(transferedTableOrderId(action.payload.orderTypeId))
        dispatch(CreateOrderSuccess(orderDetails));
        dispatch(orderRelatedChanges(true));
        ToastifyService.success("Charge Removed Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "removeCharges" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "removeCharges" })
        );
      }
    }
    next(action);
  };
const deleteOrderedItem =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === DELETE_ORDERED_ITEM) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "deleteOrderedItem" })
        );
        const orderDetails = await api.posAPI.deleteOrderedItem(action.payload);
        dispatch(orderRelatedChanges(true));
        dispatch(CreateOrderSuccess(orderDetails));
        ToastifyService.success("Ordered Item Deleted Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "deleteOrderedItem" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "deleteOrderedItem" })
        );
      }
    }
    next(action);
  };
const deleteAllOrderedItem =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === DELETE_ALL_ORDERED_ITEM) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "deleteAllOrderedItem" })
        );
        const orderDetails = await api.posAPI.deleteAllOrderedItem(
          action.payload.formObject
        );
        dispatch(transferedTableOrderId(action.payload.orderTypeId))
        dispatch(CreateOrderSuccess(orderDetails));
        dispatch(orderRelatedChanges(true));
        ToastifyService.success("Items Deleted Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "deleteAllOrderedItem" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "deleteAllOrderedItem" })
        );
      }
    }
    next(action);
  };
const transferOrder =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === TRANSFER_ORDER) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "transferOrder" }));
        const transferResponse = await api.posAPI.transferOrder(
          action.payload.formObject
        );
        if (action.payload.hasSectionTable) {
          dispatch(
            tableSectionByOrderTypeSuccess(transferResponse?.result?.sections)
          );
        } else {
          dispatch(
            ordersByOrderTypeSuccess(
              transferResponse?.result?.ordersByOrderType
            )
          );
        }
        dispatch(transferedTableOrderId(action.payload.formObject.orderType));
        dispatch(transferMergeChanges(true));
        ToastifyService.success("Table Transfered Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "transferOrder" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "transferOrder" })
        );
      }
    }
    next(action);
  };
const tableSectionByOrderType =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === TABLESECTION_BY_BRANCH) {
      try {
        dispatch(
          Loading.setLoading({
            loading: true,
            value: "tableSectionByOrderType",
          })
        );
        const sectionList = await api.posAPI.tableSectionByOrderType(
          action.payload
        );
        dispatch(tableSectionByOrderTypeSuccess(sectionList.result));
      } catch (error) {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "tableSectionByOrderType",
          })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "tableSectionByOrderType",
          })
        );
      }
    }
    next(action);
  };
const ordersByOrderType =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ORDERS_BY_ORDERTYPE) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "ordersByOrderType" })
        );
        const ordersByOrderType = await api.posAPI.ordersByOrderType(
          action.payload
        );
        dispatch(ordersByOrderTypeSuccess(ordersByOrderType.result));
      } catch (error) {
        dispatch(
          Loading.setLoading({ loading: false, value: "ordersByOrderType" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "ordersByOrderType" })
        );
      }
    }
    next(action);
  };
const orderSettlePayment =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ORDER_SETTLE_PAYMENT) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "orderSettlePayment" })
        );
        const orderDetails = await api.posAPI.orderSettlePayment(
          action.payload.formObject
        );
        dispatch(transferedTableOrderId(action.payload.orderTypeId))
        dispatch(CreateOrderSuccess(orderDetails));
        dispatch(orderRelatedChanges(true));
        ToastifyService.success("Payment Settled Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "orderSettlePayment" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "orderSettlePayment" })
        );
      }
    }
    next(action);
  };
const closeOrder =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CLOSE_ORDER) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "closeOrder" }));
        const responseData = await api.posAPI.closeOrder(
          action.payload.orderId
        );
        if (action.payload.hasSectionTable) {
          dispatch(tableSectionByOrderTypeSuccess(responseData.result));
        } else {
          dispatch(ordersByOrderTypeSuccess(responseData.result));
        }
        dispatch(transferedTableOrderId(action.payload.orderTypeId))
        dispatch(orderRelatedChanges(true))
        ToastifyService.success("Order Closed Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "closeOrder" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(Loading.setLoading({ loading: false, value: "closeOrder" }));
      }
    }
    next(action);
  };
const orderWaiterChange =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ORDER_WAITER_CHANGE) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "orderWaiterChange" })
        );
        const orderDetails = await api.posAPI.orderWaiterChange(action.payload);
        dispatch(CreateOrderSuccess(orderDetails));
        dispatch(orderRelatedChanges(true));
        ToastifyService.success("Waiter Updated Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "orderWaiterChange" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "orderWaiterChange" })
        );
      }
    }
    next(action);
  };
const mergeTable =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MERGE_TABLE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "mergeTable" }));
        const sectionList = await api.posAPI.mergeTable(
          action.payload.formObject
        );
        dispatch(tableSectionByOrderTypeSuccess(sectionList.result));
        dispatch(transferMergeChanges(true));
        dispatch(transferedTableOrderId(action.payload.orderType));
        ToastifyService.success("Table Merged Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "mergeTable" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(Loading.setLoading({ loading: false, value: "mergeTable" }));
      }
    }
    next(action);
  };
const increKotprintCount =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === INCRE_KOTPRINTCOUNT) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "increKotprintCount" })
        );
        const orderDetails = await api.posAPI.increKotprintCount(
          action.payload.formObject
        );
        dispatch(transferedTableOrderId(action.payload.orderTypeId));
        dispatch(CreateOrderSuccess(orderDetails));
      } catch (error) {
        dispatch(
          Loading.setLoading({ loading: false, value: "increKotprintCount" })
        );
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "increKotprintCount" })
        );
      }
    }
    next(action);
  };
const changeOrderStatus =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CHANGE_ORDERSTATUS) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "changeOrderStatus" })
        );
        const orderDetails = await api.posAPI.changeOrderStatus(action.payload.formObject);
        dispatch(transferedTableOrderId(action.payload.orderTypeId))
        dispatch(CreateOrderSuccess(orderDetails));
        dispatch(orderRelatedChanges(true));
      } catch (error) {
        dispatch(
          Loading.setLoading({ loading: false, value: "changeOrderStatus" })
        );
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "changeOrderStatus" })
        );
      }
    }
    next(action);
  };
  const addToFavourites =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_TO_FAVOURITES) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "addToFavourites" })
        );
        const favList = await api.posAPI.addToFavourites(action.payload);
        localStorage.setItem(
          "Favourites",
          JSON.stringify(favList?.result)
        );
        ToastifyService.success("Catalogue Added To Favourites.");
      } catch (error) {
        dispatch(
          Loading.setLoading({ loading: false, value: "addToFavourites" })
        );
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "addToFavourites" })
        );
      }
    }
    next(action);
  };
   const getAllFavourites =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_ALL_FAVOURITES) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "getAllFavourites" })
        );
        const favList = await api.posAPI.getAllFavourites(action.payload);
        localStorage.setItem(
          "Favourites",
          JSON.stringify(favList?.result)
        );
      } catch (error) {
        dispatch(
          Loading.setLoading({ loading: false, value: "getAllFavourites" })
        );
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "getAllFavourites" })
        );
      }
    }
    next(action);
  };
  const removeFromFavourites =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === REMOVE_FROM_FAVOURITES) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "removeFromFavourites" })
        );
        const favList = await api.posAPI.removeFromFavourites(action.payload);
        localStorage.setItem(
          "Favourites",
          JSON.stringify(favList?.result)
        );
        ToastifyService.warning("Catalogue Removed From Favourites.");
      } catch (error) {
        dispatch(
          Loading.setLoading({ loading: false, value: "removeFromFavourites" })
        );
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "removeFromFavourites" })
        );
      }
    }
    next(action);
  };

export default [
  GetItemDetailOnPOS,
  CreateOrder,
  GetPOSMenuDataByBranchId,
  GetWaiterListPOS,
  UpdateOrder,
  getDiscountByBranchId,
  getChargeByBranchId,
  cancelOrder,
  orderDetails,
  applyCharges,
  applyDiscount,
  removeDiscount,
  removeCharges,
  deleteOrderedItem,
  transferOrder,
  tableSectionByOrderType,
  ordersByOrderType,
  orderSettlePayment,
  closeOrder,
  orderWaiterChange,
  mergeTable,
  increKotprintCount,
  deleteAllOrderedItem,
  changeOrderStatus,
  addToFavourites,
  getAllFavourites,
  removeFromFavourites
];
