import React, { createContext, useState } from "react";

export const PrintContext = createContext();

export const PrintContextProvider = ({ children }) => {
    const [showPrintView, setShowPrintView] = useState(false);
    const [Data, setData] = useState({});
  

    return (
        <PrintContext.Provider
            value={{
                showPrintView,
                setShowPrintView,
                Data,
                setData
            }}
        >
            {children}
        </PrintContext.Provider>
    );
};
