import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Row,
  Col,
  Form,
  Offcanvas,
  Button,
  Tabs,
  Tab,
  Card,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  AddSection,
  AddTable,
  UpdateTable,
} from "./../../../../application/actions/tableAction";
import DataTable from "react-data-table-component";
import TableShimmerLoader from "../../../../layouts/ShimmerTable";
import AddNewTable from "./add-table";
import {
  getOrderTypeList,
  getSectionsList,
  getTablesList,
} from "../../../../application/selectors/indexSelector";
import Swal from "sweetalert2";
import AuthContext from "../../../../infrastructure/core/helpers/AuthContext";
import { OrderType } from "../../../../application/actions/orderTypeAction";
import Select from "react-select";
import { format } from "date-fns";
import { validateRequired } from "../../../../infrastructure/core/validationUtils";

export default function EditSection(props) {
  const { user } = useContext(AuthContext);
  useEffect(() => {
    Prism.highlightAll();
    dispatch(OrderType);
  }, []);
  const SectionsList = useSelector(getSectionsList);
  const initialState = {
    id: 0,
    name: "",
    branchId: "",
    sectionDesc: "",
    createdBy: user.id,
    modifiedBy: user.id,
    tenantId: user.tenantId,
    FirebaseRefId: "NA",
    isVisible: true,
    isDeleted: false,
    isApplyAuto: false,
    sectionCharge: 0,
    typeId: "",
  };
  const [formData, setFormData] = useState(initialState);
  const dispatch = useDispatch();
  useEffect(() => {
    if (props?.section) {
      setFormData({
        ...formData,
        id: props?.section?.id,
        name: props?.section?.name,
        sectionDesc: props?.section?.sectionDesc,
        branchId: props?.section?.branchId,
        typeId: props?.section?.typeId,
        isDeleted: false,
      });
    }
  }, [props]);
  const getOrderListByBranch = useSelector(getOrderTypeList);
  const ApplyOrderType = getOrderListByBranch
    ?.filter((emp) => emp?.isTableSection !== false)
    .map((option) => ({
      value: option.id,
      label: option.typeName,
    }));
  const handleSelectApplied = (selectedOptions) => {
    setFormData({ ...formData, typeId: selectedOptions.value });
    setErrors({ ...errors, typeId: "" });
  };

  const [errors, setErrors] = useState({});
  const validateName = () => {
    const newErrors = {};
    // Validate catalogueName
    newErrors.name = validateRequired(formData.name)
      ? ""
      : "Section Name is required";
    // Validate mainCatId (dropdown)
    newErrors.typeId = validateRequired(formData.typeId)
      ? ""
      : "Please Select Order Type";

    setErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const IsValid = validateName();
    if (!IsValid) {
      return;
    }
    dispatch(AddSection(formData));
    handleFunction();
  };

  const [activeTab, setActiveTab] = useState("edit_details_tab");
  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };
  const [showAddTable, setShowAddTable] = useState(false);
  const [table, setTable] = useState(0);
  const handleEditTable = (data, state) => {
    setTable(data);
    setShowAddTable(state);
  };

  const handleDeleteTable = (data) => {
    const TableData = {
      createdBy: user.id,
      modifiedBy: user.id,
      tableName: data.tableName,
      tableCode: data.tableCode,
      tableTypeId: data.tableTypeId,
      branchId: data.branchId,
      isVisible: true,
      isDeleted: true,
      FirebaseRefId: "string",
      id: data.id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, submit it!",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          dispatch(UpdateTable(TableData));
        } catch (error) {
          console.error("Error occurred during post request:", error);
        }
        Swal.fire("Submitted!", "Table has been Deleted.", "success");
        //   getAllTables();
      }
    });
  };
  const columns = [
    {
      name: "Table Name",
      selector: (row) => row.tableName,
      width: "180px",
    },
    {
      name: "Added On",
      selector: (row) => (
        <div>
          {" "}
          <h5 className="fs-14 fw-semibold">
            {" "}
            {format(new Date(row.createdOn), "dd MMM yyyy hh:mm a")}
          </h5>
          <h6 className="fs-11 mt-1">{row.createdByName}</h6>
        </div>
      ),
      width: "420px",
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-between w-100">
          <Button
            variant="primary"
            className="btn-icon me-2"
            onClick={() => handleEditTable(row, true)}
          >
            <i className="ri-pencil-line"></i>
          </Button>
          <Button
            variant="danger"
            className="btn-icon"
            onClick={() => handleDeleteTable(row)}
          >
            <i className="ri-delete-bin-line"></i>{" "}
          </Button>
        </div>
      )
    },
  ];
  const handleFunction = () => {
    props.closeSection(false);
    setFormData(initialState);
    setErrors({});
  };
  const TablesList = useSelector(getTablesList);
  return (
    <React.Fragment>
      <AddNewTable
        show={showAddTable}
        section={props?.section}
        SectionList={SectionsList}
        table={table}
        branchId={props.branchId}
        closeFunction={handleEditTable}
        closeSection={handleFunction}
      />
      <Offcanvas
        show={props.show}
        onHide={handleFunction}
        placement="end"
        className="w-45"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit Section</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Tabs
            id="custom-styled-tabs"
            activeKey={activeTab}
            onSelect={handleTabSelect}
            className="sale-on-point-tabs"
          >
            {/* EDIT DETAILS TAB */}
            <Tab
              eventKey="edit_details_tab"
              title={
                <>
                  <h6 className="fs-14 mb-0">Edit Details</h6>
                </>
              }
              tabClassName="custom-tab-header"
            >
              <div className="custom-tab-body pt-3">
                <div>
                  <Row>
                    <Col xl="6">
                      <div className="mb-4">
                        <Form.Label>
                          Choose Order Type
                          <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          name="orderTypes"
                          options={ApplyOrderType}
                          onChange={handleSelectApplied}
                          value={ApplyOrderType?.filter((option) =>
                            formData.typeId?.includes(option.value)
                          )}
                        />
                        <Form.Text className="text-danger">
                          {errors.typeId}
                        </Form.Text>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <div className="mb-4">
                        <Form.Label>
                          Section Name: <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Enter Section Name"
                          value={formData.name}
                          onChange={(e) => {
                            setFormData({ ...formData, name: e.target.value });
                            setErrors({ ...errors, name: "" });
                          }}
                        />
                        <Form.Text className="text-danger">
                          {errors.name}
                        </Form.Text>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="8">
                      <div className="mb-4">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="text"
                          name="sectionDesc"
                          placeholder="Enter Section Name"
                          value={formData.sectionDesc}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              sectionDesc: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Tab>

            {/* TABLE LIST TAB */}
            <Tab
              eventKey="table_list_tab"
              title={
                <>
                  <h6 className="fs-14 mb-0">Table List</h6>
                </>
              }
              tabClassName="custom-tab-header"
            >
              <div className="custom-tab-body pt-3">
                <Card className="card-one">
                  <Card.Body>
                    {!props?.loading && (
                      <div className="custom-common-table">
                        <DataTable
                          columns={columns}
                          data={TablesList}
                          fixedHeader
                          search={true}
                          highlightOnHover
                          pagination
                        ></DataTable>
                      </div>
                    )}
                    {props?.loading && (
                      <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                    )}
                  </Card.Body>
                </Card>
              </div>
            </Tab>
          </Tabs>
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            type="submit"
            onClick={handleSubmit}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Save Changes</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
