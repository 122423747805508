import {
  CATALOGUE_CODE_SUCCESS,
  CATALOGUE_FEATURE_SUCCESS,
  CATALOG_LIST_PAGINATION_SUCCESS,
  CATALOG_LIST_SUCCESS,
  COMMON_MEASUREMENT_SUCCESS,
  COMMON_SIZE_VARIANTS_SUCCESS,
  CREATE_CATALOG_SUCCESS,
  GET_ADDONS_SUCCESS,
  GET_CATALOGUEID,
  GET_CATALOGUE_ITEM_BY_ID_ERROR,
  GET_CATALOGUE_ITEM_BY_ID_SUCCESS,
  GET_CATALOG_BY_CATID_SUCCESS,
  MAP_ADDON_ITEM_SUCCESS,
} from "../actions/ItemAction";
const initialState = {
  ItemCatalogData: undefined,
  ItemCatalogPaginationData: undefined,
  MenuList: [],
  CreateCatalogData: [],
  CatalogDataById: undefined,
  CatalogFeature: undefined,
  CatalogId: undefined,
  UpdateCatalogdata: [],
  loading: false,
  AddOnsItemList: [],
  AddOnsList: [],
  PriceList: [],
  Measurement: [],
  CatalogueCodes: [],
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CATALOG_LIST_SUCCESS:
      return {
        ...state,
        ItemCatalogData: action.payload.result,
        error: null,
      };
    case CATALOG_LIST_PAGINATION_SUCCESS:
      return {
        ...state,
        ItemCatalogPaginationData: action.payload.data,
        error: null,
      };
    case CREATE_CATALOG_SUCCESS:
      return {
        ...state,
        CreateCatalogData: action.payload.data.result,
        error: null,
      };
    case GET_CATALOGUE_ITEM_BY_ID_SUCCESS:
      return { ...state, CatalogDataById: action.payload.data, error: null };
    case GET_CATALOGUEID:
      return { ...state, CatalogId: action.payload.data, error: null };
    case GET_CATALOGUE_ITEM_BY_ID_ERROR:
      return { ...state, CatalogId: undefined, error: null };
    case CATALOGUE_FEATURE_SUCCESS:
      return { ...state, CatalogFeature: action.payload, error: null };
    case GET_CATALOG_BY_CATID_SUCCESS:
      return {
        ...state,
        CatalogDataByCatId: action.payload.data.result,
        error: null,
      };
    case GET_ADDONS_SUCCESS:
      return { ...state, AddOnsList: action.payload.data.result, error: null };
    case MAP_ADDON_ITEM_SUCCESS:
      return {
        ...state,
        AddOnsItemList: action.payload.data.result,
        error: null,
      };
    case COMMON_SIZE_VARIANTS_SUCCESS:
      return {
        ...state,
        SizeVariants: action.payload.data.result,
        error: null,
      };
    case COMMON_MEASUREMENT_SUCCESS:
      return { ...state, Measurement: action.payload.data.result, error: null };
    case CATALOGUE_CODE_SUCCESS:
      return {
        ...state,
        CatalogueCodes: action.payload.data.result,
        error: null,
      };
    default:
      return state;
  }
};

export default reducer;
