// react-packages
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { format } from "date-fns";
//Loader component
//context
import AuthContext from "../../infrastructure/core/helpers/AuthContext";
//components
import Header from "../../layouts/Header";
import AddNewCustomer from "./add-customer";
import ManageCustomer from "./manage-customer";
//selectors, actions
import { customerListByTenant, deleteCustomer, uploadCustomer } from "../../application/actions/customerAction";
import { getCustomerList, getLoading } from "../../application/selectors/indexSelector";
import Environment from "../../infrastructure/core/Environment";
import { debounce } from "lodash";
import { LoadEmployee } from "../../application/actions/employeeAction";
import TableShimmerLoader from "../../layouts/ShimmerTable";
import CardShimmerLoader from "../../layouts/ShimmerCard";

export default function Customers() {
  //accessing hook from react-redux package
  const dispatch = useDispatch();
  //accessing user data from local storage using context
  const { user, selectedBranchData } = useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);
  //fetching and storing customer list and loader is on or not
  const { loading, value } = useSelector(getLoading);
  const customerList = useSelector(getCustomerList);
  //making useState for customer data
  const [customerData, setCustomerData] = useState(null);
  //making useState for showing or hiding add customer modal
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  //making useState for showing or hiding customer details modal
  const [showManageCustomer, setShowManageCustomer] = useState(false);

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  //Loading customer list based on tenant Id
  useEffect(() => {
    dispatch(customerListByTenant(user?.tenanatId));
    dispatch(LoadEmployee(user?.tenanatId));
  }, []);
  //function for open or hide add customer modal
  const handleAddCustomer = (state) => {
    setShowAddCustomer(state);
  };
  //function for open or hide customer detail modal and passing the selected customer Id from customer list
  const handleManageCustomer = (state, customerId) => {
    setShowManageCustomer(state);
    setCustomerData(customerId);
  };
  //delete customer function
  const handledeleteCustomer = (id) => {
    dispatch(deleteCustomer(id));
  };
  //datatable columns and their records
  const columns = [
    {
      name: "Customer Name",
      selector: (row) => (
        <Link onClick={() => handleManageCustomer(true, row.id)}>
          {row.fullName}
        </Link>
      ),
    },
    {
      name: "Contact",
      selector: (row) => (
        <>
          <h6 className="mb-0 fw-semibold">
            {row.countrycode} {row.contactNumber}
          </h6>
          <span>{row.email}</span>
        </>
      ),
    },
    {
      name: "Total Order",
      selector: (row) => <b>{row?.orderSummary?.totalOrderCount}</b>,
    },
    {
      name: "Pending Payment",
      selector: (row) => (
        <b className="text-danger">
          {parsedBranchData?.currencySymbol}{" "}
          {row?.orderSummary?.pendingPayments}
        </b>
      ),
    },
    {
      name: "Total Spent",
      selector: (row) => (
        <b>
          {parsedBranchData?.currencySymbol}{" "}
          {row?.orderSummary?.totalOrderPayments}
        </b>
      ),
    },
    {
      name: "Created On",
      selector: (row) => (
        <>
          <h6 className="mb-0 fw-semibold">
            {format(new Date(row.createdOn), "dd MMM yyyy hh:mm a")},
          </h6>
          <span className="text-dark fw-semibold">{row.createdByName}</span>
        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button variant="primary" className="btn-icon me-2" onClick={() => handleManageCustomer(true, row.id)}>
            <i className="ri-pencil-line"></i>
          </Button>
          <Button variant="outline-danger" className="btn-icon" onClick={() => handledeleteCustomer(row.id)}>
            <i className="ri-delete-bin-line"></i>
          </Button>
        </div>
      ),
    },
  ];

  const [branchData, setBranchData] = useState(Environment.defaultValue);
  const handleBranchIdFromHeader = debounce((data) => {
    setBranchData(data);
  }, 300);
  const handleUploadCustomer = (e) => {
    const formObject = new FormData();
    formObject.append("TenantId", user?.tenanatId);
    formObject.append("file", e.target.files[0]);
    formObject.append("CreatedBy", user?.id);
    dispatch(uploadCustomer(formObject));
  };

  const [searchTerm, setSearchTerm] = useState('');
  const filteredEmployees = customerList?.filter((item) => Object.values(item).some((value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())));


  return (
    <React.Fragment>
      <Header passDataToPointOfSale={handleBranchIdFromHeader} />
      <div className="main main-app p-3 p-lg-4">
        <AddNewCustomer
          show={showAddCustomer}
          closeFunction={handleAddCustomer}
          fromComponent={'Customers'}
        />
        <ManageCustomer
          data={customerData}
          show={showManageCustomer}
          closeFunction={handleManageCustomer}
        />

        <div className="d-md-flex align-items-center justify-content-between mb-3">
          <div>
            <h4 className="main-title fs-16 mb-0">Customer Management</h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <a className="me-2 btn btn-success decoration-none text-white"
              href={`${process.env.PUBLIC_URL}/files/CustomerUploadTemplate.xlsx`}
              download="CustomerUploadTemplate"
            >
              <span className="d-sm-inline">Download Template</span>
            </a>
            <div className="d-inline-block position-relative">
              <div>
              </div>
              <Button
                variant="dark"
                className="d-flex align-items-center gap-2 me-2"
                disabled={loading && value === "uploadCustomer"}
              >
                <input
                  type="file"
                  className="position-absolute top-0 start-0 w-100 h-100 opacity-0 cursor-pointer"
                  onChange={handleUploadCustomer}
                />
                {loading && value === "uploadCustomer" ? (
                  <CardShimmerLoader columnCount={4} rowCount={4} />
                ) : (
                  <>
                    <i className="ri-add-line fs-18 lh-1"></i>
                    <span className="d-sm-inline">Bulk Upload</span>
                  </>
                )}
              </Button>
            </div>

            <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
              onClick={() => handleAddCustomer(true)}
            >
              <i className="ri-add-line fs-18 lh-1"></i>
              <span className="d-sm-inline"> Create New Customer</span>
            </Button>
          </div>
        </div>
        <Card className="card-one">
          <Card.Header className="align-items-center justify-content-between">
            <div>
              <h4 className="main-title fs-14 mb-0">List of Customer</h4>
            </div>
            <div className="custom-dropdown-wrapper">
              <div className="custom-drop-down z-index-2 wt-300">
                <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
              </div>
            </div>
          </Card.Header>
          <Card.Body className="custom-common-table">
            {filteredEmployees && filteredEmployees !== undefined && (
              <DataTable
                columns={columns}
                data={filteredEmployees}
                fixedHeader
                search={true}
                highlightOnHover
                pagination
              ></DataTable>
            )}
            {!loading && filteredEmployees === undefined && (
              <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
            )}
            {loading &&
              value === "customerList" && filteredEmployees === undefined && (
                <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
              )}
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
