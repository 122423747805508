import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Offcanvas,
  Button,
  Form,
  Row,
  Col,
  Badge,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { getKOTRemarkList } from "../../../application/selectors/indexSelector";
import { useSelector } from "react-redux";
import { useCart } from "../MyCard/cartProvider";
import Environment from "../../../infrastructure/core/Environment";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";

export default function AddNewVariants(props) {
  const { selectedBranchData } = useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);
  const kotRemarkList = useSelector(getKOTRemarkList);
  const [itemDetailOnPOS, setItemDetailOnPOS] = useState({});
  const [selectedVariance, setSelectedVariance] = useState("");
  const [errors, setErrors] = useState({});
  const [selectedVarianceData, setSelectedVarianceData] = useState(
   ""
  );
  const [selectedVarianceSizeId, setSelectedVarianceSizeId] = useState(
    Environment.defaultValue
  );
  const [selectedVarianceType, setSelectedVarianceType] = useState(
    Environment.defaultValue
  );
  const [variantMode, setVariantMode] = useState(Environment.defaultValue);
  const [variancePrice, setVariancePrice] = useState(0);
  const [cartAmount, setCartAmount] = useState(0);
  const [remarkText, setRemarkText] = useState("");
  const [activeBadge, setActiveBadge] = useState(null);
  const [selectedRadioAddon, setSelectedRadioAddon] = useState([]);
  const [cartItemData, setcartItemData] = useState([]);
  const [selectedCheckBoxAddon, setSelectedCheckBoxAddon] = useState([]);
  const [singleAddonsData, setSingleAddonsData] = useState([]);
  const [addonsPrice, setAddonPrice] = useState(0);
  const [customizePrice, setCustomizePrice] = useState(0);
  const [addonsQuantityArray, setAddonsQuantityArray] = useState([]);
  const [multiAddonsData, setMultiAddonsData] = useState([]);
  const [actualCartQty, setCartQty] = useState(1);
  const { localStorageCartData, setCartData } = useCart();
  const commonfields = JSON.parse(localStorage.getItem("CommonFields"));
  const getDataAtIndex = (index) => {
    if (
      localStorageCartData &&
      index >= 0 &&
      index < localStorageCartData.length
    ) {
      return localStorageCartData[index];
    } else {
      return null;
    }
  };
  useEffect(() => {
    setItemDetailOnPOS(props.selectedItemData);
  }, [props.selectedItemData]);
  useEffect(() => {
    if (props.show) {
      if (props?.modifyItemId) {
        const isEmptyObject = (obj) => {
          return Object.keys(obj).length === 0 && obj.constructor === Object;
        };
        if (!isEmptyObject(props?.modifyItemId)) {
          const cartItem = getDataAtIndex(props?.modifyItemId?.itemIndex);
          setSelectedVarianceData(cartItem?.variantName);
          setSelectedVariance(cartItem?.variantId);
          setSelectedVarianceSizeId(cartItem?.sizeId);
          setSelectedVarianceType(cartItem?.variantType);
          setVariancePrice(cartItem?.varientPrice);
          setCartAmount(cartItem?.totalPrice);
          setVariantMode(cartItem?.variantMode);
          setRemarkText(cartItem?.kotRemark);
          setSelectedCheckBoxAddon(cartItem?.multiAddOnsData);
          setMultiAddonsData(cartItem?.multiAddOnsData);
          setAddonsQuantityArray(cartItem?.multiAddOnsData);
          setAddonPrice(cartItem?.totalAddonsPrice);
          setCustomizePrice(cartItem?.totalCustomisePrice);
          setcartItemData(cartItem);
          cartItem?.singleAddOnsData?.forEach((addOnItem) => {
            handleRadioAddon(addOnItem, addOnItem);
          });
          setErrors({});
        }
      } else if (
        props.selectedItemData?.sizeVariance?.length !== 0 &&
        props.selectedItemData?.sizeVariance
      ) {
        props?.setModifyItemId(null);
        setSelectedRadioAddon([]);
        setSingleAddonsData([]);
        setSelectedVarianceData(
          props.selectedItemData?.sizeVariance[0]?.variantName
        );
        resetAlltems();
      }
    }
  }, [
    cartItemData,
    props.selectedItemData,
    props.modifyItemId,
    itemDetailOnPOS,
    props.show,
  ]);
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const resetAlltems = () => {
    setSelectedVariance(props.selectedItemData?.sizeVariance[0]?.id);
    setSelectedVarianceSizeId(props.selectedItemData?.sizeVariance[0]?.sizeId);
    setSelectedVarianceType(
      props.selectedItemData?.sizeVariance[0]?.variantType
    );
    setVariancePrice(props.selectedItemData?.sizeVariance[0]?.sellingPrice);
    setCartAmount(props.selectedItemData?.sizeVariance[0]?.sellingPrice);
    setVariantMode(props.selectedItemData?.sizeVariance[0]?.variantMode);
    setRemarkText("");
    setActiveBadge(null);
    setSelectedCheckBoxAddon([]);
    setMultiAddonsData([]);
    setAddonsQuantityArray([]);
    setAddonPrice(0);
    setCustomizePrice(0);
    setCartQty(1);
    newFunc();
    setErrors({});
  };
  const newFunc = () => {
    const mandatoryAddons = [];
    const nonMandatoryAddons = [];
    itemDetailOnPOS?.addOnGroups
      ?.filter(
        (addOnGroup) =>
          addOnGroup.groupTypeId === "d9c5a952-911e-4a0f-8935-5e1fae01201e"
      )
      .forEach((groupData) => {
        if (groupData.isMandatory && groupData.addOnItems.length > 0) {
          const firstAddon = groupData.addOnItems[0];
          mandatoryAddons.push({
            ...firstAddon,
            isMandatory: groupData.isMandatory,
            totalQuantity: 1,
            addOnGroupId: groupData.addOnGroupId,
            groupTitle: groupData.groupTitle,
          });
          handleRadioAddon(firstAddon, groupData);
        } else if (groupData.addOnItems.length > 0) {
          nonMandatoryAddons.push(...groupData.addOnItems);
        }
      });
    setSingleAddonsData(mandatoryAddons);
    nonMandatoryAddons.forEach((addonItem) => {
      setSelectedRadioAddon((prevState) => ({
        ...prevState,
        [addonItem.addOnGroupId]: null,
      }));
    });
    const quantity = 1;
    const calculatedPrice = mandatoryAddons.reduce(
      (acc, item) => acc + item.price * quantity,
      0
    );
    setCustomizePrice(calculatedPrice);
    UpdatePrice(actualCartQty, variancePrice, calculatedPrice, 0);
  };
  const handleVariantSelect = (data) => {
    setSelectedVarianceData(data.variantName);
    setSelectedVariance(data.id);
    setSelectedVarianceSizeId(data.sizeId);
    setSelectedVarianceType(data.variantType);
    setVariancePrice(data.sellingPrice);
    setVariantMode(data.variantMode);
    UpdatePrice(actualCartQty, data.sellingPrice, customizePrice, addonsPrice);
  };
  const handleBadgeClick = (data) => {
    setRemarkText(
      (prevRemarkText) =>
        prevRemarkText + (prevRemarkText !== "" ? ", " : "") + data.remark
    );
    if (activeBadge && activeBadge.id !== data.id) {
      setActiveBadge(null);
    }
    setActiveBadge(data);
  };
  const handleCheckBoxAddon = (
    addonData,
    isMandatory,
    isQuantityAllowed,
    isChecked,
    groupData
  ) => {
    const updatedCheckboxes = [...selectedCheckBoxAddon];
    const maxAllowedQty = groupData.maxAllowedQty || Infinity; // Set default to Infinity if maxAllowedQty is not provided
    const currentGroupSelectedCount = selectedCheckBoxAddon.filter(
      (item) => item.addOnGroupId === groupData.addOnGroupId
    ).length;
    const errorsCopy = { ...errors }; // Copy current errors object

    if (isChecked && currentGroupSelectedCount >= maxAllowedQty) {
      // Prevent further selection if max allowed quantity is reached
      errorsCopy[
        groupData.addOnGroupId
      ] = `(Maximum addon items allowed - ${maxAllowedQty})`;
      setErrors(errorsCopy);
      return;
    } else {
      // Remove error if the user deselects the checkbox or max quantity not reached
      delete errorsCopy[groupData.addOnGroupId];
      setErrors(errorsCopy);
    }
    const index = updatedCheckboxes.indexOf(addonData.id);
    let quantityData = [...addonsQuantityArray];
    if (isChecked) {
      updatedCheckboxes.push(addonData.id);
      quantityData.push({
        id: addonData.id,
        quantity: 1,
        price: addonData.price,
        itemName: addonData.itemName,
        isMandatory: isMandatory,
        isQuantityAllowed: isQuantityAllowed,
        totalPrice: addonData.price * 1,
        addOnGroupId: groupData?.addOnGroupId,
        groupTitle: groupData?.groupTitle,
        maxItemAllowed: addonData?.maxItemAllowed,
      });
    } else {
      updatedCheckboxes.splice(index, 1);
      quantityData = quantityData.filter((item) => item.id !== addonData.id);
    }
    setMultiAddonsData(quantityData);
    setSelectedCheckBoxAddon(quantityData);
    setAddonsQuantityArray(quantityData);
    const sum = quantityData.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    setAddonPrice(sum);
    UpdatePrice(actualCartQty, variancePrice, customizePrice, sum);
  };
  const handleQuantityChange = (addonItemsData, change) => {
    const updatedQuantityData = addonsQuantityArray.map((item) => {
      if (item.id === addonItemsData?.id) {
        // Ensure quantity doesn't go below 1
        let newQuantity = item.quantity + change;
        // Check if maxItemAllowed is not 9999 and if newQuantity exceeds maxAllowedQty
        if (
          addonItemsData.maxItemAllowed !== 9999 &&
          newQuantity > addonItemsData.maxItemAllowed
        ) {
          // Disable increasing quantity beyond maxAllowedQty
          newQuantity = item.quantity;
        } else {
          // Ensure quantity doesn't go below 1
          newQuantity = Math.max(newQuantity, 1);
        }
        return {
          ...item,
          quantity: newQuantity,
          totalPrice: newQuantity * item.price,
        };
      } else {
        return item;
      }
    });
    setAddonsQuantityArray(updatedQuantityData);
    setMultiAddonsData(updatedQuantityData);
    setSelectedCheckBoxAddon(updatedQuantityData);
    const sum = updatedQuantityData.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    setAddonPrice(sum);
    UpdatePrice(actualCartQty, variancePrice, customizePrice, sum);
  };
  const handleRadioAddon = (addonData, groupData) => {
    const modifiedAddonData = {
      ...addonData,
      isMandatory: groupData?.isMandatory,
      totalQuantity: 1,
      addOnGroupId: groupData?.addOnGroupId,
      groupTitle: groupData?.groupTitle,
      maxItemAllowed: 1,
    };

    let updatedAddonsData = [...singleAddonsData];
    const existingIndex = updatedAddonsData.findIndex(
      (item) =>
        item.addOnGroupId === groupData?.addOnGroupId &&
        item.id === addonData.id
    );

    if (groupData?.isMandatory) {
      updatedAddonsData = updatedAddonsData.filter(
        (item) => item.addOnGroupId !== groupData?.addOnGroupId
      );
      updatedAddonsData.push(modifiedAddonData);
    } else {
      if (existingIndex !== -1) {
        updatedAddonsData.splice(existingIndex, 1);
      } else {
        updatedAddonsData.push(modifiedAddonData);
      }
    }

    setSelectedRadioAddon((prevState) => ({
      ...prevState,
      [groupData?.addOnGroupId]: addonData.id,
    }));

    setSingleAddonsData(updatedAddonsData);

    const quantity = 1;
    const calculatedPrice = updatedAddonsData.reduce(
      (acc, item) => acc + item.price * quantity,
      0
    );
    setCustomizePrice(calculatedPrice);
    UpdatePrice(actualCartQty, variancePrice, calculatedPrice, addonsPrice);
  };
  const UpdatePrice = (qty, varPrice, customizationPrice, addonsPrice) => {
    setCartAmount(qty * (varPrice + customizationPrice + addonsPrice));
  };
  const AddToCart = () => {
    let multiAddOns = [];
    for (let i = 0; i < multiAddonsData.length; i++) {
      const AddonsData = {
        id: multiAddonsData[i].id,
        itemName: multiAddonsData[i].itemName,
        price: multiAddonsData[i].price,
        isMandatory: multiAddonsData[i].isMandatory,
        quantity:
          addonsQuantityArray.find((item) => item.id === multiAddonsData[i].id)
            ?.quantity || 1,
        isQuantityAllowed: multiAddonsData[i].isQuantityAllowed,
        totalPrice:
          multiAddonsData[i].price *
            addonsQuantityArray.find(
              (item) => item.id === multiAddonsData[i].id
            )?.quantity || 1,
        addOnGroupId: multiAddonsData[i].addOnGroupId,
        groupTitle: multiAddonsData[i].groupTitle,
        maxItemAllowed: multiAddonsData[i].maxItemAllowed,
      };
      multiAddOns.push(AddonsData);
    }
    const cartData = {
      itemId: itemDetailOnPOS.id,
      branchId: itemDetailOnPOS.branchId,
      catalogueName: itemDetailOnPOS.catalogueName,
      tenantId: itemDetailOnPOS.tenantId,
      kotRemark: remarkText,
      foodType: itemDetailOnPOS.foodType,
      variantId: selectedVariance,
      variantName: selectedVarianceData,
      sizeId: selectedVarianceSizeId,
      variantType: selectedVarianceType,
      multiAddOnsData: multiAddOns,
      singleAddOnsData: singleAddonsData,
      tableId: props.selectedTable,
      totalPrice: cartAmount,
      totalQuantity: actualCartQty,
      varientPrice: variancePrice,
      totalVarientPrice: variancePrice,
      variantMode: variantMode,
      customisePrice: singleAddonsData?.reduce(
        (totalPrice, addon) => totalPrice + addon.price,
        0
      ),
      totalCustomisePrice: singleAddonsData?.reduce(
        (totalPrice, addon) => totalPrice + addon.price,
        0
      ),
      addonsPrice: multiAddOns?.reduce(
        (totalPrice, addon) => totalPrice + addon.price * addon.quantity,
        0
      ),
      totalAddonsPrice: multiAddOns?.reduce(
        (totalPrice, addon) => totalPrice + addon.price * addon.quantity,
        0
      ),
      varAndItemTotal:
        variancePrice +
        singleAddonsData?.reduce(
          (totalPrice, addon) => totalPrice + addon.price,
          0
        ),
      totalVarAndItemTotal:
        variancePrice +
        singleAddonsData?.reduce(
          (totalPrice, addon) => totalPrice + addon.price,
          0
        ),
    };
    if (props?.modifyItemId) {
      const itemIndex = props?.modifyItemId?.itemIndex;
      if (itemIndex !== -1) {
        const newCartData = [...localStorageCartData];
        newCartData[itemIndex] = cartData;
        setCartData(newCartData);
        localStorage.setItem("TableCartData", JSON.stringify(newCartData));
      }
    } else {
      if (localStorageCartData && localStorageCartData.length !== 0) {
        const newCartData = [...localStorageCartData, cartData];
        setCartData(newCartData);
        localStorage.setItem("TableCartData", JSON.stringify(newCartData));
      } else {
        setCartData([cartData]);
        localStorage.setItem("TableCartData", JSON.stringify([cartData]));
      }
    }
    props.closeFunction();
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        className="w-35"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="d-flex align-items-center">
            <div>
              <span
                className={`indicator d-block me-2 ${
                  itemDetailOnPOS?.foodType ===
                  "a634316f-857d-41fd-a74c-895f61e4de65"
                    ? "non-veg"
                    : itemDetailOnPOS?.foodType ===
                      "d4ce1e35-7ebb-4b29-be69-7c20c216741a"
                    ? "veg"
                    : itemDetailOnPOS?.foodType ===
                      "a634316f-857d-41fd-a74c-895f61e4de67"
                    ? "eggiterian"
                    : itemDetailOnPOS?.foodType ===
                      "a634316f-857d-41fd-a74c-895f61e4de66"
                    ? "not-applicable"
                    : ""
                }`}
              ></span>
            </div>
            <h5 className="fs-16 fw-semibold two-line-ellipsis">
              {itemDetailOnPOS?.catalogueName}{" "}
              {itemDetailOnPOS?.sizeVariance?.length === 1 && (
                <span className="badge badge-pill bg-danger fs-11 ms-2">
                  {" "}
                  {itemDetailOnPOS?.sizeVariance[0]?.variantType ===
                  Environment.defaultValue
                    ? `₹ ${itemDetailOnPOS?.sizeVariance[0]?.sellingPrice}`
                    : `${itemDetailOnPOS?.sizeVariance[0]?.variantName}  ${
                        itemDetailOnPOS?.sizeVariance[0]?.variantType ===
                        "d9c5a952-911e-4a0f-8935-5e1fae01902e"
                          ? itemDetailOnPOS?.sizeVariance[0]?.sizeId !==
                            Environment.defaultValue
                            ? `(${
                                commonfields?.find(
                                  (size) =>
                                    size.id ===
                                    itemDetailOnPOS?.sizeVariance[0]?.sizeId
                                )?.fieldValue
                              })`
                            : ""
                          : ""
                      } - ₹ ${itemDetailOnPOS?.sizeVariance[0]?.sellingPrice}`}
                </span>
              )}
            </h5>
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <div className="choose-variant-body">
            {/* CHOOSE VARIANT */}
            {itemDetailOnPOS?.sizeVariance?.length > 1 && (
              <div className="mb-4">
                <div className="variant-sub-header text-uppercase fw-bold mb-3">
                  Choose Variant
                </div>
                <div className="choose-variant-select">
                  <Row className="g-3">
                    {itemDetailOnPOS?.sizeVariance?.map((data, index) => (
                      <Col xl={3} sm={6} key={index}>
                        <label className="choose-varianr-radio d-block">
                          <input
                            type="radio"
                            name={data?.variantName}
                            id={data?.variantName}
                            value={data?.id}
                            checked={selectedVariance === data?.id}
                            onChange={(e) => handleVariantSelect(data)}
                          />
                          <div className="radio-btn w-100">
                            <OverlayTrigger
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  {data.variantName}
                                  {data.variantType ===
                                  "d9c5a952-911e-4a0f-8935-5e1fae01902e"
                                    ? data?.sizeId !== Environment.defaultValue
                                      ? `(${
                                          commonfields?.find(
                                            (size) => size.id === data.sizeId
                                          )?.fieldValue
                                        })`
                                      : ""
                                    : ""}
                                </Tooltip>
                              }
                            >
                              <h3 className="fs-14 fw-bold two-line-ellipsis">
                                {data?.variantName}{" "}
                                {data.variantType ===
                                "d9c5a952-911e-4a0f-8935-5e1fae01902e"
                                  ? data?.sizeId !== Environment.defaultValue
                                    ? `(${
                                        commonfields?.find(
                                          (size) => size.id === data.sizeId
                                        )?.fieldValue
                                      })`
                                    : ""
                                  : ""}
                              </h3>
                            </OverlayTrigger>

                            <span className="variant-price">
                              {parsedBranchData?.currencySymbol}{" "}
                              {data?.sellingPrice}
                            </span>
                          </div>
                        </label>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            )}

            {/* ADD REMARK */}
            {kotRemarkList && kotRemarkList.length > 0 && (
              <div>
                <div>
                  <div className="variant-sub-header text-uppercase fw-bold mt-3 mb-2">
                    Remark / Instruction
                  </div>
                  <Form.Control
                    as="textarea"
                    id="remark"
                    rows="1"
                    placeholder="Add order remark"
                    value={remarkText}
                    onChange={(e) => setRemarkText(e.target.value)}
                    className="font-italic"
                  />
                  <div className="mt-2">
                    {kotRemarkList?.map((data, index) => (
                      <Badge
                        key={index}
                        bg={
                          activeBadge && activeBadge.id === data.id
                            ? "dark"
                            : "gray-200"
                        }
                        color={
                          activeBadge && activeBadge.id === data.id
                            ? "gray-100"
                            : "text-dark"
                        }
                        className={`fs-12 me-2 my-1 text-dark fw-semibold ${
                          activeBadge && activeBadge.id === data.id
                            ? "text-white"
                            : ""
                        }`}
                        style={{ padding: "10px 20px", cursor: "pointer" }}
                        pill
                        onClick={() => handleBadgeClick(data)}
                      >
                        {data.remark}
                      </Badge>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {/* CUSTOMIZE VARIANT */}
            {itemDetailOnPOS?.addOnGroups &&
              itemDetailOnPOS?.addOnGroups?.filter(
                (groupData) =>
                  groupData.groupTypeId ===
                  "d9c5a952-911e-4a0f-8935-5e1fae01201e"
              ).length > 0 && (
                <div className="mb-4">
                  <div className="variant-sub-header text-uppercase fw-bold mt-3">
                    Customize Addons
                  </div>
                  {itemDetailOnPOS?.addOnGroups?.map(
                    (groupData, groupIndex) => (
                      <>
                        {groupData.groupTypeId ===
                          "d9c5a952-911e-4a0f-8935-5e1fae01201e" && (
                          <>
                            {groupData?.addOnItems?.length > 0 && (
                              <>
                                <h3 className="fs-12 fw-semibold mt-3 mb-2">
                                  {groupData.groupTitle}{" "}
                                  {groupData.isMandatory && (
                                    <span className="text-danger">
                                      (Mandatory)
                                    </span>
                                  )}{" "}
                                </h3>
                                <div
                                  className="choose-variant-select"
                                  key={groupIndex}
                                >
                                  <Row className="g-3">
                                    {groupData?.addOnItems?.map(
                                      (addonItemsData, index) => {
                                        const isSelectedAddon =
                                          singleAddonsData?.some(
                                            (addon) =>
                                              addon?.id === addonItemsData?.id
                                          );
                                        return (
                                          <Col xl={4} sm={6} key={index}>
                                            <label className="choose-varianr-radio d-block">
                                              <input
                                                type={
                                                  groupData.isMandatory
                                                    ? "radio"
                                                    : "checkbox"
                                                }
                                                name={`addonGroup-${groupData.addOnGroupId}`}
                                                id={addonItemsData?.groupTitle}
                                                value={addonItemsData?.id}
                                                checked={
                                                  selectedRadioAddon[
                                                    groupData.addOnGroupId
                                                  ] === addonItemsData?.id ||
                                                  isSelectedAddon
                                                } // Mark addon item as checked if it's selected
                                                onChange={() =>
                                                  handleRadioAddon(
                                                    addonItemsData,
                                                    groupData
                                                  )
                                                }
                                              />
                                              <div className="radio-btn w-100">
                                                <OverlayTrigger
                                                  overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                      {addonItemsData.itemName}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <h3 className="fs-12 fw-semibold two-line-ellipsis">
                                                    {addonItemsData?.itemName}
                                                  </h3>
                                                </OverlayTrigger>
                                                <span className="fs-14 variant-price">
                                                  {
                                                    parsedBranchData?.currencySymbol
                                                  }{" "}
                                                  {addonItemsData?.price}
                                                </span>
                                              </div>
                                            </label>
                                          </Col>
                                        );
                                      }
                                    )}
                                  </Row>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )
                  )}
                </div>
              )}

            {itemDetailOnPOS?.addOnGroups &&
              itemDetailOnPOS?.addOnGroups?.filter(
                (groupData) =>
                  groupData.groupTypeId !==
                  "d9c5a952-911e-4a0f-8935-5e1fae01201e"
              ).length > 0 && (
                <>
                  <hr />
                  <div className="add-topping">
                    {itemDetailOnPOS?.addOnGroups?.map(
                      (groupData, groupIndex) => (
                        <>
                          {groupData.groupTypeId !==
                            "d9c5a952-911e-4a0f-8935-5e1fae01201e" && (
                            <>
                              {groupData?.addOnItems.length > 0 && (
                                <>
                                  <h3 className="fs-14 fw-semibold mt-3 mb-3 text-dark">
                                    {groupData?.groupTitle}
                                    {errors[groupData.addOnGroupId] && (
                                      <span className="alert alert-danger mx-1 d-inline-flex my-0 p-1 align-items-center">
                                        <i className="ri-alert-line fs-12 mx-1 my-0"></i>
                                        <span className="fs-10">
                                          {errors[groupData.addOnGroupId]}
                                        </span>
                                      </span>
                                    )}
                                  </h3>
                                  {groupData?.addOnItems?.map(
                                    (addonItemsData) => {
                                      const isSelectedAddon =
                                        multiAddonsData?.some(
                                          (addon) =>
                                            addon?.id === addonItemsData?.id
                                        );
                                      return (
                                        <Row className="mb-2" key={groupIndex}>
                                          <Col className="d-flex align-items-center">
                                            <Form.Check
                                              type="checkbox"
                                              label={`${
                                                addonItemsData?.itemName
                                              } 
                                              ${
                                                addonItemsData?.isQuantityAllowed
                                                  ? `(${parsedBranchData?.currencySymbol} ${addonItemsData?.price})`
                                                  : ""
                                              }`}
                                              name={groupData?.groupTitle}
                                              id={addonItemsData?.id}
                                              value={addonItemsData?.id}
                                              className="fw-semibold text-dark"
                                              checked={
                                                selectedCheckBoxAddon[
                                                  groupData.addOnGroupId
                                                ] === addonItemsData?.id ||
                                                isSelectedAddon
                                              }
                                              onChange={(e) =>
                                                handleCheckBoxAddon(
                                                  addonItemsData,
                                                  false,
                                                  addonItemsData?.isQuantityAllowed,
                                                  e.target.checked,
                                                  groupData
                                                )
                                              }
                                            />
                                            {addonItemsData?.isQuantityAllowed &&
                                              (selectedCheckBoxAddon[
                                                groupData.addOnGroupId
                                              ] === addonItemsData?.id ||
                                                isSelectedAddon) &&
                                              addonItemsData?.maxItemAllowed !==
                                                9999 && (
                                                <span className="alert alert-danger mx-1 d-inline-flex my-0 p-1 align-items-center">
                                                  <i className="ri-alert-line fs-16 mx-1 my-0"></i>
                                                  <span className="fs-10">
                                                    Max:{" "}
                                                    {addonItemsData?.maxItemAllowed.toString()}
                                                  </span>
                                                </span>
                                              )}
                                          </Col>
                                          <Col>
                                            <div className="d-flex align-items-center justify-content-end position-relative">
                                              {(selectedCheckBoxAddon[
                                                groupData.addOnGroupId
                                              ] === addonItemsData?.id ||
                                                isSelectedAddon) && (
                                                <div
                                                  className={`qty ${
                                                    addonItemsData?.isQuantityAllowed
                                                      ? "view-qty"
                                                      : ""
                                                  }`}
                                                >
                                                  <Button
                                                    type="button"
                                                    variant="outline-dark"
                                                    className="plus"
                                                    onClick={() =>
                                                      handleQuantityChange(
                                                        addonItemsData,
                                                        -1
                                                      )
                                                    }
                                                  >
                                                    <i className="ri-subtract-line"></i>
                                                  </Button>

                                                  <input
                                                    type="number"
                                                    className="count"
                                                    name={`qty_${addonItemsData.id}`}
                                                    value={
                                                      selectedCheckBoxAddon.find(
                                                        (item) =>
                                                          item.id ===
                                                          addonItemsData.id
                                                      )?.quantity || 1
                                                    }
                                                    readOnly
                                                  />
                                                  <Button
                                                    style={
                                                      selectedCheckBoxAddon.find(
                                                        (item) =>
                                                          item.id ===
                                                          addonItemsData.id
                                                      )?.quantity ===
                                                      addonItemsData.maxItemAllowed
                                                        ? {
                                                            background:
                                                              "#c1c1c1",
                                                          }
                                                        : {}
                                                    }
                                                    type="button"
                                                    variant="outline-dark"
                                                    className="minus"
                                                    onClick={() =>
                                                      handleQuantityChange(
                                                        addonItemsData,
                                                        1
                                                      )
                                                    }
                                                  >
                                                    <i className="ri-add-line"></i>
                                                  </Button>
                                                </div>
                                              )}
                                              <h5 className="fs-14 ms-2">
                                                {
                                                  parsedBranchData?.currencySymbol
                                                }{" "}
                                                {selectedCheckBoxAddon.find(
                                                  (item) =>
                                                    item.id ===
                                                    addonItemsData.id
                                                )?.totalPrice ||
                                                  addonItemsData.price}
                                              </h5>
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )
                    )}
                  </div>
                </>
              )}
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer d-flex justify-content-end align-items-center p-3">
          <Button
            variant="default"
            className="d-flex align-items-center me-2"
            onClick={resetAlltems}
          >
            <span className="align-middle">Reset</span>
          </Button>
          <Button
            variant="danger"
            className="fs-14 d-flex align-items-center justify-content-between fw-semibold"
            type="submit"
            onClick={AddToCart}
          >
            <span className="align-middle me-2">
              {props?.modifyItemId ? "Update Cart" : "Add To Cart"}
            </span>
            <span className="align-middle">
              ({parsedBranchData?.currencySymbol} {cartAmount})
            </span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
