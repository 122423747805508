import { Navigate } from "react-router-dom";
import * as itemAction from "../actions/ItemAction";
import * as addons from "../actions/addOnsAction";
import * as varianceAction from "../actions/varianceAction";
import * as Loading from "../actions/loaderAction";
import {
  GetAddOnsGroupList,
  GetCatalogueItemsByAddonsId,
} from "../actions/addOnsAction";
import { GetTaxList } from "./../actions/taxAction";
import ToastifyService from "./../../View/_common/ToastifyService";
import { CatalogueFeatureSuccess } from './../actions/varianceAction';

const GetItemCatalog =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    const branch = JSON.parse(localStorage.getItem("branch"));
    if (action.type === itemAction.CATALOG_LIST) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "itemCatalogueList" })
        );
        const ItemCatalogData = await api.itemAPI.GetItemCatalog();
        dispatch(itemAction.CatalogSuccess(ItemCatalogData));
        dispatch(itemAction.IsCatalogueCodeExist);
        dispatch(varianceAction.GetVarianceError)
        dispatch(
          Loading.setLoading({ loading: false, value: "itemCatalogueList" })
        );
      } catch (error) {
        dispatch(
          Loading.setLoading({ loading: false, value: "itemCatalogueList" })
        );
        if (error.response?.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }   
     else if (action.type === itemAction.CATALOG_LIST_PAGINATION) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "itemCatalogueList" })
        );
        const ItemCatalogData = await api.itemAPI.GetItemCatalogPagination(action.payload);
        dispatch(itemAction.CatalogPaginationSuccess(ItemCatalogData));
        dispatch(itemAction.IsCatalogueCodeExist);
        dispatch(varianceAction.GetVarianceError)
        dispatch(
          Loading.setLoading({ loading: false, value: "itemCatalogueList" })
        );
      } catch (error) {
        dispatch(
          Loading.setLoading({ loading: false, value: "itemCatalogueList" })
        );
        if (error.response?.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const CreateItemCatalog =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === itemAction.CREATE_CATALOG) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "itemDetails" }));
        const CatalogueItemById = await api.itemAPI.CreateItemCatalog(
          action.payload.data
        );
        dispatch(itemAction.GetCatalogueId(CatalogueItemById.catalogue.id));
        dispatch(Loading.setLoading({ loading: false, value: "itemDetails" }));
        ToastifyService.success(`Item Added Successfully!`);
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "itemDetails" }));
        if (error.response?.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const GetCatalogueItemById =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === itemAction.GET_CATALOGUE_ITEM_BY_ID) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "itemDetails" }));
        const CatalogueItemById = await api.itemAPI.GetCatalogueItemById(
          action.payload.data
        );
        dispatch(itemAction.CatalogueItemByIdSuccess(CatalogueItemById));
        dispatch(itemAction.IsCatalogueCodeExist);
        dispatch(itemAction.CatalogueFeaturesSuccess(CatalogueItemById.catalogueFeatures))
        dispatch(Loading.setLoading({ loading: false, value: "itemDetails" }));
        dispatch(GetTaxList);
      } catch (error) {
        if (error.response?.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const GetCatalogueByCatId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === itemAction.GET_CATALOG_BY_CATID) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const CatalogueItemById = await api.itemAPI.GetCatalogueByCatId(
          action.payload.data
        );
        dispatch(itemAction.GetCatalogByCatIdSuccess(CatalogueItemById));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response?.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

const GetAddOnsList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === itemAction.GET_ADDONS) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const AddOnsList = await api.itemAPI.GetAddOnsList(action.payload.data);
        dispatch(itemAction.GetAddOnSuccess(AddOnsList));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response?.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateItemCatalog =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === itemAction.UPDATE_ITEMCATALOG) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const ItemCatalogData = await api.itemAPI.UpdateItemCatalog(
          action.payload.data
        );
        dispatch(itemAction.CatalogueItemByIdSuccess(ItemCatalogData));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response?.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const DeleteItem =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === itemAction.DELETE_ITEM) {
      try {
        const ItemCatalogData = await api.itemAPI.DeleteItem(action.payload);
        dispatch(itemAction.CatalogSuccess(ItemCatalogData));
        ToastifyService.success(`Item Deleted Successfully!`);
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response?.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const MapAddOnItem =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === itemAction.MAP_ADDON_ITEM) {
      try {
        const AddOnsItemList = await api.itemAPI.MapAddOnItem(
          action.payload.data
        );
        dispatch(
          GetCatalogueItemsByAddonsId(action.payload.data[0].addonGroupId)
        );
        dispatch(itemAction.MapAddOnItemSuccess(AddOnsItemList));
        dispatch(addons.GetAddOnsGroupList);
        ToastifyService.success(`Addons Included Successfully!`);
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response?.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UnMapAddOnItem =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === itemAction.UNMAP_ADDON_ITEM) {
      try {
        const AddOnsItemList = await api.itemAPI.UnMapAddOnItem(
          action.payload.data.mappingId
        );
        if (action.payload.data.id) {
          dispatch(GetCatalogueItemsByAddonsId(action.payload.data.id));
        }
        dispatch(itemAction.MapAddOnItemSuccess(AddOnsItemList));
        dispatch(addons.GetAddOnsGroupList);
        ToastifyService.success(`Addons Excluded Successfully!`);
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response?.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const GetAddOnsMappedList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === itemAction.GET_ADDNS_MAPPED_LIST) {
      try {
        const AddOnsItemList = await api.itemAPI.GetAddOnsMappedList(
          action.payload.data
        );
        dispatch(itemAction.MapAddOnItemSuccess(AddOnsItemList));
      } catch (error) {
        if (error.response?.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const Imgaeupload =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === itemAction.UPLOAD_IMAGE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const imageuploaded = await api.itemAPI.Imgaeupload(
          action.payload.data
        );
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response?.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const ImageGalleryUpload =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === itemAction.UPLOAD_IMAGE_GALLERY) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        console.log(action.payload.data);
        const ImageGalleryUpload = await api.itemAPI.ImageGalleryUpload(
          action.payload.data
        );
        console.log(ImageGalleryUpload);

        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response?.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const IsCatalogueCodeExist =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === itemAction.IS_CATALOGUE_CODE_EXIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));

        const CatalogueCodes = await api.itemAPI.IsCatalogueCodeExist()
        dispatch(itemAction.CatalogueCodeSuccess(CatalogueCodes));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response?.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateItemTaxGroup =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === itemAction.UPDATE_ITEM_TAX_GROUP) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        await api.itemAPI.UpdateItemTaxGroup(action.payload.data);
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        ToastifyService.success("Tax Details Updated Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response?.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateCatalogueAction =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === itemAction.UPDATE_CATALOGUE_ACTION) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const CatalogueItemById = await api.itemAPI.UpdateCatalogueAction(
          action.payload
        );
        dispatch(itemAction.CatalogueItemByIdSuccess(CatalogueItemById));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response?.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateCatalogFeatures =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === itemAction.UPDATE_CATALOG_FEATURES) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const CatalogueItemById = await api.itemAPI.UpdateCatalogFeatures(
          action.payload
        );
        dispatch(itemAction.CatalogueFeaturesSuccess(CatalogueItemById.result));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response?.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateKOTMode =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === itemAction.UPDATE_KOT_MODE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const CatalogueItemById = await api.itemAPI.UpdateKOTMode(
          action.payload
        );
        dispatch(itemAction.CatalogueItemByIdSuccess(CatalogueItemById));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response?.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateAddOnsMode =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === itemAction.UPDATE_ADDONS_MODE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const CatalogueItemById = await api.itemAPI.UpdateAddOnsMode(
          action.payload
        );
        dispatch(itemAction.CatalogueItemByIdSuccess(CatalogueItemById));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response?.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

export default [
  ImageGalleryUpload,
  GetItemCatalog,
  CreateItemCatalog,
  GetCatalogueItemById,
  GetAddOnsList,
  UpdateItemCatalog,
  DeleteItem,
  GetAddOnsMappedList,
  Imgaeupload,
  MapAddOnItem,
  GetCatalogueByCatId,
  IsCatalogueCodeExist,
  UpdateItemTaxGroup,
  UpdateCatalogueAction,
  UnMapAddOnItem,
  UpdateAddOnsMode,
  UpdateKOTMode,
  UpdateCatalogFeatures,
];
