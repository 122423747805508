import React, { useContext, useEffect, useRef, useState } from "react";
import ParameterControl from "./PrinterTemplate/parameter-control";
import Environment from "../../../infrastructure/core/Environment";
import { Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLoading } from "../../../application/selectors/indexSelector";
import { Imgaeupload } from "../../../application/actions/ItemAction";
import PlaceholderImage from "../../../assets/img/empty-image/placeholde-image.svg";
import { PrintContext } from "../../../infrastructure/core/helpers/PrintContext";

export default function KOTSlip() {
  const {setData} = useContext(PrintContext)
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { loading } = useSelector(getLoading);

  const [imagePath, setImagePath] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imagePath = e.target.result;
        setImagePath(imagePath);
        const image = imagePath.split(",")[1];
        const id = 2;
        const data = {
          image: image,
          id: id,
          foldername: "Icon",
        };
        dispatch(Imgaeupload(data));
      };

      reader.readAsDataURL(file);
    }
  };

  const initialState = {
    branchId: Environment.defaultValue,
    status: Environment.defaultValue,
    isVisible: true,
    isDeleted: true,
    templateName: "",
    templateTypeId: Environment.defaultValue,
    isLogo: true,
    isBrandName: true,
    isBrandNameBold: true,
    isBrandNameItalic: true,
    isBrandNameUnderlined: true,
    brandNameFontSize: "",
    isLegelEntityName: true,
    isLegelEntityNameBold: true,
    isLegelEntityNameItalic: true,
    isLegelEntityNameUnderlined: true,
    legelEntityNameFontSize: "",
    isMailingAddress: true,
    isMailingAddressBold: true,
    isMailingAddressItalic: true,
    isMailingAddressUnderlined: true,
    mailingAddressFontSize: "",
    isTaxNumber: true,
    isTaxNumberBold: true,
    isTaxNumberItalic: true,
    isTaxNumberUnderlined: true,
    taxNumberFontSize: "",
    isInvoiceNumber: true,
    isInvoiceNumberBold: true,
    isInvoiceNumberItalic: true,
    isInvoiceNumberUnderlined: true,
    invoiceNumberFontSize: "",
    isOrdeerCreatedDate: true,
    isOrdeerCreatedDateBold: true,
    isOrdeerCreatedDateItalic: true,
    isOrdeerCreatedDateUnderlined: true,
    ordeerCreatedDateFontSize: "",
    isCashierName: true,
    isCashierNameBold: true,
    isCashierNameItalic: true,
    isCashierNameUnderlined: true,
    cashierNameFontSize: "",
    isOrderType: true,
    isOrderTypeBold: true,
    isOrderTypeItalic: true,
    isOrderTypeUnderlined: true,
    orderTypeFontSize: "",
    isKOTId: true,
    isKOTIdBold: true,
    isKOTIdItalic: true,
    isKOTIdUnderlined: true,
    kotIdFontSize: "",
    isKitchenName: true,
    isKitchenNameBold: true,
    isKitchenNameItalic: true,
    isKitchenNameUnderlined: true,
    kitchenNameFontSize: "",
    isCustomerName: true,
    isCustomerNameBold: true,
    isCustomerNameItalic: true,
    isCustomerNameUnderlined: true,
    customerNameFontSize: "",
    isCustomerAddress: true,
    isCustomerAddressBold: true,
    isCustomerAddressItalic: true,
    isCustomerAddressUnderlined: true,
    customerAddressFontSize: "",
    isSection: true,
    isSectionBold: true,
    isSectionItalic: true,
    isSectionUnderlined: true,
    sectionFontSize: "",
    isTableNumber: true,
    isTableNumberBold: true,
    isTableNumberItalic: true,
    isTableNumberUnderlined: true,
    tableNumberFontSize: "",
    isServedByName: true,
    isServedByNameBold: true,
    isServedByNameItalic: true,
    isServedByNameUnderlined: true,
    servedByNameFontSize: "",
    isItemName: true,
    isItemNameBold: true,
    isItemNameItalic: true,
    isItemNameUnderlined: true,
    itemNameFontSize: "",
    isItemCustomization: true,
    isItemCustomizationBold: true,
    isItemCustomizationItalic: true,
    isItemCustomizationUnderlined: true,
    itemCustomizationFontSize: "",
    isUnitPrice: true,
    isUnitPriceBold: true,
    isUnitPriceItalic: true,
    isUnitPriceUnderlined: true,
    unitPriceFontSize: "",
    isUnitQuantity: true,
    isUnitQuantityBold: true,
    isUnitQuantityItalic: true,
    isUnitQuantityUnderlined: true,
    unitQuantityFontSize: "",
    isItemTaxAmount: true,
    isItemTaxAmountBold: true,
    isItemTaxAmountItalic: true,
    isItemTaxAmountUnderlined: true,
    itemTaxAmountFontSize: "",
    isTotalPrice: true,
    isTotalPriceBold: true,
    isTotalPriceItalic: true,
    isTotalPriceUnderlined: true,
    totalPriceFontSize: "",
    isItemRemark: true,
    isItemRemarkBold: true,
    isItemRemarkItalic: true,
    isItemRemarkUnderlined: true,
    itemRemarkFontSize: "",
    isTitleText: true,
    isTitleTextBold: true,
    isTitleTextItalic: true,
    isTitleTextUnderlined: true,
    titleTextFontSize: "",
    isCancelledItems: true,
    isCacelledItemsBold: true,
    isCacelledItemsItalic: true,
    isCacelledItemsUnderlined: true,
    isCancelledItemStriked: true,
    cacelledItemsFontSize: "",
    isPaymentCreackUpSummary: true,
    isQrOption: true,
    isPrintedOn: true,
    isPrintedOnBold: true,
    isPrintedOnItalic: true,
    isPrintedOnUnderlined: true,
    printedOnFontSize: "",
    isReprintedIndicator: true,
    isReprintedIndicatorBold: true,
    isReprintedIndicatorItalic: true,
    isReprintedIndicatorUnderlined: true,
    reprintedIndicatorFontSize: ""
  };
  const [formData, setFormData] = useState(initialState);
useEffect(()=>{
console.log(formData);
setData(formData)
},[formData])
console.log(formData);

  return (

    <React.Fragment>
      {/* HEADER CONTENT */}
      <div>
        <div className="d-flex mt-3 mb-4">
          <div className="me-3">
            <div className="item-manage-thumbnail">
              <div className="text-center bg-white thumbnail h-100">
                <Form.Group className="h-100" controlId="formFile">
                  <div className="custom-update-image">
                    <Link className="edit-image bg-dark text-white">
                      <i className="ri-pencil-line fs-16"></i>
                    </Link>
                    <div className={`${loading ? "placeholder" : ""}uploaded-image`}
                    >
                      <Form.Control
                        type="file"
                        className="custom-file-input"
                         ref={fileInputRef}
                         onChange={handleFileChange}
                        accept=".jpg, .jpeg, .png"
                      />
                      <img
                         src={PlaceholderImage}
                        onError={(e) => { e.target.src = "https://fudeasy.oss-me-east-1.aliyuncs.com/FE/catalogueThumbnail/noImage.png" }}
                         className={`${loading ? "placeholder" : ""}img-fluid w-100 h-100`}
                        alt="thumbnail"
                       srcSet={imagePath}
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>
          <div>
            <h3 className="fs-16 text-dark mb-1 fw-semibold">Brand Logo</h3>
            <p className="fs-14 text-dark">Set the logo image. Only <br /> *.png, *.jpg, and *.jpeg image file are accepted.</p>
          </div>
        </div>

        <div className="divider divider-start">
          <span>Header Content</span>
        </div>

        <Row className="mb-3 g-3">
          <Col md={4} sm={12}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Logo"
              onChange={(e) => {
                setFormData({ ...formData, isLogo: e.target.checked });
              }}
              checked={formData.isLogo}
              name="isLogo"
            />
          </Col>
          <Col></Col>
        </Row>


        <ParameterControl
          label="Order Type"
          name="isOrderType"
          formData={formData}
          setFormData={setFormData}
        />

        <ParameterControl
          label="KOT ID"
          name="isKOTId"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Kitchen Name"
          name="isKitchenName"
          formData={formData}
          setFormData={setFormData}
        />

      </div>

      {/* SUB HEADER CONTENT */}
      <div>
        <div className="divider divider-start text-dark"><span>Sub Header Content</span></div>
        <ParameterControl
          label="Section Name"
          name="isSection"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Table Number"
          name="isTableNumber"
          formData={formData}
          setFormData={setFormData}
        />

        <ParameterControl
          label="Server Name"
          name="isServedByName"
          formData={formData}
          setFormData={setFormData}
        />
      </div>

      {/* KOT ITEM DETAILS */}
      <div>
        <div className="divider divider-start text-dark g-3"><span>KOT Item Detail</span></div>
        <ParameterControl
          label="Item Name"
          name="isItemName"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Item Customization (CM)"
          name="isItemCustomization"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Item Remark"
          name="isItemRemark"
          formData={formData}
          setFormData={setFormData}
        />
      </div>

      {/* CANCELLED ITEMS */}
      <div>
        <div className="divider divider-start text-dark"><span>Cancelled Items</span></div>
        <ParameterControl
          label="Title Text"
          name="isTitleText"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Item Name"
          name="isCancelledItems"
          formData={formData}
          setFormData={setFormData}
        />
       
      </div>

      {/* FOOTER CONTENT */}
      <div>
        <div className="divider divider-start text-dark"><span>Footer Content</span></div>
        <ParameterControl
          label="Printer Date Time"
          name="isPrintedOn"
          formData={formData}
          setFormData={setFormData}
        />
        <ParameterControl
          label="Printer Date Time"
          name="isReprintedIndicator"
          formData={formData}
          setFormData={setFormData}
        />
      </div>
    </React.Fragment>
  );
}
