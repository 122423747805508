export const CATALOG_LIST = "CATALOG_LIST";
export const CATALOG_LIST_PAGINATION = "CATALOG_LIST_PAGINATION";
export const CATALOG_LIST_SUCCESS = "CATALOG_LIST_SUCCESS";
export const CATALOG_LIST_PAGINATION_SUCCESS = "CATALOG_LIST_PAGINATION_SUCCESS";
export const CREATE_CATALOG = "CREATE_CATALOG";
export const CREATE_CATALOG_SUCCESS = "CREATE_CATALOG_SUCCESS";
export const GET_CATALOGUE_ITEM_BY_ID = "GET_CATALOGUE_ITEM_BY_ID";
export const GET_CATALOGUE_ITEM_BY_ID_SUCCESS =  "GET_CATALOGUE_ITEM_BY_ID_SUCCESS";
export const GET_ADDONS = "GET_ADDONS";
export const GET_ADDONS_SUCCESS = "GET_ADDONS_SUCCESS";
export const UPDATE_ITEMCATALOG = "UPDATE_ITEMCATALOG";
export const UPDATE_ITEM_SUCCESS = "UPDATE_ITEM_SUCCESS";
export const DELETE_ITEM = "DELETE_ITEM";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_IMAGE_GALLERY = "UPLOAD_IMAGE_GALLERY";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IUPLOAD_IMAGE_SUCCESSMAGE";
export const MAP_ADDON_ITEM = "MAP_ADDON_ITEM";
export const UNMAP_ADDON_ITEM = "UNMAP_ADDON_ITEM";
export const MAP_ADDON_ITEM_SUCCESS = "MAP_ADDON_ITEM_SUCCESS";
export const GET_ADDNS_MAPPED_LIST = "GET_ADDNS_MAPPED_LIST";
export const GET_CATALOG_BY_CATID = "GET_CATALOG_BY_CATID";
export const GET_CATALOG_BY_CATID_SUCCESS = "GET_CATALOG_BY_CATID_SUCCESS";
export const COMMON_FILED = "COMMON_FILED";
export const COMMON_FILED_SUCCESS = "COMMON_FILED_SUCCESS";
export const COMMON_SIZE_VARIANTS_SUCCESS = "COMMON_SIZE_VARIANTS_SUCCESS";
export const COMMON_MEASUREMENT_SUCCESS = "COMMON_MEASUREMENT_SUCCESS";
export const IS_CATALOGUE_CODE_EXIST = "IS_CATALOGUE_CODE_EXIST";
export const CATALOGUE_CODE_SUCCESS = "CATALOGUE_CODE_SUCCESS";
export const UPDATE_ITEM_TAX_GROUP = "UPDATE_ITEM_TAX_GROUP";
export const UPDATE_ITEM_TAX_GROUP_SUCCESS = "UPDATE_ITEM_TAX_GROUP_SUCCESS";
export const UPDATE_CATALOGUE_ACTION = "UPDATE_CATALOGUE_ACTION";
export const UPDATE_CATALOG_FEATURES = "UPDATE_CATALOG_FEATURES";
export const UPDATE_ADDONS_MODE = "UPDATE_ADDONS_MODE";
export const UPDATE_KOT_MODE = "UPDATE_KOT_MODE";
export const CATALOGUE_FEATURE_SUCCESS = "CATALOGUE_FEATURE_SUCCESS";
export const GET_CATALOGUE_ITEM_BY_ID_ERROR = "GET_CATALOGUE_ITEM_BY_ID_ERROR";
export const GET_CATALOGUEID = "GET_CATALOGUEID";

export const GetItemCatalog = {
  type: CATALOG_LIST,
};
export const GetItemCatalogPagination =(data)=>({
  type: CATALOG_LIST_PAGINATION,
  payload:data
}) 
export const CatalogSuccess = (data) => ({
  type: CATALOG_LIST_SUCCESS,
  payload: data ,
});
export const CatalogPaginationSuccess = (data) => ({
  type: CATALOG_LIST_PAGINATION_SUCCESS,
  payload: {data} ,
});
export const CreateItemCatalog = (data) => ({
  type: CREATE_CATALOG,
  payload: { data },
});
export const CreateSuccess = (data) => ({
  type: CREATE_CATALOG_SUCCESS,
  payload: { data },
});
export const GetCatalogueItemById = (data) => ({
  type: GET_CATALOGUE_ITEM_BY_ID,
  payload: { data },
});
export const CatalogueItemByIdSuccess = (data) => ({
  type: GET_CATALOGUE_ITEM_BY_ID_SUCCESS,
  payload: { data },
});export const GetCatalogueId = (data) => ({
  type: GET_CATALOGUEID,
  payload: { data },
});
export const CatalogueItemByIdError ={
  type: GET_CATALOGUE_ITEM_BY_ID_ERROR
};

export const GetAddOnsList = {
  type: GET_ADDONS,
};
export const GetAddOnSuccess = (data) => ({
  type: GET_ADDONS_SUCCESS,
  payload: { data },
});
export const UpdateItemCatalog = (data) => ({
  type: UPDATE_ITEMCATALOG,
  payload: { data },
});
export const UpdateItem_success = (data) => ({
  type: UPDATE_ITEM_SUCCESS,
  payload: { data },
});
export const DeleteItem = (data) => ({
  type: DELETE_ITEM,
  payload: data ,
});
export const MapAddOnItem = (data) => ({
  type: MAP_ADDON_ITEM,
  payload: { data },
});
export const UnMapAddOnItem = (data) => ({
  type: UNMAP_ADDON_ITEM,
  payload: { data },
});
export const MapAddOnItemSuccess = (data) => ({
  type: MAP_ADDON_ITEM_SUCCESS,
  payload: { data },
});
export const GetMappedAddOns = (data) => ({
  type: GET_ADDNS_MAPPED_LIST,
  payload: { data },
});
export const Imgaeupload = (data) => ({
  type: UPLOAD_IMAGE,
  payload: { data },
});
export const ImageGalleryUpload = (data) => ({
  type: UPLOAD_IMAGE_GALLERY,
  payload: { data },
});
export const Imgaeuploadsuccess = (data) => ({
  type: UPLOAD_IMAGE_SUCCESS,
  payload: { data },
});
export const GetCatalogByCatId = (data) => ({
  type: GET_CATALOG_BY_CATID,
  payload: { data },
});
export const GetCatalogByCatIdSuccess = (data) => ({
  type: GET_CATALOG_BY_CATID_SUCCESS,
  payload: { data },
});
export const IsCatalogueCodeExist = {
  type: IS_CATALOGUE_CODE_EXIST,
};
export const CatalogueCodeSuccess= (data) => ({
  type: CATALOGUE_CODE_SUCCESS,
  payload: { data },
});
export const UpdateItemTaxGroup= (data) => ({
  type: UPDATE_ITEM_TAX_GROUP,
  payload: { data },
});
export const UpdateItemTaxGroupSuccess= (data) => ({
  type: UPDATE_ITEM_TAX_GROUP_SUCCESS,
  payload: { data },
});
export const UpdateCatalogueAction= (data) => ({
  type: UPDATE_CATALOGUE_ACTION,
  payload:  data ,
});
export const UpdateCatalogFeatures= (id) => ({
  type: UPDATE_CATALOG_FEATURES,
  payload:  id ,
});
export const UpdateAddonsMode= (id) => ({
  type: UPDATE_ADDONS_MODE,
  payload:  id ,
});
export const UpdateKOTMode= (id) => ({
  type: UPDATE_KOT_MODE,
  payload:  id ,
});
export const CatalogueFeaturesSuccess=(data)=>({
  type:CATALOGUE_FEATURE_SUCCESS,
  payload:data
})