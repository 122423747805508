import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Offcanvas, Tabs, Tab } from "react-bootstrap";
import KOTSlip from "../kotSlip";
import PrintView from "../print-view";
import StoreBill from "../store-bill";
import { PrintContext } from "../../../../infrastructure/core/helpers/PrintContext";
import { useContext } from "react";

export default function CreateTemplate(props) {
    console.log(props);
    
    const {
        setShowPrintView,
    } = useContext(PrintContext);
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const [activeTab, setActiveTab] = useState("template_details_tab");
    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };

    return (
        <React.Fragment>
            <PrintView />
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-50">
                <Offcanvas.Header className="d-block custom-offcanvas-header pe-0 ps-0" closeButton>
                    <Offcanvas.Title className="pe-3 ps-3">Create Print Template</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h3 className="fs-16">Dine In</h3>

                        <div>
                            <Button
                                variant="outline-dark"
                                className="btn-icon me-2"
                                onClick={() => setShowPrintView(true)}>
                                <i className="ri-eye-line"></i>
                            </Button>
                        </div>
                    </div>
                    <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="sale-on-point-tabs">
                        <Tab eventKey="template_details_tab" title={<><h6 className="fs-14 mb-0">Template Details</h6></>} tabClassName="custom-tab-header">
                            <div className="custom-tab-body pt-3">

                            </div>
                        </Tab>

                        <Tab eventKey="template_design_tab1" title={<><h6 className="fs-14 mb-0">Template Design</h6></>} tabClassName="custom-tab-header">
                            <div className="custom-tab-body pt-3">
                                {props.data === "867aa99a-cc41-4ac6-ac3a-e7ab915fc981" && (
                                    <KOTSlip 
                                    />
                                )}
                                {
                                    props.data === "867aa99a-cc41-4ac6-ac3a-e7ab915fc982" && <StoreBill />
                                }

                            </div>
                        </Tab>
                    </Tabs>
                </Offcanvas.Body>
                <div className="offcanvas-footer bg-white">
                    <div>
                        <Button variant="primary" className="fs-14 me-3">
                            <i className="ri-add-line fs-18 lh-1 align-middle"></i>{" "}
                            <span className="align-middle">Create</span>
                        </Button>
                    </div>
                </div>
            </Offcanvas>
        </React.Fragment>
    );
}
