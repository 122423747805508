import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import bg1 from "../../assets/img/login/side-patch.png";
import whiteLogo from "../../assets/img/login/fe-white-logo.png";

export default function SignupComponent() {
  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="4" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              <Card.Title>Sign Up</Card.Title>
              <Card.Text>It's free to signup and only takes a minute.</Card.Text>
            </Card.Header>
            <Card.Body>
              <div className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="text" placeholder="Enter your email address" />
              </div>
              <div className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Enter your password" />
              </div>
              <div className="mb-3">
                <Form.Label>Full name</Form.Label>
                <Form.Control type="text" placeholder="Enter your full name" />
              </div>
              <div className="mb-4">
                <small>By clicking <strong>Create Account</strong> below, you agree to our terms of service and privacy statement.</small>
              </div>
              <Button variant="primary" className="btn-sign">Create Account</Button>
            </Card.Body>
            <Card.Footer>
              Already have an account? <Link to="/account/login">Sign In</Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col className="d-none d-lg-block">
          <div className="login-msg">
            <div className="header-logo mb-3">
              <img className="wt-200" src={whiteLogo} alt="logo" />
            </div>

            <h1 className="text-white fw-semibold">Robust solution for <br /> smart & growing <br /> restaurant</h1>
          </div>
          {/* <img src={bg1} className="h-100 w-100" alt="bg" /> */}
        </Col>
      </Row>
    </div>
  );
}