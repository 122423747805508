import { Col, Form, Row } from "react-bootstrap";

export default function ParameterControl({ label, name, formData, setFormData }) {
  const handleSwitchChange = (e) => {
    setFormData({ ...formData, [name]: e.target.checked });
  };

  const handleStyleToggle = (style) => {
    setFormData((prev) => ({
      ...prev,
      [`${name}${style}`]: !prev[`${name}${style}`],
    }));
  };
  return (
    <Row className="align-items-center mb-3">
      <Col xl={4} xs={4}>
        <Form.Check
          className="me-3 fs-14 text-dark"
          type="switch"
          label={label}
          onChange={handleSwitchChange}
          checked={formData[name]}
          name={name}
        />
      </Col>
      {formData[name] && (
        <Col sm={8}>
          <Row className="justify-content-end align-items-center">
            <Col xl={7} xs={8}>
              <div className="text-right">
                <label className="font-style-bttns">
                  <input
                    type="checkbox"
                    onChange={() => handleStyleToggle("Bold")}
                    checked={formData[`${name}Bold`] || false}
                    name={`${name}Bold`}
                  />
                  <span>
                    <i className="ri-bold"></i>
                  </span>
                </label>
                <label className="font-style-bttns">
                  <input
                    type="checkbox"
                    onChange={() => handleStyleToggle("Italic")}
                    checked={formData[`${name}Italic`] || false}
                    name={`${name}Italic`}
                  />
                  <span>
                    <i className="ri-italic"></i>
                  </span>
                </label>
                <label className="font-style-bttns">
                  <input
                    type="checkbox"
                    onChange={() => handleStyleToggle("Underlined")}
                    checked={formData[`${name}Underlined`] || false}
                    name={`${name}Underlined`}
                  />
                  <span>
                    <i className="ri-underline"></i>
                  </span>
                </label>
              </div>
            </Col>
            <Col xl={5} xs={4}>
              <div>
                <Form.Select
                  className="fs-14"
                  aria-label={`Select font size for ${label}`}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [`${name}FontSize`]: e.target.value,
                    });
                  }}
                  value={formData[`${name}FontSize`] || ""}
                  name={`${name}FontSize`}
                >
                  <option value="">Select Font Size</option>
                  <option value="Small">Small</option>
                  <option value="Medium">Medium</option>
                  <option value="Large">Large</option>
                  <option value="Extra Large">Extra Large</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </Col>
      )}

    </Row>
  );
}
