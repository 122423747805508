import React, { useEffect, useRef, useState } from "react";
import { Offcanvas, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import AddNewCustomer from "../Customers/add-customer";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerSearchedList,
  getLoading,
  getCurrentCreatedCustomer,
  getWaiterListPOSSuccess,
} from "../../application/selectors/indexSelector";
import Environment from "../../infrastructure/core/Environment";
import {
  createCustomerSuccess,
  getSearchedListOfCustomer,
} from "../../application/actions/customerAction";
import AddNewAddress from "./MyCard/customer/add-address";
import Select from "react-select";

export default function CreateCustomerAAddress(props) {
  const dispatch = useDispatch();
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [customerId, setCustomerId] = useState(Environment.defaultValue);
  const [seller, setSeller] = useState("");
  const [value, setValue] = useState(1);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const blurTimeoutRef = useRef(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const loading = useSelector(getLoading).loading;
  const [customerEmailAddress, setCustomerEmailAddress] = useState("");
  const customerSearchedList = useSelector(getCustomerSearchedList);
  const currentCreatedCustomer = useSelector(getCurrentCreatedCustomer);
  const [showAddNewAddree, setShowAddNewAddree] = useState(false);
  const [addressId, setAddressId] = useState(Environment.defaultValue);
  const [operationValue, setOperationValue] = useState("AddNewAddress");
  const [selectedAddress, setSelectedAddress] = useState(
    Environment.defaultValue
  );
  const waiterList = useSelector(getWaiterListPOSSuccess)
    .filter((waiterRole) => waiterRole.isOrderTake === true)
    .map((waiter) => ({
      value: waiter.id,
      label: waiter.fullName,
    }));
  // console.log(
  //   " props?.orderType?.id === a5ca48b8-bfd1-42d6-8db1-7021c131e003 || customer === undefined",
  //   props?.orderType?.id === "a5ca48b8-bfd1-42d6-8db1-7021c131e003" ||
  //     customer === undefined
  // );
  // console.log("customer === undefined", customer === undefined);
  // console.log(
  //   " props?.orderType?.id === a5ca48b8-bfd1-42d6-8db1-7021c131e003",
  //   props?.orderType?.id === "a5ca48b8-bfd1-42d6-8db1-7021c131e003"
  // );
  useEffect(() => {
    if (props?.show) {
      setCustomerId(Environment.defaultValue);
      setSeller("");
      setValue(1);
      setSearchQuery("");
      setErrors({});
    }
  }, [props.show]);
  useEffect(() => {
    if (customerSearchedList) {
      setSearchResults(customerSearchedList);
    }
  }, [customerSearchedList]);
  useEffect(() => {
    if (currentCreatedCustomer) {
      handleSelectCustomer(currentCreatedCustomer, "number");
    }
  }, [currentCreatedCustomer]);
  const handleAddCustomer = (state) => {
    setShowAddCustomer(state);
  };
  const handleSelectCustomer = (selected, type) => {
    if (selected) {
      if (type === "number") {
        setCustomer(selected);
        setCustomerId(selected?.id);
        setSearchQuery(selected?.contactNumber);
      }
    }
  };
  const handleBlur = () => {
    blurTimeoutRef.current = setTimeout(() => {
      setMenuIsOpen(false);
    }, 200);
  };
  const handleFocus = () => {
    setMenuIsOpen(false);
    clearTimeout(blurTimeoutRef.current);
  };
  const fetchCustomer = (phone) => {
    setError("");
    dispatch(getSearchedListOfCustomer(phone));
  };
  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    setMenuIsOpen(value.length >= 7 && searchResults?.length !== 0);
    setShowAddCustomer(false);
    if (value.length === 10 && searchResults?.length === 0) {
      setShowAddCustomer(true);
      setSearchResults(undefined);
    } else if (value.length >= 7) {
      fetchCustomer(value);
    } else {
      setCustomer(null);
      setSearchResults(undefined);
    }
  };
  const handleSubmit = () => {
    if (seller === "") {
      const newErrors = {};
      newErrors.noSellerSelected = "Please Select Seller";
      setErrors(newErrors);
      return;
    }

    const formObject = {
      guestCount: value,
      sellerId: seller,
      customerId: customerId,
    };
    props?.handleAddItem(props.selectedTab?.tableData, true, formObject);
  };
  const handleAddNewAddress = (state, addressId, data) => {
    setShowAddNewAddree(state);
    setAddressId(addressId);
    setOperationValue(data);
  };
  const handleSetAddressDefault = () => {
    // props.onCustomerAddressSelect(selectedAddress);
    // props?.closeFunction();
  };
  return (
    <React.Fragment>
      {showAddNewAddree && (
        <AddNewAddress
          show={showAddNewAddree}
          closeFunction={handleAddNewAddress}
          operationValue={operationValue}
          getCustomerDetails={customer}
        />
      )}

      <AddNewCustomer
        show={showAddCustomer}
        closeFunction={handleAddCustomer}
        searchQuery={searchQuery}
        fromComponent={"CreateCustomerAAddress"}
        customerEmailAddress={customerEmailAddress}
        setCustomerEmailAddress={setCustomerEmailAddress}
      />
      <Offcanvas
        show={props.show}
        onHide={() => {
          dispatch(createCustomerSuccess(undefined));
          setSelectedAddress(Environment.defaultValue);
          setCustomerId(Environment.defaultValue);
          setCustomer(null);
          setSearchQuery("");
          props.closeFunction();
        }}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header
          closeButton
          onClick={() => {
            dispatch(createCustomerSuccess(undefined));
            setSelectedAddress(Environment.defaultValue);
            setCustomerId(Environment.defaultValue);
            setCustomer(null);
            setSearchQuery("");
            props.closeFunction();
          }}
        >
          <Offcanvas.Title>{props?.orderType?.typeName}</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row className="mb-4">
            <Col md={8}>
              <div className="mb-4 position-relative z-index-10 text-dark">
                <Form.Label className="text-dark fs-12 fw-semibold">
                  Seller Name <sup className="text-danger">*</sup>
                </Form.Label>
                <Select
                  options={waiterList}
                  isSearchable={true}
                  onChange={(e) => {
                    setSeller(e.value);
                    setErrors({ ...errors, noSellerSelected: "" });
                  }}
                />
                <Form.Text className="text-danger">
                  {errors.noSellerSelected}
                </Form.Text>
              </div>
            </Col>
            <Col md={8}>
              <div className="mb-2 custom-dropdown-wrapper position-relative">
                <Form.Label className="text-dark fs-12 fw-semibold">
                  Customer Number
                </Form.Label>
                <div className="custom-drop-down z-index-2">
                  <span className="dropdown-icon">+91</span>
                  <Form.Control
                    type="text"
                    className="text-dark "
                    placeholder="Primary Contact Number"
                    value={searchQuery}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleSearchChange}
                    maxLength={10}
                    style={{ paddingLeft: "45px" }}
                  />
                </div>
                {menuIsOpen && (
                  <ul className="options-container">
                    {searchResults?.map((customer) => (
                      <li
                        key={customer.id}
                        onClick={() => handleSelectCustomer(customer, "number")}
                        className="option"
                      >
                        <span className="fs-14">{customer.contactNumber}</span>
                        <small className="fs-12">{customer.fullName}</small>
                      </li>
                    ))}
                    {searchResults?.length === 0 && searchQuery?.length > 7 && (
                      <li className="d-block" onClick={handleAddCustomer}>
                        <a className="d-flex align-items-center justify-content-center pt-2 pb-2 text-center fs-12">
                          <i className="ri-add-line fs-16 lh-1"></i>
                          <span className="d-sm-inline"> Add New Customer</span>
                        </a>
                      </li>
                    )}
                  </ul>
                )}
              </div>
            </Col>

            <>
              {loading && value === "getSearchedListOfCustomer" && (
                <div className="customer-list-spinner">
                  <Spinner
                    className="align-middle"
                    animation="border"
                    variant="primary"
                  />
                </div>
              )}
              {error && <p>{error}</p>}
              {customer ? (
                <div className="d-flex align-items-center gap-2">
                  <p className="fs-14">Name: <b>{customer.fullName}</b></p>

                  <Button variant="outline-primary" className="fs-12 d-flex align-items-center justify-content-center pt-1 pb-1"
                      onClick={() => handleAddNewAddress(true, Environment.defaultValue, "AddNewAddress")}>
                      <i className="ri-add-line fs-14 lh-1 align-middle"></i>
                      <span className="align-middle">Add New Address</span>
                    </Button>
                </div>
              ) : (
                phoneNumber.length === 10 && <></>
              )}
            </>
          </Row>
          {props?.orderType?.id === "a5ca48b8-bfd1-42d6-8db1-7021c131e003" && (
            <Row>
              {customer && (
                <>
                  <div className="existing-address">
                    <div className="existing-address">
                      {customer?.userAddresses
                        ?.filter((address) => address.isDeleted === false)
                        ?.map((address, index) => (
                          <div
                            key={index}
                            className="selected-address"
                            onClick={() => setSelectedAddress(address?.id)}
                          >
                            <input
                              type="radio"
                              id={`address${index}`}
                              name="existingAddress"
                            />
                            <label htmlFor={`address${index}`}>
                              {`${address.buildingName}, ${address.roomNo}, ${address.landmark}, ${address.city}, ${address.state}, ${address.zipCode}.`}
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              )}
            </Row>
          )}
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <Button
            type="button"
            variant="primary"
            className="fs-14 d-flex justify-content-center gap-2 align-items-center pe-4 ps-4"
            disabled={
              (props?.orderType?.id ===
                "a5ca48b8-bfd1-42d6-8db1-7021c131e003" &&
                (customer === null ||
                  selectedAddress === Environment.defaultValue)) ||
              (props?.orderType?.id ===
                "a5ca48b8-bfd1-42d6-8db1-7021c131e002" &&
                customer === null)
            }
            onClick={() => {
              if (seller === "") {
                const newErrors = {};
                newErrors.noSellerSelected = "Please Select Seller";
                setErrors(newErrors);
                return;
              }

              const formObject = {
                guestCount: 0,
                sellerId: seller,
                customerId: customerId,
              };
              props?.handleAddItem(
                props.selectedTab?.tableData,
                true,
                formObject
              );
            }}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">PROCEED</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
