import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { useDispatch } from "react-redux";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import {
  AddVariance,
  MapBranchVariance,
  UpdateBranchVariance,
} from "../../../../application/actions/varianceAction";
import Environment from "../../../../infrastructure/core/Environment";
import AuthContext from "../../../../infrastructure/core/helpers/AuthContext";
import { useParams } from "react-router-dom";

function EditVariant(props) {
  const { user } = useContext(AuthContext);
  const { id } = useParams();

  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const dispatch = useDispatch();
  const initialState={
    id: undefined,
    isVisible: true,
    isDeleted: false,
    createdBy: user.id,
    modifiedBy: user.id,
    status: "a5ca48b8-bfd1-42d6-8db1-7021c131e400",
    catalogueId: id,
    variantMode: Environment.defaultValue,
    variantType: "",
    variantName: "",
    sizeId: "00000000-0000-0000-0000-000000000000",
    sellingPrice:'',
    markupPrice:'',
    preparationPrice: '',
    locations: [],
    measurmentTypeId: Environment.defaultValue,
    IsGlobal: props?.IsGlobal,
  }
  const [formData, setFormData] = useState({});
  useEffect(() => {
    if (props.variantData) {
      setFormData({
        ...formData,
        id: props.variantData.id,
        mappingId: props.variantData.branchMappingId,
        catalogueId: props.variantData.catalogueId,
        variantType: props.variantData.variantType,
        sizeId: props.variantData.sizeId,
        variantName: props.variantData.variantName,
        sellingPrice: props.variantData.sellingPrice,
        markupPrice: props.variantData.markupPrice,
        preparationPrice: props.variantData.preparationPrice,
        varianceId: props.variantData.id,
        locations: props.variantData.locations?props.variantData.locations:[] ,
        measurmentTypeId: props.variantData.measurmentTypeId,
        IsGlobal: props.IsGlobal,
      });
    }
  }, [props.variantData]);

  const handleSubmit = () => {
    if (props.variantData.branchId === Environment.defaultValue) {
      dispatch(AddVariance(formData));
    } else if (props.variantData.branchMappingId) {
      const Obj = {
        id: formData.mappingId,
        sellingPrice: formData.sellingPrice,
        markupPrice: formData.markupPrice,
        preparationPrice: formData.preparationPrice,
      };
      dispatch(UpdateBranchVariance(Obj));
    } else {
      const mapMbj = [
        {
          branchId: formData.locations,
          catalogueId:formData.catalogueId,
          varianceId: formData.varianceId,
          sellingPrice:formData.sellingPrice,
          markupPrice: formData.markupPrice,
          preparationPrice: formData.preparationPrice,

         
        },
      ];
      dispatch(MapBranchVariance(mapMbj));
    }
    props.closeFunction();
  };
  return (
    <Offcanvas
      show={props.show}
      onHide={props.closeFunction}
      placement="end"
      className="w-30"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="fs-16">Update Variant</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Row>
          <Col xl={8}>
            <div className="mb-3">
              <Form.Label>
                Selling Price: <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="number" min="1"
                placeholder="Enter Selling Price"
                name="sellingPrice"
                value={formData.sellingPrice}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    sellingPrice: Number(e.target.value),
                  })
                }
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={8}>
            <div className="mb-3">
              <Form.Label>
                Markup Price: <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="number" min="1"
                placeholder="Enter Markup Price"
                name="markupPrice"
                value={formData.markupPrice}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    markupPrice: Number(e.target.value),
                  })
                }
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={8}>
            <div className="mb-3">
              <Form.Label>
                Preparation Cost: <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="number" min="1"
                placeholder="Enter Preparation Cost"
                name="preparationPrice"
                value={formData.preparationPrice}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    preparationPrice: Number(e.target.value),
                  })
                }
              />
            </div>
          </Col>
        </Row>
      </Offcanvas.Body>
      <div className="offcanvas-footer bg-white">
        <Button
          variant="primary"
          className="fs-14 d-flex align-items-center"
          type="submit"
          onClick={handleSubmit}
        >
          <i className="ri-add-line fs-16 lh-1 align-middle me-1"></i>
          <span className="align-middle">Update Variant</span>
        </Button>
      </div>
    </Offcanvas>
  );
}

export default EditVariant;
