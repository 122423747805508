import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from './layouts/Main';
import NotFound from "./pages/NotFound";

import publicRoutes from "./routes/PublicRoutes";
import commonRoutes from "./routes/commonRoute"
import protectedRoutes from "./routes/ProtectedRoutes";

import { configureStore } from './application/store'
import services from './infrastructure/services';
// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";
import { Provider } from "react-redux";
import PrivateRoute from "./routes/PrivateRoute";
import { AuthContextProvider } from "./infrastructure/core/helpers/AuthContext";
import { PrintContextProvider } from "./infrastructure/core/helpers/PrintContext";


// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});
const ROLES = {

  'Admin': 1
}


export default function App() {
  return (
    // <React.Fragment>
    <BrowserRouter>
        <AuthContextProvider>
      <PrintContextProvider>
          <Provider store={configureStore(services)}>
            <Routes>
              <Route path="/" element={<Main />}>
                {protectedRoutes.map((route, index) => {
                  return (
                    <Route
                      path={route.path}
                      element={route.element}
                      key={index}
                    />
                  )
                })}
              </Route>
              {publicRoutes.map((route, index) => {
                return (
                  <Route
                    path={route.path}
                    element={route.element}
                    key={index}
                  />
                )
              })}
              <Route path="/" element={<Main />}>

                {protectedRoutes.map(({ path, element }) => (
                  <Route key={path} path={path} element={<PrivateRoute accessBy="authenticated" element={element} />} />
                ))}
              </Route>

              {publicRoutes.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))}

              {commonRoutes.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))}

              <Route path="*" element={<NotFound />} />
            </Routes>
          </Provider>

      </PrintContextProvider>
        </AuthContextProvider>
    </BrowserRouter>


  );
}