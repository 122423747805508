export const GETITEM_DETAIL_ON_POS = "GETITEM_DETAIL_ON_POS";
export const ITEM_DETAIL_ON_POS_SUCCESS = "ITEM_DETAIL_ON_POS_SUCCESS";
export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const POST_MENUDATA_BY_BRANCHID = "POST_MENUDATA_BY_BRANCHID";
export const GET_WAITERLIST_POS = "GET_WAITERLIST_POS";
export const GET_WAITERLIST_POS_SUCCESS = "GET_WAITERLIST_POS_SUCCESS";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const GET_DISCOUNT_BY_BRANCHID = "GET_DISCOUNT_BY_BRANCHID";
export const GET_DISCOUNT_BY_BRANCHID_SUCCESS =
  "GET_DISCOUNT_BY_BRANCHID_SUCCESS";
export const GET_CHARGE_BY_BRANCHID = "GET_CHARGE_BY_BRANCHID";
export const GET_CHARGE_BY_BRANCHID_SUCCESS = "GET_CHARGE_BY_BRANCHID_SUCCESS";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const ORDER_DETAILS = "ORDER_DETAILS";
export const RESET_ORDER_DETAILS = "RESET_ORDER_DETAILS";
export const APPLY_CHARGES = "APPLY_CHARGES";
export const ORDER_RELATED_CHANGES = "ORDER_RELATED_CHANGES";
export const APPLY_DISCOUNT = "APPLY_DISCOUNT";
export const REMOVE_DISCOUNT = "REMOVE_DISCOUNT";
export const REMOVE_CHARGES = "REMOVE_CHARGES";
export const DELETE_ORDERED_ITEM = "DELETE_ORDERED_ITEM";
export const DELETE_ALL_ORDERED_ITEM = "DELETE_ALL_ORDERED_ITEM";
export const TRANSFER_ORDER = "TRANSFER_ORDER";
export const TABLESECTION_BY_BRANCH = "TABLESECTION_BY_BRANCH";
export const TABLESECTION_BY_BRANCH_SUCCESS = "TABLESECTION_BY_BRANCH_SUCCESS";
export const ORDERS_BY_ORDERTYPE = "ORDERS_BY_ORDERTYPE";
export const ORDERS_BY_ORDERTYPE_SUCCESS = "ORDERS_BY_ORDERTYPE_SUCCESS";
export const TRANSFER_MERGE_CHANGES = "TRANSFER_MERGE_CHANGES";
export const ORDER_SETTLE_PAYMENT = "ORDER_SETTLE_PAYMENT";
export const CLOSE_ORDER = "CLOSE_ORDER";
export const ORDER_WAITER_CHANGE = "ORDER_WAITER_CHANGE";
export const MERGE_TABLE = "MERGE_TABLE";
export const TRANSFERED_TABLE_ORDERID = "TRANSFERED_TABLE_ORDERID";
export const INCRE_KOTPRINTCOUNT = "INCRE_KOTPRINTCOUNT";
export const CHANGE_ORDERSTATUS = "CHANGE_ORDERSTATUS";
export const ADD_TO_FAVOURITES = "ADD_TO_FAVOURITES";
export const GET_ALL_FAVOURITES = "GET_ALL_FAVOURITES";
export const GET_ALL_FAVOURITES_SUCCESS = "GET_ALL_FAVOURITES_SUCCESS";
export const REMOVE_FROM_FAVOURITES = "REMOVE_FROM_FAVOURITES";

export const GetItemDetailOnPOS = (branchId, id) => ({
  type: GETITEM_DETAIL_ON_POS,
  payload: { branchId, id },
});
export const GetItemDetailOnPOSSuccess = (data) => ({
  type: ITEM_DETAIL_ON_POS_SUCCESS,
  payload: data,
});
export const CreateOrder = (data) => ({
  type: CREATE_ORDER,
  payload: data,
});
export const UpdateOrder = (data) => ({
  type: UPDATE_ORDER,
  payload: data,
});
export const CreateOrderSuccess = (data) => ({
  type: CREATE_ORDER_SUCCESS,
  payload: data,
});
export const GetPOSMenuDataByBranchId = (data) => ({
  type: POST_MENUDATA_BY_BRANCHID,
  payload: data,
});
export const GetWaiterListPOS = (id) => ({
  type: GET_WAITERLIST_POS,
  payload: id,
});
export const GetWaiterListPOSSuccess = (id) => ({
  type: GET_WAITERLIST_POS_SUCCESS,
  payload: id,
});
export const getDiscountByBranchId = (id) => ({
  type: GET_DISCOUNT_BY_BRANCHID,
  payload: id,
});
export const getDiscountByBranchIdSuccess = (id) => ({
  type: GET_DISCOUNT_BY_BRANCHID_SUCCESS,
  payload: id,
});
export const getChargeByBranchId = (id) => ({
  type: GET_CHARGE_BY_BRANCHID,
  payload: id,
});
export const getChargeByBranchIdSuccess = (id) => ({
  type: GET_CHARGE_BY_BRANCHID_SUCCESS,
  payload: id,
});
export const cancelOrder = (data) => ({
  type: CANCEL_ORDER,
  payload: data,
});
export const orderDetails = (id) => ({
  type: ORDER_DETAILS,
  payload: id,
});
export const applyCharges = (data) => ({
  type: APPLY_CHARGES,
  payload: data,
});
export const removeCharges = (id) => ({
  type: REMOVE_CHARGES,
  payload: id,
});
export const resetOrderDetails = {
  type: RESET_ORDER_DETAILS,
};
export const orderRelatedChanges = (data) => ({
  type: ORDER_RELATED_CHANGES,
  payload: data,
});
export const transferMergeChanges = (data) => ({
  type: TRANSFER_MERGE_CHANGES,
  payload: data,
});
export const applyDiscount = (data) => ({
  type: APPLY_DISCOUNT,
  payload: data,
});
export const removeDiscount = (data) => ({
  type: REMOVE_DISCOUNT,
  payload: data,
});
export const deleteOrderedItem = (id) => ({
  type: DELETE_ORDERED_ITEM,
  payload: id,
});
export const deleteAllOrderedItem = (data) => ({
  type: DELETE_ALL_ORDERED_ITEM,
  payload: data,
});
export const transferOrder = (data) => ({
  type: TRANSFER_ORDER,
  payload: data,
});
export const tableSectionByOrderType = (data) => ({
  type: TABLESECTION_BY_BRANCH,
  payload: data,
});
export const ordersByOrderType = (data) => ({
  type: ORDERS_BY_ORDERTYPE,
  payload: data,
});
export const ordersByOrderTypeSuccess = (data) => ({
  type: ORDERS_BY_ORDERTYPE_SUCCESS,
  payload: data,
});
export const tableSectionByOrderTypeSuccess = (data) => ({
  type: TABLESECTION_BY_BRANCH_SUCCESS,
  payload: data,
});
export const orderSettlePayment = (data) => ({
  type: ORDER_SETTLE_PAYMENT,
  payload: data,
});
export const closeOrder = (data) => ({
  type: CLOSE_ORDER,
  payload: data,
});
export const orderWaiterChange = (data) => ({
  type: ORDER_WAITER_CHANGE,
  payload: data,
});
export const mergeTable = (data) => ({
  type: MERGE_TABLE,
  payload: data,
});
export const transferedTableOrderId = (id) => ({
  type: TRANSFERED_TABLE_ORDERID,
  payload: id,
});
export const increKotprintCount = (data) => ({
  type: INCRE_KOTPRINTCOUNT,
  payload: data,
});
export const changeOrderStatus = (data) => ({
  type: CHANGE_ORDERSTATUS,
  payload: data,
});
export const addToFavourites = (data) => ({
  type: ADD_TO_FAVOURITES,
  payload: data,
});
export const removeFromFavourites = (data) => ({
  type: REMOVE_FROM_FAVOURITES,
  payload: data,
});
export const getAllFavourites = (data) => ({
  type: GET_ALL_FAVOURITES,
  payload: data,
});
export const getAllFavouritesSuccess = (data) => ({
  type: GET_ALL_FAVOURITES_SUCCESS,
  payload: data,
});
