//react packages
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Prism from "prismjs";
import { Card, Button, Badge } from "react-bootstrap";
//empty state
import OrderEmptyState from "../../_emptyState/order-empty";
//selectors
import { getOrdersByOrderType } from "../../../application/selectors/indexSelector";
import Environment from "../../../infrastructure/core/Environment";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import { Link } from "react-router-dom";
import viewOrder from "../../../assets/img/icons/search-file.png";
import CreateCustomerAAddress from "../CreateCustomerAAddress";

export default function HomeDelivery({
  handleAddItem,
  orderType,
  handleOrderDetails,
  handleBillPrintRequest,
  selectedTabSection,
  selectedTab,
}) {
  const { selectedBranchData } = useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);
  const commonfields = JSON.parse(localStorage.getItem("CommonFields"));
  //fetching and storing orders by order type
  const ordersByOrderType = useSelector(getOrdersByOrderType);
  const [openCreateCustAAddress, setOpenCreateCustAAddress] = useState(false);
  //storing the orders by ordertype in useMemo (cache)
  const memoizedOrdersByOrderType = useMemo(() => {
    return ordersByOrderType;
  }, [ordersByOrderType]);
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  return (
    <React.Fragment>
      <CreateCustomerAAddress
        selectedTabSection={selectedTabSection}
        closeFunction={setOpenCreateCustAAddress}
        show={openCreateCustAAddress}
        selectedTab={selectedTab}
        orderType={orderType}
        handleAddItem={handleAddItem}
      />
      <div className="home-delivery-wrapper">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3 className="fs-14 fw-semibold text-black-50">
            List of Pending {orderType?.typeName}
          </h3>
          <Button
            variant="outline-dark sm"
            className="gap-1 d-flex align-items-center btn btn-dark text-white fw-semibold btn-sm"
            onClick={
              () => setOpenCreateCustAAddress(true)
            
            }
          >
            <i className="ri-add-line fs-16"></i>
            <span className="d-sm-inline fs-14">Create New Order</span>
          </Button>
        </div>
        {memoizedOrdersByOrderType &&
        memoizedOrdersByOrderType?.length !== 0 ? (
          <Card className="card-one p-2">
            <div class="table-responsive">
              <table class="table table-agent mb-0">
                <thead>
                  <tr>
                    <th className="wt-100">Order ID</th>
                    {memoizedOrdersByOrderType[0]?.orderTypeId !==
                      "a5ca48b8-bfd1-42d6-8db1-7021c131e003" && (
                      <th className=" wt-200">Token #</th>
                    )}
                    <th className=" wt-150">Customer Name</th>

                    {memoizedOrdersByOrderType[0]?.orderTypeId ===
                      "a5ca48b8-bfd1-42d6-8db1-7021c131e003" && (
                      <th className=" wt-200">Address</th>
                    )}
                    <th>Status</th>
                    <th>Payment</th>
                    <th>Total Items</th>
                    <th>Amount</th>
                    <th>Last Updated</th>
                    <th className="justify-content-middle">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {memoizedOrdersByOrderType?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <a
                          onClick={() =>
                            handleOrderDetails(
                              item.id,
                              {},
                              {
                                tableName: orderType?.typeName,
                                tableTypeId: orderType?.id,
                              },
                              true
                            )
                          }
                          class="fw-bold fs-12"
                        >{`${item.orderNumber.substring(
                          0,
                          item.orderNumber.indexOf("-") + 1
                        )}
                  ${item.orderNumber.substring(2, 8)}`}</a>
                      </td>
                      {memoizedOrdersByOrderType[0]?.orderTypeId !==
                        "a5ca48b8-bfd1-42d6-8db1-7021c131e003" && (
                        <>
                          <td>
                            <h3 className="text-dark fs-12 fw-bold">
                              {/* {item.tokenNumber} :  */}
                              {`${item.orderNumber.substring(
                                item.orderNumber.lastIndexOf("/") + 1
                              )}`}
                            </h3>
                          </td>
                        </>
                      )}

                      <td>
                        <h4 className="mb-0 fs-12 text-dark">
                          <span className="fw-semibold text-dark align-middle">
                            {item?.customerId === Environment.defaultValue
                              ? "Guest"
                              : item?.customerDetails?.fullName}
                          </span>
                        </h4>
                        {item?.customerId !== Environment.defaultValue && (
                          <h4 className="mb-0 fs-12 text-dark">
                            <i className="ri-phone-line fs-12 me-1 align-middle"></i>
                            <span className="fw-semibold text-dark align-middle">
                              {item?.customerDetails?.contactNumber}
                            </span>
                          </h4>
                        )}
                      </td>

                      {memoizedOrdersByOrderType[0]?.orderTypeId ===
                        "a5ca48b8-bfd1-42d6-8db1-7021c131e003" && (
                        <>
                          <td>
                            {item?.customerId !== Environment.defaultValue &&
                              orderType?.id ===
                                "a5ca48b8-bfd1-42d6-8db1-7021c131e003" &&
                              item?.addressId !== Environment.defaultValue && (
                                <div
                                  className="mb-0 fs-12 text-dark three-line-ellipsis wt-200"
                                  style={{ whiteSpace: "normal" }}
                                >
                                  {item?.deliveryAddress?.roomNo},{" "}
                                  {item?.deliveryAddress?.buildingName},{" "}
                                  {item?.deliveryAddress?.location},{" "}
                                  {item?.deliveryAddress?.landmark},{" "}
                                  {item?.deliveryAddress?.zipCode},{" "}
                                  {item?.deliveryAddress?.city},{" "}
                                  {item?.deliveryAddress?.state},{" "}
                                  {item?.deliveryAddress?.country},{" "}
                                  {item?.deliveryAddress?.zipCode}
                                </div>
                              )}
                          </td>
                        </>
                      )}

                      <td>
                        <Badge
                          bg={`${
                            item.orderStatus ===
                            "a5ca48b8-bfd1-42d6-8db1-7021c131e606"
                              ? "primary"
                              : item.orderStatus ===
                                "a5ca48b8-bfd1-42d6-8db1-7021c131e610"
                              ? "success"
                              : item.orderStatus ===
                                "a5ca48b8-bfd1-42d6-8db1-7021c131e601"
                              ? "success"
                              : "primary"
                          }`}
                          className="fs-12 badge-pill p-1"
                        >
                          {
                            commonfields?.find(
                              (cmdflds) => cmdflds.id === item.orderStatus
                            )?.fieldValue
                          }
                        </Badge>
                      </td>
                      <td>
                        <Badge
                          bg={`${
                            item.paymentStatus ===
                            "a5ca48b8-bfd1-42d6-8db1-7021c131e701"
                              ? "success"
                              : item.paymentStatus ===
                                "a5ca48b8-bfd1-42d6-8db1-7021c131e702"
                              ? "danger"
                              : "primary"
                          }`}
                          className="badge-pill fs-12 pw-1"
                        >
                          {
                            commonfields?.find(
                              (cmdflds) => cmdflds.id === item.paymentStatus
                            )?.fieldValue
                          }
                        </Badge>
                      </td>
                      <td className="justify-content-end">
                        <span className="fw-bold text-dark ">
                          {item?.productCount}
                        </span>
                      </td>
                      <td>
                        <h4 className="mb-0 fs-16 fw-bold text-dark">
                          {parsedBranchData?.currencySymbol}{" "}
                          <span
                            className="me-2 text-dark"
                            // className={`me-2 ${
                            //   item.paymentStatus === "a5ca48b8-bfd1-42d6-8db1-7021c131e701" ? "text-success" :
                            //   item.paymentStatus === "a5ca48b8-bfd1-42d6-8db1-7021c131e702" ? "text-danger" : "text-primary"
                            // }`}
                          >
                            {item?.grandTotalPrice}
                          </span>
                        </h4>
                      </td>
                      <td>
                        <span className=" text-dark align-middle fs-12">
                          {item?.customerDetails?.modifiedOn}
                        </span>
                      </td>
                      <td>
                        <div className="d-flex gap-1 align-items-center">
                          <Button variant="white" className="text-dark fw-medium border-0 p-0"
                            onClick={() => handleBillPrintRequest(null, selectedTabSection, item)}>
                            <i className="ri-printer-line fs-22"></i>
                          </Button>

                          <Link
                            className="text-dark fw-medium"
                            onClick={() => handleOrderDetails(item.id, {}, {tableName: orderType?.typeName, tableTypeId: orderType?.id,}, true)}>
                            <img src={viewOrder} className="wt-22" alt="view" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Card>
        ) : (
          <OrderEmptyState />
        )}
      </div>
    </React.Fragment>
  );
}
