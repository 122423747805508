import React, { useCallback, useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Badge, Form, Row, Col, Card } from "react-bootstrap";
import AddNewEmployee from "./add-employee";
import ManageEmployee from "./manage-employee";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { getBranchList, getEmployeeList, getLoading, getUserRoleList } from "../../../application/selectors/indexSelector";
import { DeleteEmployee, LoadEmployee } from "../../../application/actions/employeeAction";
import DataTable from "react-data-table-component";
import TableShimmerLoader from "../../../layouts/ShimmerTable";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import { UserRoleList } from "../../../application/actions/userAction";
import SweetAlertService from "../../_common/SweetAlertService";
import ToastifyService from "../../_common/ToastifyService";
import Select, { components } from "react-select";
import LoadingSpinner from "../../_common/LoadingSpinner";
const CustomDropdownIndicator = ({ loading, value, ...props }) => (
  <components.DropdownIndicator {...props}>
    {loading ? (<LoadingSpinner color="#dc3545" size={20} type={"TailSpin"} />) : null}
  </components.DropdownIndicator>
);
export default function Employees() {
  const dispatch = useDispatch();
  const { loading, value } = useSelector(getLoading);
  const employeeList = useSelector(getEmployeeList);
  const branchList = useSelector(getBranchList);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [totalEmpWithSysAccess, setTotalEmpWithSysAccess] = useState(0);
  const [totalEmpWithTakeOrders, setTotalEmpWithTakeOrders] = useState(0);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const BranchType = branchList?.map((option) => ({
    value: option.id,
    label: option.branchName,
  }));
  const [selectedOptions, setSelectedOptions] = useState(
    JSON.parse(localStorage.getItem("branch"))
  );
  const { user } = useContext(AuthContext);
  useEffect(() => {
    Prism.highlightAll();
    dispatch(UserRoleList);
    dispatch(LoadEmployee(user?.tenanatId));
    setSelectedOptions(JSON.parse(localStorage.getItem("branch")));
  }, []);
  useEffect(() => {
    if (employeeList) {
      setFilteredEmployees(
        employeeList?.filter(
          (employee) =>
            employee.branchId ===
            JSON.parse(localStorage.getItem("branch"))?.value
        )
      );
      setTotalEmployees(
        employeeList?.filter(
          (employee) =>
            employee.branchId ===
            JSON.parse(localStorage.getItem("branch"))?.value
        )?.length
      );
      setTotalEmpWithSysAccess(
        employeeList?.filter(
          (employee) =>
            employee.isSystemAccess &&
            employee.branchId ===
              JSON.parse(localStorage.getItem("branch"))?.value
        )?.length
      );
      setTotalEmpWithTakeOrders(employeeList?.filter((employee) => employee.isOrderTake === true && employee.branchId === JSON.parse(localStorage.getItem("branch"))?.value)?.length);
    }
  }, [employeeList]);

  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const handleAddEmployee = (state) => {
    setShowAddEmployee(state);
  };
  const [employeeData, setEmployeeData] = useState(null);

  const [showManageEmployee, setShowManageEmployee] = useState(false);
  const handleManageEmployee = (state, data) => {
    setEmployeeData(data);
    setShowManageEmployee(state);
  };
  const handleDeleteEmployee = useCallback((id) => {
    SweetAlertService.showAlert(
      "Delete Employee",
      "Are you sure you want to delete this Employee?",
      "warning"
    )
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(DeleteEmployee(id));
          ToastifyService.success(`Deleted Employee Successfully`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        ToastifyService.error(`something went wrong`);
      });
  }, []);

  // TBALE SEARCH FILTER 15-07-2024

  const [searchTerm, setSearchTerm] = useState("");

  // TABLE COLUMNS DATA
  const columns = [
    {
      name: "Employee Name",
      selector: (row) => (
        <div>
          <div>
            <Link
              className="fs-14 fw-semibold mb-1"
              onClick={() => handleManageEmployee(true, row.id)}
            >
              {row.fullName}
            </Link>
            <h5 className="fs-12">{row.email}</h5>
          </div>
        </div>
      ),
    },
    {
      name: "Role",
      selector: (row) => <div>{row.roleName}</div>,
    },
    {
      name: "System Access",
      selector: (row) => (
        <div
          className={`${row.isSystemAccess === true ? "text-success" : ""}${
            row.isSystemAccess === false ? "text-danger" : ""
          }`}
        >
          {row.isSystemAccess === true ? "Enabled" : "Disabled"}
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <div>
          <Badge
            bg={`${
              row.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e400"
                ? "success"
                : "danger"
            }`}
            className="w-100 fs-14 ht-30 lh-4"
            pill
          >
            {row.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e400"
              ? "Active"
              : "Inactive"}
          </Badge>
        </div>
      ),
    },
    {
      name: "Last Login",
      selector: (row) => (
        <div> {format(new Date(row.lastLogin), "dd MMM yyyy hh:mm a")}</div>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex">
          <Button
            variant="primary"
            className="btn-icon me-2"
            onClick={() => handleManageEmployee(true, row.id)}
          >
            <i className="ri-pencil-line"></i>
          </Button>
          <Button
            variant="outline-danger"
            className="btn-icon"
            onClick={() => handleDeleteEmployee(row.id)}
          >
            <i className="ri-delete-bin-line"></i>
          </Button>
        </div>
      ),
    },
  ];

  const handleSelectBranch = (selectedOption) => {
    setSelectedOptions(selectedOption?.value);
    setFilteredEmployees(
      employeeList?.filter(
        (employee) => employee.branchId === selectedOption?.value
      )
    );
    setTotalEmployees(
      employeeList?.filter(
        (employee) => employee.branchId === selectedOption?.value
      )?.length
    );
    setTotalEmpWithSysAccess(
      employeeList?.filter(
        (employee) =>
          employee.isSystemAccess && employee.branchId === selectedOption?.value
      )?.length
    );
    setTotalEmpWithTakeOrders(
      employeeList?.filter(
        (employee) =>
          employee.isOrderTake === true &&
          employee.branchId === selectedOption?.value
      )?.length
    );
  };
  const UserRolesList = useSelector(getUserRoleList);
  return (
    <React.Fragment>
      <AddNewEmployee
        show={showAddEmployee}
        closeFunction={handleAddEmployee}
        UserRolesList={UserRolesList}
        branchId={selectedOptions?.value}
      />
      <ManageEmployee
        show={showManageEmployee}
        data={employeeData}
        closeFunction={handleManageEmployee}
        UserRolesList={UserRolesList}
        branchId={selectedOptions?.value}
      />
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <h4 className="fs-16 main-title mb-1">Employees</h4>

        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button
            variant="primary"
            className="d-flex align-items-center gap-1"
            onClick={() => handleAddEmployee(true)}
          >
            <i className="ri-add-line fs-18 lh-1"></i>
            <span className="d-sm-inline">Add Employee</span>
          </Button>
        </div>
      </div>

      <div className="mb-4 z-index-99 position-relative">
        <Select
          name="branchId"
          placeholder="Choose Branch"
          options={BranchType}
          onChange={handleSelectBranch}
          value={BranchType?.find(
            (option) => option.value === selectedOptions.value
          )}
          className="wt-300 me-3 p-0"
          components={{
            DropdownIndicator: (props) => (
              <CustomDropdownIndicator {...props} loading={(loading && value === "branchList") || branchList === undefined} value={value} />
            ),
          }}
        />
      </div>

      <Row className="g-3 mb-4">
        <Col md={4} xl={4} sm={12}>
          <Card className="card-one">
            <Card.Body>
              <h3 className="card-value mb-1">{totalEmployees}</h3>
              <label className="card-title fw-medium text-dark mb-1">Total Employees</label>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4} xl={4} sm={12}>
          <Card className="card-one">
            <Card.Body>
              <h3 className="card-value mb-1">{totalEmpWithSysAccess}</h3>
              <label className="card-title fw-medium text-dark mb-1">System Access</label>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4} xl={4} sm={12}>
          <Card className="card-one">
            <Card.Body>
              <h3 className="card-value mb-1">{totalEmpWithTakeOrders}</h3>
              <label className="card-title fw-medium text-dark mb-1">Take Customer Orders</label>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Card className="card-one">
        <Card.Header className="align-items-center justify-content-between">
          <div>
            <h4 className="main-title fs-14 mb-0">List of Employees</h4>
          </div>
          <div className="custom-dropdown-wrapper position-relative">
            <div className="custom-drop-down z-index-2 wt-300">
              <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
              <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="custom-common-table">
            {filteredEmployees && filteredEmployees !== undefined && (
              <DataTable
                columns={columns}
                data={filteredEmployees}
                fixedHeader
                search={true}
                highlightOnHover
                pagination
              ></DataTable>
            )}
          </div>

          {loading && filteredEmployees === undefined && (
            <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
          )}
          {loading && value === "LoadEmployee" && filteredEmployees === undefined && (
              <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
            )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
