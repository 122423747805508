
const ComponentPages = [
  {
    "label": "Dashboard",
    "link": "/dashboard/dashboard",
    "icon": "ri-dashboard-line"
  },

  {
    "label": "Financial Dashboard",
    "link": "/dashboard/financial",
    "icon": "ri-computer-line"
  },

  {
    "label": "Point of Sale",
    "link": "/sales/table-state",
    "icon": "ri-calculator-line"
  },

  {
    "label": "Sales Orders",
    "link": "/order/sale",
    "icon": "ri-list-ordered"
  },

  // {
  //   "label": "Attendance",
  //   "link": "/attendance",
  //   "icon": "ri-calendar-event-line"
  // },

  // {
  //   "label": "Salary & Advance",
  //   "link": "/salary-advance",
  //   "icon": "ri-calendar-event-line"
  // },

  {
    "label": "Customers",
    "link": "/customers",
    "icon": "ri-hand-coin-line"
  },


  // {
  //   "label": "Finance Monitoring",
  //   "link": "/dashboard/finance",
  //   "icon": "ri-pie-chart-2-line"
  // },
  // {
  //   "label": "Events Management",
  //   "link": "/dashboard/events",
  //   "icon": "ri-calendar-todo-line"
  // },
  // {
  //   "label": "Sales Monitoring",
  //   "link": "/dashboard/sales",
  //   "icon": "ri-shopping-bag-3-line"
  // },
  // {
  //   "label": "Website Analytics",
  //   "link": "/dashboard/analytics",
  //   "icon": "ri-bar-chart-2-line"
  // },
  // {
  //   "label": "Cryptocurrency",
  //   "link": "/dashboard/crypto",
  //   "icon": "ri-coin-line"
  // },
  // {
  //   "label": "Helpdesk Service",
  //   "link": "/dashboard/helpdesk",
  //   "icon": "ri-service-line"
  // },
  // {
  //   "label": "Storage Management",
  //   "link": "/dashboard/storage",
  //   "icon": "ri-hard-drive-2-line"
  // },
  // {
  //   "label": "Product Management",
  //   "link": "/dashboard/product",
  //   "icon": "ri-suitcase-2-line"
  // }
];

const businessCatalog = [
  {
    "label": "Item Catalog",
    "link": "/item-catalogue",
    "icon": "ri-layout-grid-line"
  },

  {
    "label": "Menu Category",
    "link": "/businessCatalog/menu",
    "icon": "ri-menu-line"
  },
  {
    "label": "Addons",
    "link": "/business-catalog/addons",
    "icon": "ri-command-line"
  },
  {
    "label": "Charges",
    "link": "/business-catalog/charges",
    "icon": "ri-hand-coin-line"
  },

  {
    "label": "Discount",
    "link": "/business-catalog/discount",
    "icon": "ri-percent-fill"
  },

  {
    "label": "Tax Rates",
    "link": "/taxGroup/manage",
    "icon": "ri-book-line"
  }

  // {
  //   "label": "Kitchen KOT Groups",
  //   "link": "/business-catalog/kitchen",
  //   "icon": "ri-hotel-line"
  // }
]


// const goggleReviewMenu = [
//   {
//     "label": "Google Page Analysis",
//     "link": "/google-review",
//     "icon": "ri-star-line"
//   }

// ];

// const coupoenManageMenu = [
//   {
//     "label": "Coupon Management",
//     "link": "/coupon-management",
//     "icon": "ri-coupon-line"
//   }
// ]

// const recieptsPaymentMenu = [
//   {
//     "label": "Reciepts And Payments",
//     "link": "/invoice-management",
//     "icon": "ri-coupon-line"
//   }
// ]

// const customerRoyalMenu = [
//   {
//     "label": "Customer loyalty",
//     "link": "/customer-loyalty",
//     "icon": "ri-hand-coin-line"
//   }
// ]



const settingsMenu = [
  {
    "label": "System Settings",
    "link": "/system-settings",
    "icon": "ri-tools-fill"
  },

];

export { ComponentPages, businessCatalog, 
  // goggleReviewMenu, 
  // coupoenManageMenu,
  //  recieptsPaymentMenu, 
  //  customerRoyalMenu, 
   settingsMenu };