import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Nav, Row, Col, Card, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import "./browse-menu.scss";
import AddNewVariants from "./add-variant";
import verientIcon from "../../../assets/img/icons/varient-icon.svg";
import warningErr from "../../../assets/img/icons/warningErr.png";
import OrderEmptyState from "../../_emptyState/order-empty";
import SearchItems from "./search-items";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import { useDispatch } from "react-redux";
import { addToFavourites, removeFromFavourites } from "../../../application/actions/posAction";
//import Environment from "../../../infrastructure/core/Environment";
import { useCart } from "../MyCard/cartProvider";
import ToastifyService from "../../_common/ToastifyService";

export default function BrowseMenu(props) {
  const dispatch = useDispatch();
  const [subCategory, setSubCategory] = useState(props?.Categorylist[0]?.id);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [showAddVariant, setShowAddVariant] = useState(false);
  const { user } = useContext(AuthContext);
  const todaysDay = new Date().toLocaleDateString("en-US", { weekday: "long" });

  // USE EFFECT
  useEffect(() => {
    if (props?.selectedSubCategory) {
      setSubCategory(props?.selectedSubCategory);
    } else {
      setSubCategory(props?.Categorylist[0]?.id);
    }
    setShowAddVariant(false);
  }, [props]);

   // USE EFFECT
  useEffect(() => {
    if (props?.modifyItemId) {
      const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
      };
      if (!isEmptyObject(props?.modifyItemId)) {
        const itemDetails = props?.ItemCataloguelist.find((itemData) => itemData?.id === props?.modifyItemId?.itemId);
        setSelectedItemData(itemDetails);
        setShowAddVariant(true);
      }
    }
  }, [props?.modifyItemId]);

  // FAVOURITES LOCAL STORAGE GET ITEM
  const favourites = JSON.parse(localStorage.getItem("Favourites"));

   // CATALOUGES LOCAL STORAGE GET ITEM
  const catalogues = JSON.parse(localStorage.getItem("Catalogues"));

  // USE MEMO FUNCTION
  const Item = useMemo(() => {
    if (subCategory && !props?.isFavourites) {
      return props?.ItemCataloguelist.filter(
        (itemCat) => itemCat.categoryId === subCategory && itemCat?.status === "9251e44c-dda2-40d9-8d96-b392d974331b" &&
          (itemCat.visibility === "73649d84-d590-40b9-8971-28b830acacd5" || itemCat.visibility === "73649d84-d590-40b9-8971-28b830acacd6")
      ).map((item) => ({
        ...item,
        isFav: favourites?.some((fav) => fav.catalogueId === item.id) || false,
      }));
    } else if (props?.isFavourites) {
      return favourites
        ?.map((fav) => {
          const catalogue = catalogues.find((cat) => cat.id === fav.catalogueId);
          if (catalogue) {
            return { ...catalogue, isFav: true };
          }
          return null;
        }).filter(Boolean);
    } else {
      return props?.ItemCataloguelist.map((item) => ({ ...item, isFav: false }));
    }
  }, [subCategory, props?.ItemCataloguelist, favourites, catalogues, props?.isFavourites]);

   // HANDLE SUB CATEGORY TAB FUNCTION
  const handleSubCategoryTab = (tab) => {
    setSubCategory(tab.id);
  };

  // HANDLE CATEGORY FUNCTION
  const handleCategoryClick = (tab) => {
    handleSubCategoryTab(tab);
  };

  // HANDLE ADD VARIANT
  const handleAddVariant = (state, itemData) => {
    if (itemData) {
      setSelectedItemData(itemData);
    }
    props?.setModifyItemId(null);
    setShowAddVariant(state);
  };

  // HANDLE ADD TO FAVOURIE FUNCTION
  const handleAddToFavourites = (itemId) => {
    const formObject = {
      catalogueId: itemId,
      userId: user?.id,
    };
    dispatch(addToFavourites(formObject));
  };

  // HANDLE REMOVE FROM FAVOURITE FUNCTION
  const handleRemoveFromFavourites = (itemId) => {
    const formObject = {
      catalogueId: itemId,
      userId: user?.id,
    };
    dispatch(removeFromFavourites(formObject));
  };

  // ADD TO CART FUNCTION
  const { localStorageCartData, setCartData } = useCart();
  const [focusedCardId, setIsCardFocused] = useState(null);
  const AddToCart = (itemDetailOnPOS) => {
    setIsCardFocused(itemDetailOnPOS.id);
    setTimeout(() => {
      setIsCardFocused(false);
    }, 200);
    const cartData = {
      itemId: itemDetailOnPOS.id,
      branchId: itemDetailOnPOS.branchId,
      catalogueName: itemDetailOnPOS.catalogueName,
      tenantId: itemDetailOnPOS.tenantId,
      kotRemark: "",
      foodType: itemDetailOnPOS.foodType,
      variantId: itemDetailOnPOS?.sizeVariance[0]?.id,
      variantName: itemDetailOnPOS?.sizeVariance[0]?.variantName,
      sizeId: itemDetailOnPOS?.sizeVariance[0]?.sizeId,
      variantType: itemDetailOnPOS?.sizeVariance[0]?.variantType,
      multiAddOnsData: [],
      singleAddOnsData: [],
      tableId: props?.selectedTable?.id,
      totalPrice: itemDetailOnPOS?.sizeVariance[0]?.sellingPrice,
      totalQuantity: 1,
      varientPrice: itemDetailOnPOS?.sizeVariance[0]?.sellingPrice,
      totalVarientPrice: itemDetailOnPOS?.sizeVariance[0]?.sellingPrice,
      variantMode: itemDetailOnPOS?.sizeVariance[0]?.variantMode,
      customisePrice: 0,
      totalCustomisePrice: 0,
      addonsPrice: 0,
      totalAddonsPrice: 0,
      varAndItemTotal: itemDetailOnPOS?.sizeVariance[0]?.sellingPrice,
      totalVarAndItemTotal: itemDetailOnPOS?.sizeVariance[0]?.sellingPrice,
    };
    console.log("cartData............................", cartData);

    // ToastifyService.success("Item Added From Cart!");
    if (props?.modifyItemId) {
      const itemIndex = props?.modifyItemId?.itemIndex;
      if (itemIndex !== -1) {
        const newCartData = [...localStorageCartData];
        setCartData(newCartData);
        localStorage.setItem("TableCartData", JSON.stringify(newCartData.reverse()));
      }
    } else {
      if (localStorageCartData && localStorageCartData.length !== 0) {
        const newCartData = [...localStorageCartData, cartData];
        setCartData(newCartData);
       
        localStorage.setItem("TableCartData", JSON.stringify(newCartData.reverse()));
      } else {
        setCartData([cartData]);
        localStorage.setItem("TableCartData", JSON.stringify([cartData]));
      }
    }
  };

  return (
    <React.Fragment>
      {/* SEARCHABLE ITEMS VIEW BEGIN */}
      {props.searchItem.trim() !== "" && (
        <SearchItems ItemCataloguelist={props.ItemCataloguelist} show={handleAddVariant} searchItem={props.searchItem} />
      )}
      {/* SEARCHABLE ITEMS VIEW ENDED */}
      <AddNewVariants
        setModifyItemId={props?.setModifyItemId}
        setShowAddVariant={setShowAddVariant}
        modifyItemId={props?.modifyItemId}
        show={showAddVariant}
        closeFunction={handleAddVariant}
        selectedItemData={selectedItemData}
        selectedTable={props?.selectedTable?.id}
      />
      <div className={`sub-category-tabs ${props?.isFavourites ? "mt-4" : ""}`}>
        {!props?.isFavourites && (<h3 className="fs-14 text-dark mt-2 mb-2 px-3 fw-bold"></h3>)}
        <Tab.Container id="left-tabs-example" activeKey={subCategory}>
          <Row className="m-0">
            {!props?.isFavourites && (
              <Col className="px-0" xs={12} md={3}>
                <div className="sab-menu-tabs">
                  <h3 className="fs-14 text-dark mt-2 mb-2 px-3 fw-bold">Sub Menu:</h3>
                  {props?.Categorylist?.map((tab) => (
                    <Nav
                      variant="pills"
                      className="sale-on-point-tabs main-category-tabs"
                      key={tab.id}
                      activeKey={tab.id}
                      onSelect={() => handleSubCategoryTab(tab)}
                      onClick={() => handleCategoryClick(tab)}
                    >
                      <Nav.Item className="w-100">
                        <Nav.Link className="pb-1 pt-1 pl-4 bg-transparent w-100" eventKey={tab.id}>{tab.name}</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  ))}
                </div>
              </Col>
            )}

            <Col xs={12} md={`${props?.isFavourites ? 12 : 9}`} className="pr-0">
              <div className="sub-menu-content">
                <Tab.Content>
                  {Item?.length !== 0 ? (
                    <div className="sub-menu-tabs">
                      <Tab.Container defaultActiveKey="list_view_tab">
                        <Nav variant="pills" className="gap-2 justify-content-end mb-2">
                          <Nav.Item>
                            <Nav.Link eventKey="list_view_tab"><i className="ri-list-check fs-12"></i></Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="card_view_tab"><i className="ri-layout-grid-line fs-12"></i></Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content>
                          {/* =-=-=-=-=-=-=-= LIST VIEW TAB =-=-=-=-=-=-=-= */}
                          <Tab.Pane eventKey="list_view_tab">
                            <div className="ps-2 pe-2">
                              {Item?.map((item, index) => (
                                <>
                                  {item.status === "9251e44c-dda2-40d9-8d96-b392d974331b" &&
                                    item?.sizeVariance?.length !== 0 &&
                                    (!item?.isDaySpeacial ||
                                      item?.catalogueOnDays?.some((day) => day.dayName === todaysDay)) && (
                                      <div key={index}>
                                        <Card className="mb-0 variant-card mb-2">
                                          <Card.Body className="p-0 ">
                                          
                                            {/* <div className={`d-flex justify-content-between cursor-pointer ${item?.sizeVariance?.length === 1 && item?.addOnGroups.length === 0 ? "cursor-default" : "cursor-pointer"}`} */}
                                            <div className={`d-flex justify-content-between cursor-pointer align-items-center ps-2 pl-2 check-card-focus ${focusedCardId === item.id ? "item-card-focus" : ""}`}
                                              onClick={() => {
                                                if (item?.sizeVariance?.length === 1 && item?.addOnGroups.length === 0) {
                                                  AddToCart(item); // Call AddToCart when conditions are met
                                                } else {
                                                  handleAddVariant(true, item, props?.selectedTable?.id); // Call handleAddVariant otherwise
                                                }
                                              }}
                                            >
                                              <div>
                                                <div className="d-flex text-dark">
                                                  <div className="indicator-varient gap-2 d-flex">
                                                    <div>
                                                    <span className={`indicator d-block ${item.foodType === "a634316f-857d-41fd-a74c-895f61e4de65" ? "non-veg" :
                                                      item.foodType === "d4ce1e35-7ebb-4b29-be69-7c20c216741a" ? "veg" :
                                                        item.foodType === "a634316f-857d-41fd-a74c-895f61e4de67" ? "eggiterian" :
                                                          item.foodType === "a634316f-857d-41fd-a74c-895f61e4de66" ? "not-applicable" : ""
                                                      }`}
                                                    ></span>
                                                    </div>
                                                    {item?.addOnGroups?.filter((addonItems) => addonItems.groupTypeId !== "d9c5a952-911e-4a0f-8935-5e1fae01201e").length > 0 && (
                                                      <img src={verientIcon} alt="varient" className="wt-18 opacity-50" />
                                                    )}

                                                    <h3 className="fs-14 mt-0 mb-0 fw-semibold text-dark two-line-ellipsis">{item.catalogueName}</h3>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="p-1">
                                                {/* DONT'T REMOVE THIS CODE */}
                                                {/* <h6 className="fs-11 fw-bold">
                                                    {item?.sizeVariance?.length === 1 && item?.addOnGroups.length === 0 ? item
                                                      ?.sizeVariance[0]?.variantType === Environment.defaultValue ? `₹ ${item?.sizeVariance[0]?.sellingPrice}`
                                                      : `${item?.sizeVariance[0]?.variantName} - ₹ ${item?.sizeVariance[0]?.sellingPrice}` : ""}
                                                  </h6> */}
                                                <div className="add-to-fav">
                                                  <Dropdown onClick={(e) => e.stopPropagation()}>
                                                    <Dropdown.Toggle variant="default" className="btn-icon custom-dropdown-action">
                                                      <i className="ri-more-2-fill fs-18"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="p-0">
                                                      <Dropdown.Item href="#" onClick={(e) => {
                                                        e.stopPropagation();
                                                        !item?.isFav ? handleAddToFavourites(item.id) : handleRemoveFromFavourites(item.id)
                                                      }}>
                                                        <i className={`fav-icon ri-heart-fill me-1 align-middle ${!item?.isFav ? "text-dark" : "text-danger"}`}></i>
                                                        <span className="align-middle">{!item?.isFav ? "Add to Favourite" : "Remove from Favourite"}</span>
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </div>
                                              </div>
                                            </div>
                                          </Card.Body>
                                        </Card>
                                      </div>
                                    )}
                                </>
                              ))}

                              {Item?.map((item, index) => (
                                <>
                                  {item.status === "9251e44c-dda2-40d9-8d96-b392d974331b" &&
                                    item?.sizeVariance?.length !== 0 &&
                                    item?.isDaySpeacial && !item?.catalogueOnDays?.some(
                                      (day) => day.dayName === todaysDay
                                    ) && (
                                      <div className="" key={index}>
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip id={`tooltip-top`}>
                                              Not available today
                                            </Tooltip>
                                          }
                                          showLabel={"Show"}
                                          hideLabel={"Hide"}
                                        >
                                          <Card className="mb-3 variant-card disabled-variant h-auto cursor-pointer">
                                            <Card.Body className="p-0">
                                              <div className="d-flex justify-content-between"
                                                onClick={() => {
                                                  if (item?.sizeVariance?.length === 1 && item?.addOnGroups.length === 0) {
                                                    // Call AddToCart when conditions are met
                                                    AddToCart(item);
                                                  } else {
                                                    // Call handleAddVariant otherwise
                                                    handleAddVariant(true, item, props?.selectedTable?.id);
                                                  }
                                                }}>
                                                <div>
                                                  <Link className="d-flex flex-wrap align-items-center h-auto text-dark p-2">
                                                    <div className="indicator-varient d-flex align-items-center gap-2 flex-wrap">
                                                    <div>
                                                    <span
                                                        className={`indicator d-block ${item.foodType === "a634316f-857d-41fd-a74c-895f61e4de65" ? "non-veg" :
                                                          item.foodType === "d4ce1e35-7ebb-4b29-be69-7c20c216741a" ? "veg" :
                                                            item.foodType === "a634316f-857d-41fd-a74c-895f61e4de67" ? "eggiterian" :
                                                              item.foodType === "a634316f-857d-41fd-a74c-895f61e4de66" ? "not-applicable" : ""
                                                          }`}
                                                      ></span>
                                                    </div>
                                                      {item?.addOnGroups?.filter(
                                                        (addonItems) => addonItems.groupTypeId !== "d9c5a952-911e-4a0f-8935-5e1fae01201e"
                                                      ).length > 0 && (
                                                          <img src={verientIcon} alt="varient" className="wt-18 opacity-75" />
                                                        )}
                                                      <span className="badge badge-pill bg-danger fs-10">Not available today</span>
                                                      <span className="fs-14 mt-0 mb-0 fw-semibold text-dark mt-0">{item.catalogueName}</span>
                                                    </div>
                                                  </Link>
                                                </div>

                                                <div className="add-to-fav">
                                                  {/* <Button
                                                    variant={`${!item?.isFav ? "outline-secondary" : "outline-danger"}`}
                                                    className="btn-icon border-0 bg-transparent"
                                                    onClick={(e) => {
                                                      e.stopPropagation(); // Prevent the parent onClick
                                                      !item?.isFav ? handleAddToFavourites(item.id) : handleRemoveFromFavourites(item.id);
                                                    }}
                                                  >
                                                    <i className="fav-icon ri-heart-fill fs-16" />
                                                  </Button> */}
                                                  <div>
                                                    <Dropdown onClick={(e) => e.stopPropagation()}>
                                                      <Dropdown.Toggle variant="default" className="btn-icon custom-dropdown-action">
                                                        <i className="ri-more-2-fill fs-18"></i>
                                                      </Dropdown.Toggle>
                                                      <Dropdown.Menu className="p-0">
                                                        <Dropdown.Item href="#" onClick={() => !item?.isFav ? handleAddToFavourites(item.id) : handleRemoveFromFavourites(item.id)}>
                                                          <i className={`fav-icon ri-heart-fill me-1 align-middle ${!item?.isFav ? "text-dark" : "text-danger"}`}></i>
                                                          <span className="align-middle">{!item?.isFav ? "Add to Favourite" : "Remove from Favourite"}</span>
                                                        </Dropdown.Item>
                                                        {/* {item?.sizeVariance?.length === 1 && item?.addOnGroups.length === 0 && (
                                                          <Dropdown.Item className="text-danger" href="#" onClick={() => AddToCart(item)}>
                                                            <i class="ri-shopping-cart-line me-1"></i> Add To Cart
                                                          </Dropdown.Item>
                                                        )} */}
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                  </div>
                                                </div>
                                              </div>
                                            </Card.Body>
                                          </Card>
                                        </OverlayTrigger>
                                      </div>
                                    )}
                                </>
                              ))}

                              {Item?.map((item, index) => (
                                <>
                                  {item.status === "9251e44c-dda2-40d9-8d96-b392d974331b" &&
                                    item?.sizeVariance?.length === 0 && item?.isDaySpeacial &&
                                    !item?.catalogueOnDays?.some((day) => day.dayName === todaysDay) && (
                                      <div key={index}>
                                        <OverlayTrigger
                                          overlay={<Tooltip id={`tooltip-top`}>Not available today & no varients</Tooltip>}
                                          showLabel={"Show"}
                                          hideLabel={"Hide"}
                                        >
                                          <Card className="card-one mb-0 variant-card h-auto disabled-variant">
                                            <Card.Body className="p-0">
                                              <div className="d-flex justify-content-between">
                                                <div>
                                                  <Link className="d-flex align-items-center h-auto text-dark p-2">
                                                    <div className="indicator-varient gap-2 flex-wrap">
                                                      <span
                                                        className={`indicator d-block ${item.foodType === "a634316f-857d-41fd-a74c-895f61e4de65" ? "non-veg" :
                                                          item.foodType === "d4ce1e35-7ebb-4b29-be69-7c20c216741a" ? "veg" :
                                                            item.foodType === "a634316f-857d-41fd-a74c-895f61e4de67" ? "eggiterian" :
                                                              item.foodType === "a634316f-857d-41fd-a74c-895f61e4de66" ? "not-applicable" : ""
                                                          }`}
                                                      ></span>
                                                      {item?.addOnGroups?.filter((addonItems) => addonItems.groupTypeId !== "d9c5a952-911e-4a0f-8935-5e1fae01201e"
                                                      ).length > 0 && (
                                                          <img src={verientIcon} alt="varient" className="wt-18 opacity-75" />
                                                        )}
                                                      <img src={warningErr} alt="varient" className="wt-22" />
                                                      {item?.isDaySpeacial && !item?.catalogueOnDays?.some((day) => day.dayName === todaysDay
                                                      ) && (
                                                          <span className="badge badge-pill bg-danger fs-10">Not available today</span>
                                                        )}

                                                      <h3 className="fs-14 fw-semibold text-dark mt-0">{item.catalogueName}</h3>
                                                    </div>
                                                  </Link>
                                                </div>

                                                <div className="p-1">
                                                  <div className="add-to-fav">
                                                    {/* <Button variant={`${!item?.isFav ? "outline-secondary" : "outline-danger"}`}
                                                      className="btn-icon border-0 bg-transparent"
                                                      onClick={(e) => { e.stopPropagation();
                                                        !item?.isFav ? handleAddToFavourites(item.id) : handleRemoveFromFavourites(item.id);
                                                      }}
                                                    >
                                                      <i className="fav-icon ri-heart-fill fs-16" />
                                                    </Button> */}
                                                    <Dropdown onClick={(e) => e.stopPropagation()}>
                                                      <Dropdown.Toggle variant="default" className="btn-icon custom-dropdown-action">
                                                        <i className="ri-more-2-fill fs-18"></i>
                                                      </Dropdown.Toggle>
                                                      <Dropdown.Menu className="p-0">
                                                        <Dropdown.Item href="#" onClick={() => !item?.isFav ? handleAddToFavourites(item.id) : handleRemoveFromFavourites(item.id)}>
                                                          <i className={`fav-icon ri-heart-fill me-1 align-middle ${!item?.isFav ? "text-dark" : "text-danger"}`}></i>
                                                          <span className="align-middle">{!item?.isFav ? "Add to Favourite" : "Remove from Favourite"}</span>
                                                        </Dropdown.Item>

                                                        {/* {item?.sizeVariance?.length === 1 && item?.addOnGroups.length === 0 && (
                                                          <Dropdown.Item className="text-danger" href="#">
                                                            <i class="ri-shopping-cart-line me-1"></i> Add To Cart
                                                          </Dropdown.Item>
                                                        )} */}
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                  </div>
                                                </div>
                                              </div>
                                            </Card.Body>
                                          </Card>
                                        </OverlayTrigger>
                                      </div>
                                    )}
                                </>
                              ))}

                              {Item?.map((item, index) => (
                                <>
                                  {item.status ===
                                    "9251e44c-dda2-40d9-8d96-b392d974331b" &&
                                    item?.sizeVariance?.length === 0 &&
                                    (!item?.isDaySpeacial ||
                                      item?.catalogueOnDays?.some(
                                        (day) => day.dayName === todaysDay
                                      )) && (
                                      <div key={index}>
                                        <OverlayTrigger
                                          overlay={<Tooltip id={`tooltip-top`}>No varients</Tooltip>}
                                          showLabel={"Show"}
                                          hideLabel={"Hide"}
                                        >
                                          <Card className="card-one variant-card disabled-variant mb-2 h-auto">
                                            <Card.Body className="p-0 overflow-visible">
                                              <div className="d-flex justify-content-between">
                                                <div>
                                                  <Link className="d-block text-dark p-2">
                                                    <div className="indicator-varient gap-1">
                                                      <span
                                                        className={`indicator d-block ${item.foodType === "a634316f-857d-41fd-a74c-895f61e4de65" ? "non-veg" :
                                                          item.foodType === "d4ce1e35-7ebb-4b29-be69-7c20c216741a" ? "veg" :
                                                            item.foodType === "a634316f-857d-41fd-a74c-895f61e4de67" ? "eggiterian" :
                                                              item.foodType === "a634316f-857d-41fd-a74c-895f61e4de66" ? "not-applicable" : ""
                                                          }`}
                                                      ></span>
                                                      {item?.addOnGroups?.filter((addonItems) => addonItems.groupTypeId !== "d9c5a952-911e-4a0f-8935-5e1fae01201e"
                                                      ).length > 0 && (
                                                          <img src={verientIcon} alt="varient" className="wt-18 opacity-75" />
                                                        )}
                                                      <img src={warningErr} alt="varient" className="wt-22" />
                                                      {item?.isDaySpeacial &&
                                                        !item?.catalogueOnDays?.some((day) => day.dayName === todaysDay) && (
                                                          <span className="badge badge-pill bg-danger fs-10">Not available today</span>
                                                        )}

                                                      <h3 className="fs-14 fw-semibold text-dark mt-0">{item.catalogueName}</h3>
                                                    </div>
                                                  </Link>
                                                </div>
                                                <div className="p-1">
                                                  <div className="add-to-fav">
                                                    {/* <Button
                                                      variant={`${!item?.isFav ? "outline-secondary" : "outline-danger"}`}
                                                      className="btn-icon border-0 bg-transparent"
                                                      onClick={(e) => {
                                                        e.stopPropagation(); // Prevent the parent onClick
                                                        !item?.isFav ? handleAddToFavourites(item.id) : handleRemoveFromFavourites(item.id);
                                                      }}
                                                    >
                                                      <i className="fav-icon ri-heart-fill fs-16" />
                                                    </Button> */}

                                                    <Dropdown onClick={(e) => e.stopPropagation()}>
                                                      <Dropdown.Toggle variant="default" className="btn-icon custom-dropdown-action">
                                                        <i className="ri-more-2-fill fs-18"></i>
                                                      </Dropdown.Toggle>
                                                      <Dropdown.Menu className="p-0">
                                                        <Dropdown.Item href="#" onClick={() => !item?.isFav ? handleAddToFavourites(item.id) : handleRemoveFromFavourites(item.id)}>
                                                          <i className={`fav-icon ri-heart-fill ${!item?.isFav ? "text-dark" : "text-danger"}`} />
                                                          {!item?.isFav ? "Add to Favourite" : "Remove from Favourite"}
                                                        </Dropdown.Item>

                                                        {/* {item?.sizeVariance?.length === 1 && item?.addOnGroups.length === 0 && (
                                                          <Dropdown.Item className="text-danger" href="#" onClick={() => AddToCart(item)}>
                                                            <i class="ri-shopping-cart-line me-1"></i> Add To Cart
                                                          </Dropdown.Item>
                                                        )} */}
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                  </div>
                                                </div>
                                              </div>
                                            </Card.Body>
                                          </Card>
                                        </OverlayTrigger>
                                      </div>
                                    )}
                                </>
                              ))}
                            </div>
                          </Tab.Pane>

                          {/* =-=-=-=-=-=-=-= CARD VIEW TAB =-=-=-=-=-=-=-= */}
                          <Tab.Pane eventKey="card_view_tab">
                            <Row className="m-0 g-2 pe-2 ps-2">
                              {Item?.map((item, index) => (
                                <>
                                  {item.status === "9251e44c-dda2-40d9-8d96-b392d974331b" && item?.sizeVariance?.length !== 0 &&
                                    (!item?.isDaySpeacial || item?.catalogueOnDays?.some((day) => day.dayName === todaysDay)) && (
                                      <Col sm={6} lg={3} md={4} key={index}>
                                        <Card className="card-one variant-card">
                                          <Card.Body className="p-0 overflow-visible">
                                            <div className="d-flex justify-content-between">
                                              <div>
                                                <Link className="d-block h-100 text-dark p-2"
                                                  //onClick={() => handleAddVariant(true, item, props?.selectedTable?.id)}
                                                  onClick={() => {
                                                    if (item?.sizeVariance?.length === 1 && item?.addOnGroups.length === 0) {
                                                      AddToCart(item); // Call AddToCart when conditions are met
                                                    } else {
                                                      handleAddVariant(true, item, props?.selectedTable?.id); // Call handleAddVariant otherwise
                                                    }
                                                  }}
                                                >
                                                  <div className="indicator-varient gap-1">
                                                    <span className={`indicator d-block me-1 ${item.foodType === "a634316f-857d-41fd-a74c-895f61e4de65" ? "non-veg" :
                                                      item.foodType === "d4ce1e35-7ebb-4b29-be69-7c20c216741a" ? "veg" :
                                                        item.foodType === "a634316f-857d-41fd-a74c-895f61e4de67" ? "eggiterian" :
                                                          item.foodType === "a634316f-857d-41fd-a74c-895f61e4de66" ? "not-applicable" : ""
                                                      }`}
                                                    ></span>
                                                    {item?.addOnGroups?.filter(
                                                      (addonItems) => addonItems.groupTypeId !== "d9c5a952-911e-4a0f-8935-5e1fae01201e"
                                                    ).length > 0 && (
                                                        <img src={verientIcon} alt="varient" className="wt-18 opacity-75" />
                                                      )}
                                                    <img src={warningErr} alt="varient" className="wt-22" />
                                                    {item?.isDaySpeacial && !item?.catalogueOnDays?.some((day) => day.dayName === todaysDay) && (
                                                      <span className="badge badge-pill bg-danger fs-10">Not available today</span>
                                                    )}
                                                  </div>

                                                  <h3 className="fs-14 fw-semibold text-dark">
                                                    {item.catalogueName}
                                                  </h3>
                                                </Link>
                                              </div>
                                              <div className="p-1">
                                                {/* <div>
                                                  {item?.sizeVariance ?.length === 1 && item?.addOnGroups.length === 0 && (
                                                      <Button variant="danger" onClick={() => AddToCart(item)}>Add To Cart</Button>
                                                    )}
                                                </div> */}

                                                <div>
                                                  <Dropdown onClick={(e) => e.stopPropagation()}>
                                                    <Dropdown.Toggle variant="default" className="btn-icon custom-dropdown-action">
                                                      <i className="ri-more-2-fill fs-18"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="p-0">
                                                      <Dropdown.Item href="#" onClick={() => !item?.isFav ? handleAddToFavourites(item.id) : handleRemoveFromFavourites(item.id)}>
                                                        <i className={`fav-icon ri-heart-fill align-middle me-1 ${!item?.isFav ? "text-dark" : "text-danger"}`}></i>
                                                        <span className="align-middle">{!item?.isFav ? "Add to Favourite" : "Remove from Favourite"}</span>
                                                      </Dropdown.Item>

                                                      {/* {item?.sizeVariance?.length === 1 && item?.addOnGroups.length === 0 && (
                                                          <Dropdown.Item className="text-danger" href="#" onClick={() => AddToCart(item)}>
                                                            <i class="ri-shopping-cart-line me-1"></i> Add To Cart
                                                          </Dropdown.Item>
                                                        )} */}
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </div>
                                              </div>
                                            </div>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                    )}
                                </>
                              ))}

                              {Item?.map((item, index) => (
                                <>
                                  {item.status === "9251e44c-dda2-40d9-8d96-b392d974331b" &&
                                    item?.sizeVariance?.length !== 0 && item?.isDaySpeacial &&
                                    !item?.catalogueOnDays?.some((day) => day.dayName === todaysDay) && (
                                      <Col sm={12} lg={12} md={12} className="mb-3" key={index}>
                                        <OverlayTrigger
                                          overlay={<Tooltip id={`tooltip-top`}>Not available today</Tooltip>}
                                          showLabel={"Show"}
                                          hideLabel={"Hide"}
                                        >
                                          <Card className="mb-3 variant-card disabled-variant">
                                            <Card.Body className="p-0">
                                              <div className="d-flex justify-content-between">
                                                <div>
                                                  <Link className="d-block text-dark p-2">
                                                    <div className="indicator-varient gap-2 flex-wrap">
                                                      <span
                                                        className={`indicator d-block ${item.foodType === "a634316f-857d-41fd-a74c-895f61e4de65" ? "non-veg" :
                                                          item.foodType === "d4ce1e35-7ebb-4b29-be69-7c20c216741a" ? "veg" :
                                                            item.foodType === "a634316f-857d-41fd-a74c-895f61e4de67" ? "eggiterian" :
                                                              item.foodType === "a634316f-857d-41fd-a74c-895f61e4de66" ? "not-applicable" : ""
                                                          }`}
                                                      ></span>
                                                      {item?.addOnGroups?.filter((addonItems) => addonItems.groupTypeId !== "d9c5a952-911e-4a0f-8935-5e1fae01201e"
                                                      ).length > 0 && (
                                                          <img src={verientIcon} alt="varient" className="wt-18 opacity-75" />
                                                        )}
                                                      <span className="badge badge-pill bg-danger fs-10">Not available today</span>
                                                      <h3 className="fs-14 mt-0 mb-0 fw-semibold text-dark">{item.catalogueName} </h3>
                                                    </div>
                                                  </Link>
                                                </div>
                                                <div className="p-1">
                                                  {/* <div>
                                                    {item?.sizeVariance?.length === 1 && item?.addOnGroups.length === 0 && (
                                                        <Button variant="danger" onClick={() => AddToCart(item)}>Add To Cart</Button>
                                                      )}
                                                  </div> */}

                                                  <div>
                                                    <Dropdown onClick={(e) => e.stopPropagation()}>
                                                      <Dropdown.Toggle variant="default" className="btn-icon custom-dropdown-action">
                                                        <i className="ri-more-2-fill fs-18"></i>
                                                      </Dropdown.Toggle>
                                                      <Dropdown.Menu className="p-0">
                                                        <Dropdown.Item href="#" onClick={() => !item?.isFav ? handleAddToFavourites(item.id) : handleRemoveFromFavourites(item.id)}>
                                                          <i className={`fav-icon ri-heart-fill me-1 align-middle ${!item?.isFav ? "text-dark" : "text-danger"}`}></i>
                                                          <span className="align-middle">{!item?.isFav ? "Add to Favourite" : "Remove from Favourite"}</span>
                                                        </Dropdown.Item>

                                                        {/* {item?.sizeVariance?.length === 1 && item?.addOnGroups.length === 0 && (
                                                          <Dropdown.Item className="text-danger" href="#" onClick={() => AddToCart(item)}>
                                                            <i class="ri-shopping-cart-line me-1"></i> Add To Cart
                                                          </Dropdown.Item>
                                                        )} */}
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                  </div>
                                                </div>
                                              </div>
                                            </Card.Body>
                                          </Card>
                                        </OverlayTrigger>
                                      </Col>
                                    )}
                                </>
                              ))}

                              {Item?.map((item, index) => (
                                <>
                                  {item.status ===
                                    "9251e44c-dda2-40d9-8d96-b392d974331b" &&
                                    item?.sizeVariance?.length === 0 &&
                                    item?.isDaySpeacial &&
                                    !item?.catalogueOnDays?.some((day) => day.dayName === todaysDay) && (
                                      <Col sm={6} lg={3} md={4} key={index}>
                                        <OverlayTrigger
                                          overlay={<Tooltip id={`tooltip-top`}>Not available today & no varients</Tooltip>}
                                          showLabel={"Show"}
                                          hideLabel={"Hide"}>
                                          <Card className="card-one  variant-card disabled-variant">
                                            <Card.Body className="p-0">
                                              <div className="d-flex justify-content-between">
                                                <div>
                                                  <Link className="d-block h-100 text-dark p-2">
                                                    <div className="indicator-varient gap-2">
                                                      <span
                                                        className={`indicator d-block ${item.foodType === "a634316f-857d-41fd-a74c-895f61e4de65" ? "non-veg" :
                                                          item.foodType === "d4ce1e35-7ebb-4b29-be69-7c20c216741a" ? "veg" :
                                                            item.foodType === "a634316f-857d-41fd-a74c-895f61e4de67" ? "eggiterian" :
                                                              item.foodType === "a634316f-857d-41fd-a74c-895f61e4de66" ? "not-applicable" : ""
                                                          }`}
                                                      ></span>
                                                      {item?.addOnGroups?.filter((addonItems) => addonItems.groupTypeId !== "d9c5a952-911e-4a0f-8935-5e1fae01201e"
                                                      ).length > 0 && (
                                                          <img src={verientIcon} alt="varient" className="wt-18 opacity-75" />
                                                        )}
                                                      <img src={warningErr} alt="varient" className="wt-22" />
                                                      {item?.isDaySpeacial && !item?.catalogueOnDays?.some((day) => day.dayName === todaysDay) && (
                                                        <span className="badge badge-pill bg-danger fs-10">Not available today</span>
                                                      )}
                                                    </div>

                                                    <h3 className="fs-14 fw-semibold text-dark">{item.catalogueName}</h3>
                                                  </Link>
                                                </div>
                                                <div className="p-1">
                                                  {/* <div>
                                                    {item?.sizeVariance ?.length === 1 && item?.addOnGroups.length === 0 && (
                                                        <Button variant="danger" onClick={() => AddToCart(item)}>Add To Cart</Button>
                                                      )}
                                                  </div> */}

                                                  <div>
                                                  <Dropdown onClick={(e) => e.stopPropagation()}>
                                                      <Dropdown.Toggle variant="default" className="btn-icon custom-dropdown-action">
                                                        <i className="ri-more-2-fill fs-18"></i>
                                                      </Dropdown.Toggle>
                                                      <Dropdown.Menu className="p-0">
                                                        <Dropdown.Item href="#" onClick={() => !item?.isFav ? handleAddToFavourites(item.id) : handleRemoveFromFavourites(item.id)}>
                                                          <i className={`fav-icon ri-heart-fill me-1 align-middle ${!item?.isFav ? "text-dark" : "text-danger"}`} />
                                                          <span className="align-middle">{!item?.isFav ? "Add to Favourite" : "Remove from Favourite"}</span>
                                                        </Dropdown.Item>

                                                        {/* {item?.sizeVariance?.length === 1 && item?.addOnGroups.length === 0 && (
                                                          <Dropdown.Item className="text-danger" href="#" onClick={() => AddToCart(item)}>
                                                            <i class="ri-shopping-cart-line me-1"></i> Add To Cart
                                                          </Dropdown.Item>
                                                        )} */}
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                  </div>
                                                </div>
                                              </div>
                                            </Card.Body>
                                          </Card>
                                        </OverlayTrigger>
                                      </Col>
                                    )}
                                </>
                              ))}

                              {Item?.map((item, index) => (
                                <>
                                  {item.status === "9251e44c-dda2-40d9-8d96-b392d974331b" && item?.sizeVariance?.length === 0 && (!item?.isDaySpeacial ||
                                    item?.catalogueOnDays?.some((day) => day.dayName === todaysDay)) && (
                                      <Col sm={6} lg={3} md={4} key={index}>
                                        <OverlayTrigger overlay={<Tooltip id={`tooltip-top`}>No varients</Tooltip>}
                                          showLabel={"Show"}
                                          hideLabel={"Hide"}
                                        >
                                          <Card className="card-one variant-card disabled-variant">
                                            <Card.Body className="p-0 overflow-visible">
                                              <div className="d-flex justify-content-between">
                                                <div>
                                                  <Link className="d-block h-100 text-dark p-2">
                                                    <div className="indicator-varient gap-1">
                                                      <span className={`indicator d-block me-1 ${item.foodType === "a634316f-857d-41fd-a74c-895f61e4de65" ? "non-veg" :
                                                        item.foodType === "d4ce1e35-7ebb-4b29-be69-7c20c216741a" ? "veg" :
                                                          item.foodType === "a634316f-857d-41fd-a74c-895f61e4de67" ? "eggiterian" :
                                                            item.foodType === "a634316f-857d-41fd-a74c-895f61e4de66" ? "not-applicable" : ""}`}>
                                                      </span>
                                                      {item?.addOnGroups?.filter((addonItems) => addonItems.groupTypeId !== "d9c5a952-911e-4a0f-8935-5e1fae01201e"
                                                      ).length > 0 && (
                                                          <img src={verientIcon} alt="varient" className="wt-18 opacity-75" />
                                                        )}
                                                      <img src={warningErr} alt="varient" className="wt-22" />
                                                      {item?.isDaySpeacial && !item?.catalogueOnDays?.some((day) => day.dayName === todaysDay) && (
                                                        <span className="badge badge-pill bg-danger fs-10">Not available today</span>
                                                      )}
                                                    </div>

                                                    <h3 className="fs-14 fw-semibold text-dark">{item.catalogueName}</h3>
                                                  </Link>
                                                </div>
                                                <div className="p-1">
                                                  {/* <div>
                                                  {item?.sizeVariance ?.length === 1 && item?.addOnGroups.length === 0 && (
                                                      <Button variant="danger" onClick={() => AddToCart(item)}>Add To Cart</Button>
                                                    )}
                                                </div> */}

                                                  <div>
                                                  <Dropdown onClick={(e) => e.stopPropagation()}>
                                                      <Dropdown.Toggle variant="default" className="btn-icon custom-dropdown-action">
                                                        <i className="ri-more-2-fill fs-18"></i>
                                                      </Dropdown.Toggle>
                                                      <Dropdown.Menu className="p-0">
                                                        <Dropdown.Item href="#" onClick={() => !item?.isFav ? handleAddToFavourites(item.id) : handleRemoveFromFavourites(item.id)}>
                                                          <i className={`fav-icon ri-heart-fill me-1 align-middle ${!item?.isFav ? "text-dark" : "text-danger"}`}></i>
                                                          <span className="align-middle">{!item?.isFav ? " Add to Favourite" : "Remove from Favourite"}</span>
                                                        </Dropdown.Item>

                                                        {/* {item?.sizeVariance?.length === 1 && item?.addOnGroups.length === 0 && (
                                                          <Dropdown.Item className="text-danger" href="#" onClick={() => AddToCart(item)}>
                                                            <i class="ri-shopping-cart-line me-1"></i> Add To Cart
                                                          </Dropdown.Item>
                                                        )} */}
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                  </div>
                                                </div>
                                              </div>
                                            </Card.Body>
                                          </Card>
                                        </OverlayTrigger>
                                      </Col>
                                    )}
                                </>
                              ))}
                            </Row>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  ) : (
                    <>
                      <OrderEmptyState />
                    </>
                  )}
                </Tab.Content>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </React.Fragment>
  );
}
