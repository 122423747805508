import React, { useContext, useEffect, useState, useRef } from "react";
import Prism from "prismjs";
import { Card, Row, Col, Dropdown, ButtonGroup, Button, Modal, Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CreateOrder, UpdateOrder, cancelOrder, changeOrderStatus, closeOrder, deleteAllOrderedItem, getChargeByBranchId, getDiscountByBranchId, increKotprintCount } from "../../../application/actions/posAction";
import ApplyDiscount from "./apply-discount";
import ApplyCharges from "./apply-charges";
import { useCart } from "./cartProvider";
import { getCreateOrderSuccess, getCustomerList, getLoading, getOrderRelatedChangesDone } from "../../../application/selectors/indexSelector";
import ToastifyService from "../../_common/ToastifyService";
import SettlePayment from "../SettlePayment/settle-payment";
import ComponentToPrint from "../../Settings/Print/componentToPrint";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import Environment from "../../../infrastructure/core/Environment";
import Loader from "../../../layouts/Loader";
import * as Loading from "./../../../application/actions/loaderAction";
import BillPrint from "../../Settings/Print/bill-print";
import { PaymentModeListByBranchId } from "../../../application/actions/paymentModeAction";

export default function MyCardOrder(props) {
  const dispatch = useDispatch();
  const tableData = props?.selectedTab;
  const sectionName = props?.selectedTabSection;
  const orderType = props?.orderType;
  const { selectedBranchData } = useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);
  const { localStorageCartData, setCartData, clearLocalStorage, deletedItems, setDeletedItems, clearLocalDeletedItems } = useCart();
  const [showEndOrder, setShowEndOrder] = useState(false);
  const [showApplyDiscount, setShowApplyDiscount] = useState(false);
  const [showApplyCharges, setShowApplyCharges] = useState(false);
  const [showSettlePayment, setShowSettlePayment] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isToggled, setToggled] = useState(false);
  const [isKOTPrintRequested, setisKOTPrintRequested] = useState(false);
  const [isBillPrintRequested, setisBillPrintRequested] = useState(false);
  const [printData, setPrintData] = useState(null);
  const [billPrintData, setBillPrintData] = useState(null);
  const [getCustomerDetails, setCustomerDetails] = useState(null);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState(Environment.defaultValue);
  const branchData = JSON.parse(localStorage.getItem("branch"));
  const commonfields = JSON.parse(localStorage.getItem("CommonFields"));

  const includedIds = [
    "a5ca48b8-bfd1-42d6-8db1-7021c131e603",
    "a5ca48b8-bfd1-42d6-8db1-7021c131e602",
    "a5ca48b8-bfd1-42d6-8db1-7021c131e607",
    "a5ca48b8-bfd1-42d6-8db1-7021c131e609",
    "a5ca48b8-bfd1-42d6-8db1-7021c131e612",
    "a5ca48b8-bfd1-42d6-8db1-7021c131e611",
  ];

  const orderStatus = JSON.parse(localStorage.getItem("CommonFields")) ? JSON.parse(localStorage.getItem("CommonFields")).filter((fields) => fields.fieldTypeCode === "OSTS").filter((fields) => includedIds.includes(fields.id)) : [];
  const createOrderSuccess = useSelector(getCreateOrderSuccess);
  const orderRelatedChangesDone = useSelector(getOrderRelatedChangesDone);
  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;
  const customerListData = useSelector(getCustomerList);

  // USE EFFECT
  const [newItemAdded, setNewItemAdded] = useState(false);
  const previousCartLength = useRef(localStorageCartData.length);

  useEffect(() => {
    if (localStorageCartData?.length > previousCartLength.current &&  newItemAdded === false) {
      console.log("New item added animation triggered");
      setNewItemAdded(true);
  
      const timer = setTimeout(() => {
        setNewItemAdded(false);
      }, 2000);
  
      return () => clearTimeout(timer);
    }
  
    // Update the previous cart length after handling the effect
    previousCartLength.current = localStorageCartData?.length;
  }, [localStorageCartData, newItemAdded]);

  // USE EFFECT
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  // USE EFFECT
  useEffect(() => {
    if (
      (orderRelatedChangesDone &&
        (value === "deleteAllOrderedItem" ||
          value === "createOrder" ||
          value === "updateOrder")) ||
      (loading === false &&
        (value === "deleteAllOrderedItem" ||
          value === "createOrder" ||
          value === "increKotprintCount" ||
          value === "changeOrderStatus" ||
          value === "updateOrder"))
    ) {
      setIsDataLoaded(false);
    }
    if (loading === false && value === "PaymentModeListByBranchId") {
      setIsDataLoaded(false);
      dispatch(Loading.setLoading({ loading: false, value: "" }));
      setShowSettlePayment(true);
    } else if (
      (orderRelatedChangesDone &&
        (value === "cancelOrder" || value === "closeOrder")) ||
      (loading === false && (value === "cancelOrder" || value === "closeOrder"))
    ) {
      setIsDataLoaded(false);
      dispatch(Loading.setLoading({ loading: false, value: "" }));
      props?.closeFunction();
    } else if (
      orderRelatedChangesDone &&
      loading === false &&
      value === "deleteAllOrderedItem"
    ) {
      setIsDataLoaded(false);
      dispatch(Loading.setLoading({ loading: false, value: "" }));
      clearLocalDeletedItems();
    }
  }, [loading, props, value]);

  // HANDLE CLOSE 
  const handleClose1 = () => setShowEndOrder(false);

  // HANDLE PLUS FUNCTION
  const handlePlusClick = (index) => {
    const selectedItem = localStorageCartData[index];
    const updatedQuantity = selectedItem.totalQuantity + 1;
    const updatedTotalPrice = selectedItem.varAndItemTotal * updatedQuantity;
    const updatedcustomisePrice = selectedItem.customisePrice * updatedQuantity;
    const updatedvarientPrice = selectedItem.varientPrice * updatedQuantity;
    const updatedCartData = [...localStorageCartData];
    const oldVarAndItemTotal =
      selectedItem.varAndItemTotal * selectedItem.totalQuantity;
    updatedCartData[index] = {
      ...selectedItem,
      totalQuantity: updatedQuantity,
      totalVarAndItemTotal: updatedTotalPrice,
      totalCustomisePrice: updatedcustomisePrice,
      totalVarientPrice: updatedvarientPrice,
    };
    updatedCartData[index].totalPrice += updatedTotalPrice - oldVarAndItemTotal;
    setCartData(updatedCartData);
  };

  // HANDLE MENUS FUNCTION
  const handleMinusClick = (index) => {
    const selectedItem = localStorageCartData[index];
    const updatedQuantity = Math.max(selectedItem.totalQuantity - 1, 1);
    const updatedTotalPrice = selectedItem.varAndItemTotal * updatedQuantity;
    const updatedcustomisePrice = selectedItem.customisePrice * updatedQuantity;
    const updatedvarientPrice = selectedItem.varientPrice * updatedQuantity;
    const updatedCartData = [...localStorageCartData];
    const oldVarAndItemTotal =
      selectedItem.varAndItemTotal * selectedItem.totalQuantity;
    updatedCartData[index] = {
      ...selectedItem,
      totalQuantity: updatedQuantity,
      totalVarAndItemTotal: updatedTotalPrice,
      totalCustomisePrice: updatedcustomisePrice,
      totalVarientPrice: updatedvarientPrice,
    };
    updatedCartData[index].totalPrice += updatedTotalPrice - oldVarAndItemTotal;
    setCartData(updatedCartData);
  };

  // DELETE MULTI ADDONS FUNCTION
  const deleteMultiAddons = (addonId, itemIndex) => {
    const updatedCartData = [...localStorageCartData];
    const selectedItem = updatedCartData[itemIndex];

    if (selectedItem && selectedItem.multiAddOnsData) {
      // Find the index of the addon with the provided addonId
      const addonIndex = selectedItem.multiAddOnsData.findIndex(
        (addon) => addon.id === addonId
      );
      if (addonIndex !== -1) {
        // Remove the addon using its index
        selectedItem.multiAddOnsData.splice(addonIndex, 1);
        selectedItem.totalAddonsPrice = calculateTotalAddonsPrice(selectedItem);
        selectedItem.totalPrice = calculateTotalPrice(selectedItem);
        setCartData(updatedCartData);
      }
    }
  };

  // DELETE SINGLE ADDONS FUNCTION
  const deleteSingleAddons = (addonId, itemIndex) => {
    const updatedCartData = [...localStorageCartData];
    const selectedItem = updatedCartData[itemIndex];

    if (selectedItem && selectedItem.singleAddOnsData) {
      // Find the index of the addon with the provided addonId
      const addonIndex = selectedItem.singleAddOnsData.findIndex(
        (addon) => addon.id === addonId
      );
      if (addonIndex !== -1) {
        // Remove the addon using its index
        selectedItem.singleAddOnsData.splice(addonIndex, 1);
        selectedItem.totalAddonsPrice = calculateTotalAddonsPrice(selectedItem);
        selectedItem.totalPrice = calculateTotalPrice(selectedItem);
        setCartData(updatedCartData);
      }
    }
  };

  // DELETE ITEM FUNCTION
  const deleteItem = (e, itemIndex) => {
    e.preventDefault();
    setNewItemAdded(false);
    const updatedCartData = [...localStorageCartData];
    if (itemIndex >= 0 && itemIndex < updatedCartData.length) {
      updatedCartData.splice(itemIndex, 1);
      setCartData(updatedCartData);
      //ToastifyService.warning("Item Removed From Cart!");
    };

  };

  // HANDLEMULTI ADDONS PLUS FUNCTION
  const handleMultiAddonPlus = (itemIndex, addonId) => {
    const updatedCartData = [...localStorageCartData];
    const selectedItem = updatedCartData[itemIndex];

    if (selectedItem && selectedItem.multiAddOnsData) {
      // Find the index of the addon with the provided addonId
      const addonIndex = selectedItem.multiAddOnsData.findIndex(
        (addon) => addon.id === addonId
      );
      if (addonIndex !== -1) {
        const selectedMultiAddOn = selectedItem.multiAddOnsData[addonIndex];
        // Check if incrementing the quantity exceeds maxItemAllowed
        const maxAllowedQty = selectedMultiAddOn.maxItemAllowed ?? 9999; // Default to 9999 if maxItemAllowed is not defined
        if (selectedMultiAddOn.quantity < maxAllowedQty) {
          // Increment the quantity of the selected addon
          selectedMultiAddOn.quantity += 1;
          selectedMultiAddOn.totalPrice =
            selectedMultiAddOn.quantity * selectedMultiAddOn.price;
          selectedItem.totalAddonsPrice =
            calculateTotalAddonsPrice(selectedItem);
          selectedItem.totalPrice = calculateTotalPrice(selectedItem);
          setCartData(updatedCartData);
        } else {
          return;
        }
      }
    }
  };

  // CALCULATE TOTAL ADDONS PRINCE FUNCTION 
  const calculateTotalAddonsPrice = (item) => {
    if (item && item.multiAddOnsData) {
      return item.multiAddOnsData.reduce((total, multiAddon) => {
        const addonTotalPrice =
          multiAddon.totalPrice || multiAddon.quantity * multiAddon.price || 0;
        return total + addonTotalPrice;
      }, 0);
    }
    return 0;
  };

  // CALCULATE TOTAL PRICE FUNCTION
  const calculateTotalPrice = (item) => {
    if (item) {
      const varientPrice = item.varientPrice || 0;
      const customisePrice = item.customisePrice || 0;
      const addonsPrice = calculateTotalAddonsPrice(item);
      return varientPrice + customisePrice + addonsPrice;
    }
    return 0;
  };

  // HANDLE MULTI ADDONS MENUS FUNCION
  const handleMultiAddonMinus = (itemIndex, addonId) => {
    const updatedCartData = [...localStorageCartData];
    const selectedItem = updatedCartData[itemIndex];

    if (selectedItem && selectedItem.multiAddOnsData) {
      // Find the index of the addon with the provided addonId
      const addonIndex = selectedItem.multiAddOnsData.findIndex(
        (addon) => addon.id === addonId
      );
      if (addonIndex !== -1) {
        const selectedMultiAddOn = selectedItem.multiAddOnsData[addonIndex];
        // Ensure quantity doesn't go below 1
        if (selectedMultiAddOn.quantity !== 1) {
          selectedMultiAddOn.quantity -= 1;
          selectedMultiAddOn.totalPrice =
            selectedMultiAddOn.quantity * selectedMultiAddOn.price;
          selectedItem.totalAddonsPrice =
            calculateTotalAddonsPrice(selectedItem);
          selectedItem.totalPrice = calculateTotalPrice(selectedItem);
          setCartData(updatedCartData);
        }
      }
    }
  };

  // CLEAR TABLE CARD DATA FUNCTION
  const clearTableCartData = () => {
    clearLocalStorage();
  };

  // HANDLE CREATE ORDER FUNCTION
  const handleCreateOrder = () => {
    setIsDataLoaded(true);
    const orderDetails = [];
    let totalPriceSum = 0;
    let totalProductQuantityCount = 0;
    let totalProductCount = localStorageCartData?.length
      ? localStorageCartData?.length
      : 0;
    for (const item of localStorageCartData) {
      totalPriceSum += item.totalPrice;
      totalProductQuantityCount += item.totalQuantity;
    }
    for (let [index, item] of localStorageCartData.entries()) {
      // Main Item
      const mainItem = {
        catalogId: item.itemId, // itemId
        sizeVarianceId: item.variantId, // varient
        colorVarianceId: Environment.defaultValue, //00000
        addonId: Environment.defaultValue,
        // taxGroupId: Environment.defaultValue, //tax id assigned to that item
        // chargeId: [], // charge asigned to that item
        // discountId: Environment.defaultValue, // discount asigned to that item
        orderSequence: index + 1, // add 1 to start from 1
        isAddOn: false,
        isCustom: false,
        isMandatory: true,
        quantity: item.totalQuantity, // total mainn item quantity
        price: item.varientPrice,
        amount: item.totalVarientPrice,
        status: "a5ca48b8-bfd1-42d6-8db1-7021c131e400",
        remark: item.kotRemark,
        deliveryDate: new Date(),
      };

      orderDetails.push(mainItem);

      // Single Add-ons
      for (const singleAddOn of item.singleAddOnsData || []) {
        const singleAddOnItem = {
          catalogId: item.itemId,
          sizeVarianceId: item.variantId, // varient
          colorVarianceId: Environment.defaultValue, //00000
          addonId: singleAddOn.id,
          // taxGroupId: Environment.defaultValue, //tax id assigned to that item
          // chargeId: [], // charge asigned to that item
          // discountId: Environment.defaultValue, // discount asigned to that item
          orderSequence: index + 1, // add 1 to start from 1
          isAddOn: false,
          isCustom: true,
          isMandatory: singleAddOn.isMandatory,
          quantity: 1,
          price: singleAddOn.price,
          amount: singleAddOn.price, // Assuming it's the same as price
          status: "a5ca48b8-bfd1-42d6-8db1-7021c131e400",
          remark: "",
          deliveryDate: new Date(),
        };

        orderDetails.push(singleAddOnItem);
      }

      // Multi Add-ons
      for (const multiAddOn of item.multiAddOnsData || []) {
        const multiAddOnItem = {
          catalogId: item.itemId,
          sizeVarianceId: item.variantId, // varient
          colorVarianceId: Environment.defaultValue, //00000
          addonId: multiAddOn.id,
          // taxGroupId: Environment.defaultValue, //tax id assigned to that item
          // chargeId: [], // charge asigned to that item
          // discountId: Environment.defaultValue, // discount asigned to that item
          orderSequence: index + 1, // add 1 to start from 1
          isAddOn: true,
          isCustom: false,
          isMandatory: multiAddOn.isMandatory,
          quantity: multiAddOn.quantity,
          price: multiAddOn.price,
          amount: multiAddOn.totalPrice,
          status: "a5ca48b8-bfd1-42d6-8db1-7021c131e400",
          remark: "",
          deliveryDate: new Date(),
        };
        orderDetails.push(multiAddOnItem);
      }
    }

    const orderData = {
      salesPerson: props?.customerFormData
        ? props?.customerFormData?.sellerId !== ""
          ? props?.customerFormData?.sellerId
          : Environment.defaultValue
        : Environment.defaultValue, // waiter name
      source: "POS", //Online for web
      otp: "-", // - for dinein
      customerId:
        props?.selectedCustomer === null
          ? Environment.defaultValue
          : props?.selectedCustomer, //selected customer id
      bookingId: Environment.defaultValue, // 000000 for dine in
      branchId: branchData.value, // selected branch id
      addressId:
        props?.selectedCustomerAddress === null
          ? Environment.defaultValue
          : props?.selectedCustomerAddress, // selected add id
      tableId: tableData?.id ? tableData?.id : Environment.defaultValue, // table Id
      orderTypeId: orderType?.id
        ? orderType?.id
        : props?.selectedTab?.tableTypeId, // selected ordertype Id
      // taxType: Environment.defaultValue, // selected tax id
      // kotPrint: 0, // 0 for dinein
      // kotLogPrintCount: 0, // 0 for dinein
      // tokenNumber: 0, // 0 for dinein
      // taxRate: 0, //selected tax rate
      // taxableAmount: totalPriceSum,
      productCount: totalProductCount,
      totalQuantity: totalProductQuantityCount,
      // totalWeight: 0, // 0 for dinein
      // discount: 0, // selected discount percent or amount
      totalPrice: totalPriceSum, // pending - need to add tax as well
      paidAmount: 0, // 0 for dinein
      orderStatus: "a5ca48b8-bfd1-42d6-8db1-7021c131e601", // ordered id from common field
      transactionId: "-", // - for dinein
      paymentStatus: "a5ca48b8-bfd1-42d6-8db1-7021c131e702", // pending payment status id
      paymentMode: "a1ca48b8-bfd1-42d6-8db1-7021c131e001", // cash id from pay mode - but it should be dynamic id of paymode for now taking from commonfields
      // deliveryType: 0,
      // deliveryCharges: 0,
      // deliveryNotes: "-", //-------------------------------------------
      // deliveryDate: "2024-02-03T13:11:44.033Z",
      // confirmedBy: "-", //-------------------------------------------
      // confirmedOn: "2024-02-03T13:11:44.033Z",
      // cancelledOn: "2024-02-03T13:11:44.033Z",
      remark: "", // - for dinein
      memberCount: props?.customerFormData?.guestCount,
      orderDetails: orderDetails,
    };
    dispatch(CreateOrder(orderData));
  };

  // HANDLE APPLY DISCOUNT FUNCTION
  const handleApplyDiscount = (state) => {
    setShowApplyDiscount(state);
  };

  // HANDLE APPLY CHARGES FUNCTION
  const handleApplyCharges = (state) => {
    setShowApplyCharges(state);
  };

  // HANDLE TOGGLE BILL FUNCTION
  const handleToggleBill = () => {
    setToggled(!isToggled);
  };

  // HANDLE SETTLE PAYMENT FUNCTION
  const handleSettlePayment = (state) => {
    if (state) {
      dispatch(PaymentModeListByBranchId(parsedBranchData?.id));
    } else {
      setShowSettlePayment(state);
    }
  };

  // HANDLE CANCEL ORDER FUNCTION
  const handleCancelOrder = () => {
    setIsDataLoaded(true);
    const hasSectionTable = props?.orderTypeList?.find(
      (orderType) => orderType.id === createOrderSuccess.orderTypeId
    )?.isTableSection;
    const formObject = {
      orderId: createOrderSuccess?.id,
      hasSectionTable: hasSectionTable,
      orderTypeId: createOrderSuccess?.orderTypeId,
    };
    dispatch(cancelOrder(formObject));
  };

  // HANDLE DELETE ORDER ITEM FUNCTION
  const handleDeleteOrderedItem = (orderedItemId) => {
    // setIsDataLoaded(true);
    // dispatch(deleteOrderedItem(orderedItemId));
    if (deletedItems && deletedItems.length !== 0) {
      const updatedDeletedItems = [...deletedItems, orderedItemId];
      setDeletedItems(updatedDeletedItems);
      localStorage.setItem("DeletedItems", JSON.stringify(updatedDeletedItems));
    } else {
      setDeletedItems([orderedItemId]);
      localStorage.setItem("DeletedItems", JSON.stringify([orderedItemId]));
    }
  };

  // HANDLE CLOSE ORDER FUNCTION
  const handleCloseOrder = () => {
    setIsDataLoaded(true);
    props?.setOrderLocked(true);
    const hasSectionTable = props?.orderTypeList?.find(
      (orderType) => orderType.id === createOrderSuccess.orderTypeId
    )?.isTableSection;
    const formObject = {
      orderId: createOrderSuccess?.id,
      hasSectionTable: hasSectionTable,
      orderTypeId: createOrderSuccess?.orderTypeId,
    };
    dispatch(closeOrder(formObject));
  };

  // HANDLE MODIFY FUNCTION
  const handleModifyClick = (itemIndex, itemId) => {
    props.onOpenAddVariant({ itemIndex: itemIndex, itemId: itemId });
  };

  // HANDLE KOT PRINT REQUEST FUNCTION
  const handleKOTPrintRequest = (printType, KOTDetails, orderItemLists) => {
    setIsDataLoaded(true);
    const tableName = tableData?.tableName;
    const deletedItemsArr = deletedItems || [];
    if (printType === "cancelledItems") {
      const formObject = {
        salesId: createOrderSuccess?.id,
        deletedOrderIds: deletedItemsArr,
      };
      const formObjectData = {
        formObject: formObject,
        orderTypeId: createOrderSuccess?.orderTypeId,
      };
      dispatch(deleteAllOrderedItem(formObjectData));
      setPrintData({
        KOTDetails,
        orderItemLists,
        tableName,
        sectionName,
        orderType,
        getCustomerDetails,
        printType,
        deletedItemsArr,
      });
    } else if (printType === "kotItems") {
      const formObject = {
        salesId: createOrderSuccess?.id,
        orderKotId: KOTDetails?.id,
        kotNumber: KOTDetails?.kotNumber,
      };
      const formObjectData = {
        formObject: formObject,
        orderTypeId: createOrderSuccess?.orderTypeId,
      };
      dispatch(increKotprintCount(formObjectData));
      setPrintData({
        KOTDetails,
        orderItemLists,
        tableName,
        sectionName,
        orderType,
        getCustomerDetails,
        printType,
        deletedItemsArr,
      });
    }
    setisKOTPrintRequested(true);
  };

  // HANDLE BILL PRINT REQUEST FUNCTION
  const handleBillPrintRequest = () => {
    const tableName = tableData?.tableName;
    const hasSectionTable = props?.orderTypeList?.find(
      (orderType) => orderType.id === createOrderSuccess.orderTypeId
    )?.isTableSection;
    if (hasSectionTable) {
      const formObject = {
        salesId: createOrderSuccess?.id,
        orderStatus: "a5ca48b8-bfd1-42d6-8db1-7021c131e606",
      };
      const formObjectData = {
        formObject: formObject,
        orderTypeId: createOrderSuccess?.orderTypeId,
      };
      dispatch(changeOrderStatus(formObjectData));
    }

    setBillPrintData({
      createOrderSuccess,
      tableName,
      sectionName,
      orderType,
      getCustomerDetails,
    });
    setisBillPrintRequested(true);
  };

  // HANDLE ORDER STATUS CHANGE FUNCTION
  const handleOrderStatusChange = (event) => {
    setSelectedOrderStatus(event.target.value);
  };

  // HANDLE UPDATE ORDER FUNCTION
  const handleUpdateOrder = () => {
    setIsDataLoaded(true);
    handleBillPrintRequest();
    const orderId = createOrderSuccess?.id;
    const orderDetails = [];
    for (let [index, item] of localStorageCartData.entries()) {
      // Main Item
      const mainItem = {
        catalogId: item.itemId,
        sizeVarianceId: item.variantId,
        colorVarianceId: Environment.defaultValue,
        addonId: Environment.defaultValue,
        orderSequence: index + 1,
        isAddOn: false,
        isCustom: false,
        isMandatory: true,
        quantity: item.totalQuantity,
        price: item.varientPrice,
        amount: item.totalVarientPrice,
        status: "a5ca48b8-bfd1-42d6-8db1-7021c131e400",
        remark: item.kotRemark,
        deliveryDate: new Date(),
      };

      orderDetails.push(mainItem);

      // Single Add-ons
      for (const singleAddOn of item.singleAddOnsData || []) {
        const singleAddOnItem = {
          catalogId: item.itemId,
          sizeVarianceId: item.variantId,
          colorVarianceId: Environment.defaultValue,
          addonId: singleAddOn.id,
          orderSequence: index + 1,
          isAddOn: false,
          isCustom: true,
          isMandatory: singleAddOn.isMandatory,
          quantity: 1,
          price: singleAddOn.price,
          amount: singleAddOn.price,
          status: "a5ca48b8-bfd1-42d6-8db1-7021c131e400",
          remark: "",
          deliveryDate: new Date(),
        };

        orderDetails.push(singleAddOnItem);
      }

      // Multi Add-ons
      for (const multiAddOn of item.multiAddOnsData || []) {
        const multiAddOnItem = {
          catalogId: item.itemId,
          sizeVarianceId: item.variantId,
          colorVarianceId: Environment.defaultValue,
          addonId: multiAddOn.id,
          orderSequence: index + 1,
          isAddOn: true,
          isCustom: false,
          isMandatory: multiAddOn.isMandatory,
          quantity: multiAddOn.quantity,
          price: multiAddOn.price,
          amount: multiAddOn.totalPrice,
          status: "a5ca48b8-bfd1-42d6-8db1-7021c131e400",
          remark: "",
          deliveryDate: new Date(),
        };

        orderDetails.push(multiAddOnItem);
      }
    }

    const formData = {
      id: orderId,
      orderData: orderDetails,
      orderTypeId: createOrderSuccess?.orderTypeId,
    };
    dispatch(UpdateOrder(formData));
  };

  // HANDLE SUBMIT FUNCTION
  const handleSubmit = () => {
    setIsDataLoaded(true);
    const formObject = {
      salesId: createOrderSuccess?.id,
      orderStatus: selectedOrderStatus,
    };
    const formObjectData = {
      formObject: formObject,
      orderTypeId: createOrderSuccess?.orderTypeId,
    };
    dispatch(changeOrderStatus(formObjectData));
  };

  return (
    <React.Fragment>
      <ApplyDiscount
        show={showApplyDiscount}
        closeFunction={handleApplyDiscount}
        createOrderSuccess={createOrderSuccess}
      />
      <ApplyCharges
        show={showApplyCharges}
        closeFunction={handleApplyCharges}
        createOrderSuccess={createOrderSuccess}
      />
      <SettlePayment
        show={showSettlePayment}
        closeFunction={handleSettlePayment}
      />

      <div>
        <h3 className={`fs-12 fw-bold text-dark mb-1 added-item-title ${localStorageCartData.length !== 0 ? "d-block" : ""}`}>New Items Added:</h3>
        {(orderRelatedChangesDone || isDataLoaded) && (<div className="card-loader"><Loader /></div>)}

        {/* ADDED ITEM CARD BEGIN */}
        {localStorageCartData && localStorageCartData.length !== 0 && localStorageCartData.map((data, itemIndex) => (
          <>
            <Card
              className={`added-item-card overflow-hidden rounded-0 mb-2 ${newItemAdded && itemIndex === 0 ? "added-item-zoom-in" : ""}`}
              key={data.itemId}>
              <Card.Body key={data?.itemId} className="pl-0 pr-0 pb-0 pt-0">
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col md={6} sm={6}>
                      <h3 className="fs-14 fw-semibold text-dark">
                        {data?.catalogueName} - {data?.variantName}{" "}
                        {data.variantType === "d9c5a952-911e-4a0f-8935-5e1fae01902e" ? data?.sizeId !== Environment.defaultValue
                          ? `(${commonfields?.find((size) => size.id === data.sizeId)?.fieldValue})` : "" : ""}
                      </h3>
                      {data?.kotRemark !== "" && (
                        <h4 className="fs-14 text-dark mt-1"><b>Remark:</b> {data?.kotRemark}</h4>
                      )}
                    </Col>
                    <Col md={6} sm={6}>
                      <div className="text-right item-qty d-flex align-items-center justify-content-end">
                        <div className="qty text-right item-qty">
                          <Button type="button" variant="outline-dark" className="minus" onClick={() => handleMinusClick(itemIndex)}>
                            <i className="ri-subtract-line"></i>
                          </Button>
                          <input type="number" min="1" className="count wt-30" name="qty" value={localStorageCartData[itemIndex]?.totalQuantity} />
                          <Button type="button" variant="outline-dark" className="plus" onClick={() => handlePlusClick(itemIndex)}>
                            <i className="ri-add-line"></i>
                          </Button>
                        </div>
                        <div className="ms-2">
                          <span className="fs-12 text-dark me-2 align-middle">{parsedBranchData?.currencySymbol}{" "}{data?.totalVarAndItemTotal}</span>
                          <Link className="text-danger align-middle" onClick={(e) => deleteItem(e, itemIndex)}>
                            <i className="ri-delete-bin-line fs-16"></i>
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {(() => {
                    const addonGroupWithItems = data?.singleAddOnsData;
                    const uniqueAddonGroups = addonGroupWithItems.reduce(
                      (accumulator, currentItem) => {
                        const existingGroupIndex = accumulator.findIndex(
                          (group) =>
                            group.addOnGroupId === currentItem.addOnGroupId
                        );
                        if (existingGroupIndex === -1) {
                          accumulator.push({
                            addOnGroupId: currentItem.addOnGroupId,
                            groupTitle: currentItem.groupTitle,
                            addons: [currentItem],
                          });
                        } else {
                          accumulator[existingGroupIndex].addons.push(
                            currentItem
                          );
                        }
                        return accumulator;
                      },
                      []
                    );
                    return (
                      <>
                        {uniqueAddonGroups.map((addonGroup) => (
                          <>
                            <h2 className="fs-12 fw-bold text-dark mb-1 mt-2">
                              {addonGroup?.groupTitle}
                            </h2>
                            {addonGroup?.addons?.map((addons) => (
                              <Row className="align-items-center m-0">
                                <Col xl={6} xs={6}>
                                  <h4 className={`fs-12 text-dark `}>
                                    {addons?.itemName}
                                  </h4>
                                </Col>

                                <Col xl={6} xs={6}>
                                  <div className="d-flex align-items-center justify-content-end">
                                    <div>
                                      <b
                                        className={`fs-12 text-dark me-2 align-middle `}
                                      >
                                        {addons.totalQuantity} x{" "}
                                        {parsedBranchData?.currencySymbol}{" "}
                                        {addons.price}
                                      </b>

                                      {!addons?.isMandatory ? (
                                        <Link
                                          className="text-danger align-middle"
                                          onClick={() =>
                                            deleteSingleAddons(
                                              addons?.id,
                                              itemIndex
                                            )
                                          }
                                        >
                                          <i className="ri-delete-bin-line fs-18"></i>
                                        </Link>
                                      ) : (
                                        <>
                                          <Link className="align-middle user-select-none color-graybcb">
                                            <i className="ri-delete-bin-line fs-16"></i>
                                          </Link>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            ))}
                          </>
                        ))}
                      </>
                    );
                  })()}

                  {data?.multiAddOnsData?.length > 0 && (
                    <hr className="mb-0 mt-1" />
                  )}

                  {(() => {
                    const addonGroupWithItems = data?.multiAddOnsData;
                    const uniqueAddonGroups = addonGroupWithItems.reduce(
                      (accumulator, currentItem) => {
                        const existingGroupIndex = accumulator.findIndex(
                          (group) =>
                            group.addOnGroupId === currentItem.addOnGroupId
                        );
                        if (existingGroupIndex === -1) {
                          accumulator.push({
                            addOnGroupId: currentItem.addOnGroupId,
                            groupTitle: currentItem.groupTitle,
                            addons: [currentItem],
                          });
                        } else {
                          accumulator[existingGroupIndex].addons.push(
                            currentItem
                          );
                        }
                        return accumulator;
                      },
                      []
                    );
                    return (
                      <>
                        {uniqueAddonGroups.map((addonGroup) => (
                          <>
                            <h2 className="fs-12 fw-bold text-dark mb-1 mt-2">
                              {addonGroup?.groupTitle}
                            </h2>
                            {addonGroup?.addons?.map((addons) => (
                              <Row className="align-items-center m-0">
                                <Col xl={6} xs={6}>
                                  <h4 className={`fs-12 text-dark `}>
                                    {addons?.itemName}
                                    {addons?.isQuantityAllowed &&
                                      addons?.maxItemAllowed !== 9999 && (
                                        <span className="alert alert-danger mx-1 d-inline-flex my-0 p-1 align-items-center">
                                          <i className="ri-alert-line fs-16 mx-1 my-0"></i>
                                          <span className="fs-10">
                                            Max:{" "}
                                            {addons?.maxItemAllowed.toString()}
                                          </span>
                                        </span>
                                      )}
                                  </h4>
                                </Col>

                                <Col xl={6} xs={6}>
                                  <Row className="align-items-center">
                                    <Col md={8}>
                                      {addons?.isQuantityAllowed && (
                                        <div className="qty item-qty text-right me-2">
                                          <Button
                                            type="button"
                                            variant="outline-dark"
                                            className="minus"
                                            onClick={() =>
                                              handleMultiAddonMinus(
                                                itemIndex,
                                                addons.id
                                              )
                                            }
                                          >
                                            <i className="ri-subtract-line"></i>
                                          </Button>
                                          <input
                                            type="number"
                                            min="1"
                                            className="count"
                                            name="qty"
                                            value={addons.quantity}
                                          />
                                          <Button
                                            style={
                                              addons?.quantity ===
                                                addons.maxItemAllowed
                                                ? {
                                                  background: "#c1c1c1",
                                                }
                                                : {}
                                            }
                                            type="button"
                                            variant="outline-dark"
                                            className="plus"
                                            onClick={() =>
                                              handleMultiAddonPlus(
                                                itemIndex,
                                                addons.id
                                              )
                                            }
                                          >
                                            <i className="ri-add-line"></i>
                                          </Button>
                                        </div>
                                      )}
                                    </Col>
                                    <Col md={4}>
                                      <div className="text-right">
                                        <b
                                          className={`fs-12 text-dark me-2 align-middle `}
                                        >
                                          {addons.quantity} x{" "}
                                          {parsedBranchData?.currencySymbol}{" "}
                                          {addons.price}
                                        </b>

                                        <Link
                                          className="text-danger align-middle"
                                          onClick={() =>
                                            deleteMultiAddons(
                                              addons?.id,
                                              itemIndex
                                            )
                                          }
                                        >
                                          <i className="ri-delete-bin-line fs-16"></i>
                                        </Link>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            ))}
                          </>
                        ))}
                      </>
                    );
                  })()}
                </div>

                {data.singleAddOnsData.filter(
                  (singleAddOns) => singleAddOns?.isMandatory === true
                ).length > 0 && (
                    <div
                      className="text-center p-1 bg-gray-200"
                      onClick={() => handleModifyClick(itemIndex, data.itemId)}
                    >
                      <Link className="fs-14 text-dark fw-semibold">
                        <i className="ri-pencil-line"></i> Modify
                      </Link>
                    </div>
                  )}
              </Card.Body>
            </Card>
          </>
        ))}
        {/* ADDED ITEM CARD ENDED */}

        {createOrderSuccess && createOrderSuccess.length !== 0 && (
          <>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h3 className="fs-14 text-dark fw-semibold">Order Items</h3>
              </div>
              {orderType?.id === "a5ca48b8-bfd1-42d6-8db1-7021c131e003" && (
                <div className="d-flex align-items-center">
                  <Form.Label className="fs-12 me-1 text-nowrap mb-0">
                    Order Status:
                  </Form.Label>
                  <Form.Select
                    className="ht-28 lh-2"
                    value={selectedOrderStatus}
                    onChange={handleOrderStatusChange}
                  >
                    <option value={Environment.defaultValue} disabled>
                      Select Order Status
                    </option>
                    {orderStatus?.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.fieldValue}
                      </option>
                    ))}
                  </Form.Select>
                  <Button
                    variant="danger"
                    className="text-uppercase btn btn-danger ps-2 pe-2 ms-2 ht-28 lh-4 fs-12 text-nowrap"
                    onClick={handleSubmit}
                  >
                    Update Status
                  </Button>
                </div>
              )}
            </div>

            {createOrderSuccess?.orderKOTs
              ?.filter((kot) =>
                createOrderSuccess?.orderItemLists?.some(
                  (item) => item.kotSequence === kot.kotSequence
                )
              )
              .map((kot) => (
                <div key={kot.id}>
                  <div className="mb-2 mt-3 d-flex align-items-center justify-content-between">
                    <h3 className="fs-14 text-dark fw-bold">
                      KOT ID: {parseInt(kot?.createdOn?.slice(8, 10), 10)}
                      {parseInt(kot?.createdOn?.slice(5, 7), 10)}
                      {kot?.createdOn?.slice(2, 4)}/{kot?.todayKOTNumber}
                    </h3>
                    <Link
                      className="fw-12 fw-semibold" onClick={() => { handleKOTPrintRequest("kotItems", kot, createOrderSuccess?.orderItemLists); }}>
                      <span><i className="ri-printer-line fs-16 align-middle"></i>{" "} Print KOT</span>
                    </Link>
                  </div>
                  <div className="table-responsive customize-table-border">
                    {createOrderSuccess?.orderItemLists
                      ?.filter((item) => item.kotSequence === kot.kotSequence)
                      .map((item) => (
                        <>
                          {item.isCustom === false &&
                            item.isAddOn === false && (
                              <>
                                <Table className="table border mb-0">
                                  <tbody
                                    className={`bg-white ${deletedItems.includes(item.id)
                                      ? "deleted-items"
                                      : ""
                                      }`}
                                  >
                                    <tr>
                                      <td className="bg-white p-2 w-75 border-0">
                                        <h3
                                          className="fs-14 text-dark fw-semibold"
                                          style={{ textWrap: "auto" }}
                                        >
                                          {item.catalogueName} -{" "}
                                          <h4 className="fs-14 text-dark d-inline">
                                            <span>
                                              {item.varianceName}{" "}
                                              {item.variantType ===
                                                "d9c5a952-911e-4a0f-8935-5e1fae01902e"
                                                ? item?.sizeId !==
                                                  Environment.defaultValue
                                                  ? `(${commonfields?.find(
                                                    (size) =>
                                                      size.id ===
                                                      item.sizeId
                                                  )?.fieldValue
                                                  })`
                                                  : ""
                                                : ""}
                                            </span>
                                          </h4>
                                        </h3>
                                        {item?.remark !== "" && (
                                          <h4 className="fs-12 text-dark">
                                            <b>Remark: </b> {item.remark}
                                          </h4>
                                        )}
                                      </td>
                                      <td
                                        className="bg-white border-0"
                                        style={{ verticalAlign: "baseline" }}
                                      >
                                        <div className="d-flex align-items-center justify-content-end">
                                          <div>
                                            <span className="fs-14 text-dark fw-bold me-2 align-middle">
                                              {item.quantity} x{" "}
                                              {parsedBranchData?.currencySymbol}{" "}
                                              {item.price}
                                            </span>
                                            {item.isDeleted === false &&
                                              item.isCustom === false &&
                                              item.isAddOn === false &&
                                              item.kotSequence ===
                                              kot.kotSequence &&
                                              !(
                                                createOrderSuccess &&
                                                createOrderSuccess.length !== 0 &&
                                                localStorageCartData.length ===
                                                0 &&
                                                createOrderSuccess?.paymentStatus ===
                                                "a5ca48b8-bfd1-42d6-8db1-7021c131e701" &&
                                                props?.orderLocked
                                              ) ? (
                                              <Link
                                                className="text-danger align-middle"
                                                onClick={() =>
                                                  handleDeleteOrderedItem(
                                                    item?.id
                                                  )
                                                }
                                              >
                                                <i className="ri-delete-bin-line fs-18"></i>
                                              </Link>
                                            ) : (
                                              <>
                                                <Link className="align-middle user-select-none color-graybcb">
                                                  <i className="ri-delete-bin-line fs-16"></i>
                                                </Link>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>

                                    {(() => {
                                      const addonGroupWithItems =
                                        createOrderSuccess?.orderItemLists?.filter(
                                          (addon) =>
                                            addon.isAddOn === false &&
                                            addon.isCustom === true &&
                                            addon.orderSequence ===
                                            item.orderSequence &&
                                            addon.kotSequence ===
                                            item.kotSequence
                                        );
                                      if (addonGroupWithItems.length === 0) {
                                        return null;
                                      }
                                      const uniqueAddonGroups =
                                        addonGroupWithItems.reduce(
                                          (accumulator, currentItem) => {
                                            const existingGroupIndex =
                                              accumulator.findIndex(
                                                (group) =>
                                                  group.addOnGroupId ===
                                                  currentItem.addOnGroupId
                                              );
                                            if (existingGroupIndex === -1) {
                                              accumulator.push({
                                                addOnGroupId:
                                                  currentItem.addOnGroupId,
                                                addOnGroupName:
                                                  currentItem.addOnGroupName,
                                                addons: [currentItem],
                                              });
                                            } else {
                                              accumulator[
                                                existingGroupIndex
                                              ].addons.push(currentItem);
                                            }
                                            return accumulator;
                                          },
                                          []
                                        );
                                      return (
                                        <>
                                          <tr>
                                            <td
                                              colSpan={2}
                                              className="bg-white p-2"
                                            >
                                              {/* ================================== */}
                                              <div>
                                                {uniqueAddonGroups.map(
                                                  (addonGroup) => (
                                                    <>
                                                      <h2 className="fs-14 fw-bold text-dark border-0">
                                                        {
                                                          addonGroup?.addOnGroupName
                                                        }
                                                      </h2>
                                                      {addonGroup?.addons?.map(
                                                        (addons) => (
                                                          <>
                                                            <div className="d-flex align-items-center justify-content-between mb-1">
                                                              <h4
                                                                className={`fs-14 text-dark ${!deletedItems.includes(
                                                                  item.id
                                                                ) &&
                                                                  deletedItems.includes(
                                                                    addons.id
                                                                  )
                                                                  ? "deleted-addons"
                                                                  : ""
                                                                  }`}
                                                              >
                                                                {
                                                                  addons.addOnName
                                                                }
                                                              </h4>
                                                              <div>
                                                                <b
                                                                  className={`fs-14 text-dark me-2 align-middle ${!deletedItems.includes(
                                                                    item.id
                                                                  ) &&
                                                                    deletedItems.includes(
                                                                      addons.id
                                                                    )
                                                                    ? "deleted-addons"
                                                                    : ""
                                                                    }`}
                                                                >
                                                                  {
                                                                    addons.quantity
                                                                  }{" "}
                                                                  x{" "}
                                                                  {
                                                                    parsedBranchData?.currencySymbol
                                                                  }{" "}
                                                                  {addons.price}
                                                                </b>

                                                                {addons.isMandatory ===
                                                                  false &&
                                                                  addons.isDeleted ===
                                                                  false &&
                                                                  item.isDeleted ===
                                                                  false &&
                                                                  !(
                                                                    createOrderSuccess &&
                                                                    createOrderSuccess.length !==
                                                                    0 &&
                                                                    localStorageCartData.length ===
                                                                    0 &&
                                                                    createOrderSuccess?.paymentStatus ===
                                                                    "a5ca48b8-bfd1-42d6-8db1-7021c131e701" &&
                                                                    props?.orderLocked
                                                                  ) ? (
                                                                  <Link
                                                                    className="text-danger align-middle"
                                                                    onClick={() =>
                                                                      handleDeleteOrderedItem(
                                                                        addons?.id
                                                                      )
                                                                    }
                                                                  >
                                                                    <i className="ri-delete-bin-line fs-16"></i>
                                                                  </Link>
                                                                ) : (
                                                                  <>
                                                                    <Link className="align-middle user-select-none color-graybcb">
                                                                      <i className="ri-delete-bin-line fs-16"></i>
                                                                    </Link>
                                                                  </>
                                                                )}
                                                              </div>
                                                            </div>
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  )
                                                )}
                                              </div>
                                              {/* ================================== */}
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    })()}

                                    {(() => {
                                      const addonGroupWithItems =
                                        createOrderSuccess?.orderItemLists?.filter(
                                          (addon) =>
                                            addon.isAddOn === true &&
                                            addon.isCustom === false &&
                                            addon.orderSequence ===
                                            item.orderSequence &&
                                            addon.kotSequence ===
                                            item.kotSequence
                                        );

                                      if (addonGroupWithItems.length === 0) {
                                        return null;
                                      }

                                      const uniqueAddonGroups =
                                        addonGroupWithItems.reduce(
                                          (accumulator, currentItem) => {
                                            const existingGroupIndex =
                                              accumulator.findIndex(
                                                (group) =>
                                                  group.addOnGroupId ===
                                                  currentItem.addOnGroupId
                                              );
                                            if (existingGroupIndex === -1) {
                                              accumulator.push({
                                                addOnGroupId:
                                                  currentItem.addOnGroupId,
                                                addOnGroupName:
                                                  currentItem.addOnGroupName,
                                                addons: [currentItem],
                                              });
                                            } else {
                                              accumulator[
                                                existingGroupIndex
                                              ].addons.push(currentItem);
                                            }
                                            return accumulator;
                                          },
                                          []
                                        );

                                      return (
                                        <>
                                          {uniqueAddonGroups.map(
                                            (addonGroup) => (
                                              <tr key={addonGroup.addOnGroupId}>
                                                <td
                                                  colSpan={2}
                                                  className="border-top pt-1 pb-1"
                                                  style={{
                                                    backgroundColor: "#efefef",
                                                  }}
                                                >
                                                  <h2 className="fs-12 fw-bold text-dark border-0">
                                                    {addonGroup?.addOnGroupName}
                                                  </h2>
                                                  {addonGroup?.addons?.map(
                                                    (addons) => (
                                                      <div
                                                        key={addons.id}
                                                        className="d-flex align-items-center justify-content-between"
                                                      >
                                                        <div className="p-0 ps-2">
                                                          <h4
                                                            className={`fs-14 text-dark ${!deletedItems.includes(
                                                              item.id
                                                            ) &&
                                                              deletedItems.includes(
                                                                addons.id
                                                              )
                                                              ? "deleted-addons"
                                                              : ""
                                                              }`}
                                                          >
                                                            {addons.addOnName}
                                                          </h4>
                                                        </div>
                                                        <div>
                                                          <div className="d-flex align-items-center justify-content-end">
                                                            <div>
                                                              <b
                                                                className={`fs-12 text-dark me-2 align-middle ${!deletedItems.includes(
                                                                  item.id
                                                                ) &&
                                                                  deletedItems.includes(
                                                                    addons.id
                                                                  )
                                                                  ? "deleted-addons"
                                                                  : ""
                                                                  }`}
                                                              >
                                                                {
                                                                  addons.quantity
                                                                }{" "}
                                                                x{" "}
                                                                {
                                                                  parsedBranchData?.currencySymbol
                                                                }{" "}
                                                                {addons.price}
                                                              </b>
                                                              {addons.isMandatory ===
                                                                false &&
                                                                addons.isDeleted ===
                                                                false &&
                                                                item.isDeleted ===
                                                                false &&
                                                                !(
                                                                  createOrderSuccess &&
                                                                  createOrderSuccess.length !==
                                                                  0 &&
                                                                  localStorageCartData.length ===
                                                                  0 &&
                                                                  createOrderSuccess?.paymentStatus ===
                                                                  "a5ca48b8-bfd1-42d6-8db1-7021c131e701" &&
                                                                  props?.orderLocked
                                                                ) ? (
                                                                <Link
                                                                  className="text-danger align-middle"
                                                                  onClick={() =>
                                                                    handleDeleteOrderedItem(
                                                                      addons?.id
                                                                    )
                                                                  }
                                                                >
                                                                  <i className="ri-delete-bin-line fs-16"></i>
                                                                </Link>
                                                              ) : (
                                                                <Link className="align-middle user-select-none color-graybcb">
                                                                  <i className="ri-delete-bin-line fs-16"></i>
                                                                </Link>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </>
                                      );
                                    })()}
                                  </tbody>
                                </Table>
                              </>
                            )}
                        </>
                      ))}
                  </div>
                </div>
              ))}
          </>
        )}

        {/* MY CARD FOOTER */}
        <div className="my-card-footer d-block order-added-modal-footer p-0 h-auto border-top-0">
          {createOrderSuccess &&
            createOrderSuccess.length !== 0 &&
            localStorageCartData.length === 0 &&
            deletedItems &&
            deletedItems.length === 0 && (
              <div
                className={`total-bills-bottom ${isToggled ? "toggled" : ""}`}
              >
                <ul className="totla-bills-list">
                  <li>
                    <span>
                      Price ({createOrderSuccess?.productCount} Items)
                    </span>
                    <b>
                      {parsedBranchData?.currencySymbol}{" "}
                      {createOrderSuccess?.totalOrderPrice}
                    </b>
                  </li>
                  {createOrderSuccess?.orderDiscounts !== null ? (
                    <li>
                      <span>
                        Discount{" "}
                        {createOrderSuccess?.orderDiscounts?.discountTypeId ===
                          "d9c5a952-911e-4a0f-8935-5e1fae01802e" && (
                            <span>
                              ({createOrderSuccess?.orderDiscounts?.discountRate}
                              {createOrderSuccess?.orderDiscounts
                                ?.discountTypeId ===
                                "d9c5a952-911e-4a0f-8935-5e1fae01802e"
                                ? "%"
                                : ""}{" "}
                              Applied)
                            </span>
                          )}
                      </span>
                      <b>
                        {parsedBranchData?.currencySymbol}{" "}
                        {createOrderSuccess?.discountAmount}
                      </b>
                    </li>
                  ) : (
                    createOrderSuccess?.discountAmount !== 0 && (
                      <li>
                        <span>Discount</span>
                        <b>
                          {parsedBranchData?.currencySymbol}{" "}
                          {createOrderSuccess?.discountAmount}
                        </b>
                      </li>
                    )
                  )}
                  <li>
                    <span>
                      {createOrderSuccess?.globalTaxGroup?.taxGroupName} (
                      {createOrderSuccess?.globalTaxGroup?.taxRate}% Applied)
                    </span>
                    <b>
                      {parsedBranchData?.currencySymbol}{" "}
                      {createOrderSuccess?.taxAmount}
                    </b>
                  </li>
                  {createOrderSuccess?.orderCharges &&
                    createOrderSuccess?.orderCharges?.length !== 0 && (
                      <>
                        {createOrderSuccess?.orderCharges?.map(
                          (chargesApplied) => (
                            <li>
                              <span>
                                {chargesApplied?.chargeName}{" "}
                                {chargesApplied.chargeType ===
                                  "d9c5a952-911e-4a0f-8935-5e1fae01702e" && (
                                    <span>
                                      ({chargesApplied?.fixedRate}
                                      {chargesApplied?.chargeType ===
                                        "d9c5a952-911e-4a0f-8935-5e1fae01702e"
                                        ? "%"
                                        : ""}{" "}
                                      Applied)
                                    </span>
                                  )}
                              </span>
                              <b>
                                {chargesApplied.chargeType ===
                                  "d9c5a952-911e-4a0f-8935-5e1fae01702e"
                                  ? `${parsedBranchData?.currencySymbol} ${(
                                    (chargesApplied?.fixedRate / 100) *
                                    createOrderSuccess?.totalOrderPrice
                                  ).toFixed(2)}`
                                  : chargesApplied.chargeType ===
                                    "d9c5a952-911e-4a0f-8935-5e1fae01701e"
                                    ? `${parsedBranchData?.currencySymbol} ${chargesApplied?.fixedRate}`
                                    : ""}
                              </b>
                            </li>
                          )
                        )}
                      </>
                    )}
                </ul>
                <div className="d-flex align-items-center justify-content-between">
                  <h3 className="fs-14">Total Bill: </h3>
                  <Link
                    className="totla-bills-toggle text-dark fs-16 gap-2 fw-bold"
                    onClick={() => handleToggleBill()}
                  >
                    {parsedBranchData?.currencySymbol}{" "}
                    {createOrderSuccess?.grandTotalPrice}{" "}
                    <span
                      className={
                        createOrderSuccess.paymentStatus ===
                          "a5ca48b8-bfd1-42d6-8db1-7021c131e701"
                          ? "text-success"
                          : "text-danger"
                      }
                    >
                      (
                      {createOrderSuccess.paymentStatus ===
                        "a5ca48b8-bfd1-42d6-8db1-7021c131e701"
                        ? "Paid"
                        : "Not Paid"}
                      )
                    </span>
                    <i className="ri-arrow-drop-down-line fw-normal"></i>
                  </Link>
                </div>
              </div>
            )}

          {createOrderSuccess === undefined &&
            localStorageCartData.length > 0 && (
              <>
                <Button
                  variant="secondary"
                  className="fs-14 text-uppercase w-40"
                  onClick={() => clearTableCartData()}
                >
                  Discard
                </Button>
                <Button
                  type="button"
                  variant="success"
                  className="fs-14 text-uppercase w-60"
                  onClick={() => handleCreateOrder()}
                >
                  Create Order
                </Button>
              </>
            )}

          {createOrderSuccess &&
            createOrderSuccess.length !== 0 &&
            localStorageCartData.length === 0 &&
            createOrderSuccess?.paymentStatus ===
            "a5ca48b8-bfd1-42d6-8db1-7021c131e702" &&
            deletedItems &&
            deletedItems.length === 0 && (
              <ButtonGroup>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="dark"
                    className="btn-icon custom-dropdown-action w-auto h-auto d-block"
                    id="dropdown-basic"
                  >
                    <i className="ri-more-2-fill fs-18"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="fw-bold"
                      href="#"
                      onClick={() => handleApplyDiscount(true)}
                    >
                      Apply Discount
                    </Dropdown.Item>
                    <hr className="m-0" />
                    <Dropdown.Item
                      className="fw-bold"
                      href="#"
                      onClick={() => handleApplyCharges(true)}
                    >
                      Additional Charges
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Button
                  variant="primary"
                  className="btn-icon order-print-bttn"
                  onClick={() => handleBillPrintRequest()}
                >
                  <i className="ri-printer-line fs-16"></i>
                </Button>
                <Button variant="danger" onClick={() => handleCancelOrder()}>
                  {/* <i class="lh-1 ri-close-circle-line fs-14 pt-3"></i> */}
                  Cancel Order
                </Button>
                <Button
                  variant="success"
                  onClick={() => handleSettlePayment(true)}
                >
                  Settle Bill
                </Button>
              </ButtonGroup>
            )}

          {deletedItems &&
            deletedItems.length === 0 &&
            createOrderSuccess &&
            createOrderSuccess.length !== 0 &&
            localStorageCartData.length > 0 && (
              <ButtonGroup>
                <Button
                  variant="secondary w-20"
                  onClick={() => clearTableCartData()}
                >
                  Discard Changes
                </Button>
                <Button variant="success" onClick={() => handleUpdateOrder()}>
                  Update Order
                </Button>
              </ButtonGroup>
            )}

          {deletedItems &&
            deletedItems.length !== 0 &&
            localStorageCartData.length === 0 && (
              <ButtonGroup>
                <Button
                  variant="primary"
                  onClick={() => clearLocalDeletedItems()}
                >
                  Discard Changes
                </Button>
                <Button
                  variant="success"
                  onClick={() =>
                    handleKOTPrintRequest(
                      "cancelledItems",
                      -1,
                      createOrderSuccess
                    )
                  }
                >
                  Save & Print KOT
                </Button>
              </ButtonGroup>
            )}

          {deletedItems &&
            deletedItems.length !== 0 &&
            createOrderSuccess &&
            createOrderSuccess.length !== 0 &&
            localStorageCartData.length > 0 && (
              <ButtonGroup>
                <Button
                  variant="primary"
                  onClick={() => clearLocalDeletedItems()}
                >
                  Discard Changes
                </Button>
                <Button
                  variant="success"
                  onClick={() => {
                    handleKOTPrintRequest("cancelledItems", -1, deletedItems);
                    handleUpdateOrder();
                  }}
                >
                  Update & KOT Print
                </Button>
              </ButtonGroup>
            )}

          {createOrderSuccess &&
            createOrderSuccess.length !== 0 &&
            localStorageCartData.length === 0 &&
            createOrderSuccess?.paymentStatus ===
            "a5ca48b8-bfd1-42d6-8db1-7021c131e701" &&
            deletedItems &&
            deletedItems.length === 0 && (
              <ButtonGroup>
                <Button
                  variant="primary"
                  onClick={() => handleBillPrintRequest()}
                >
                  Print Bill
                </Button>
                <Button variant="dark" onClick={() => handleCloseOrder()}>
                  Close Order
                </Button>
              </ButtonGroup>
            )}
        </div>

        {isKOTPrintRequested &&
          !loading &&
          (value === "updateOrder" ||
            value === "deleteAllOrderedItem" ||
            value === "createOrder" ||
            value === "increKotprintCount") && (
            <ComponentToPrint
              printData={printData}
              onPrintRequested={() => setisKOTPrintRequested(false)}
            />
          )}
        {isBillPrintRequested && (
          <BillPrint
            billPrintData={billPrintData}
            onBillPrintRequested={() => setisBillPrintRequested(false)}
          />
        )}
      </div>
      {/* END ORDER MODAL */}
      <Modal
        show={showEndOrder}
        onHide={() => handleClose1()}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-16 text-dark">Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2 className="fs-14 fw-semibold text-dark text-center mt-2 mb-2">
            Are you sure you want to Add the items in Table <b>T2 ?</b>
          </h2>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            className="fs-14 ht-36"
            onClick={() => handleClose1()}
          >
            Cancel
          </Button>
          <Button variant="danger" className="fs-14 ht-36">
            Close Order
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
