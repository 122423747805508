import {
  ADD_VARIANCE,
  GET_VARIANCE_BY_ITEM_ID,
  GetVarianceByIdSuccess,
  RESET_ALL_BRANCHES,
  RESET_ALL_BY_BRANCH_ID,
  SET_VARIANT_MODE,
} from "../actions/varianceAction";
import * as Loading from "../actions/loaderAction";
import * as variantAction from "../actions/varianceAction";
import { Navigate } from "react-router-dom";
import ToastifyService from "../../View/_common/ToastifyService";

const GetVarianceList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_VARIANCE_BY_ITEM_ID) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "getVarianceList" })
        );
        const VarianceData = await api.varianceAPI.VarianceSizeList(
          action.payload.data
        );
        dispatch(variantAction.GetVarianceByIdSuccess(VarianceData.result));
        dispatch(
          Loading.setLoading({ loading: false, value: "getVarianceList" })
        );
      } catch (error) {
        dispatch(
          Loading.setLoading({ loading: false, value: "getVarianceList" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const AddVariance =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_VARIANCE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "getVarianceList" }));
        const VarianceList = await api.varianceAPI.AddVariance(
          action.payload.data
        );
        dispatch(GetVarianceByIdSuccess(VarianceList.result));
        dispatch(Loading.setLoading({ loading: false, value: "getVarianceList" }));
      } catch (error) {
        console.log(error);
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "getVarianceList" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    if (action.type === variantAction.COPY_VARIANCE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const VarianceList = await api.varianceAPI.CopyVariance(
          action.payload.data
        );
        dispatch(GetVarianceByIdSuccess(VarianceList.result));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const SetVariantModeType =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === SET_VARIANT_MODE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const VariantType = await api.varianceAPI.SetVariantModeType(
          action.payload.data
        );
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const ResetAllBranches =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === RESET_ALL_BRANCHES) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const VarianceList = await api.varianceAPI.ResetAllBranches(
          action.payload
        );
        dispatch(GetVarianceByIdSuccess(VarianceList.result));
        ToastifyService.success("All branches has been Reset Successfully!");

        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const ResetAllByBranchId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === RESET_ALL_BY_BRANCH_ID) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const VarianceList = await api.varianceAPI.ResetAllByBranchId(
          action.payload.data
        );
        ToastifyService.success("Branches has been Reset Successfully!");
        dispatch(GetVarianceByIdSuccess(VarianceList.result));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const BranchVarianceFeaturesUpdate =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === variantAction.BRANCH_VAR_FEATURES_UPDATE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "varFeatureUpdate" }));
        const VarianceList = await api.varianceAPI.BranchVarianceFeaturesUpdate(
          action.payload.data
        );
        dispatch(GetVarianceByIdSuccess(VarianceList.result));
        ToastifyService.success("Branch Features Updated Successfully!");
        dispatch(Loading.setLoading({ loading: false, value: "varFeatureUpdate" }));
      } catch (error) {
        console.log(error);
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "varFeatureUpdate" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const GetCommonFieldsTypeByGroupCode =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === variantAction.COMMON_FIELD_TYPE_BY_GROUP_CODE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const CommonFieldType =
          await api.varianceAPI.GetCommonFieldsTypeByGroupCode(action.payload);
        dispatch(
          variantAction.CommonFieldsTypeByGroupCodeSuccess(CommonFieldType)
        );
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const MapBranchVariance =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === variantAction.MAP_BRANCH_VARIANCE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const VarianceList = await api.varianceAPI.MapBranchVariance(
          action.payload
        );
        dispatch(GetVarianceByIdSuccess(VarianceList.result));
        ToastifyService.success("Operation Completed  Successfully!");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UnMapBranchVariance =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === variantAction.UNMAP_BRANCH_VARIANCE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const VarianceList = await api.varianceAPI.UnMapBranchVariance(
          action.payload
        );
        dispatch(GetVarianceByIdSuccess(VarianceList.result));
        ToastifyService.success("Operation Completed  Successfully!");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UnMapGlobalVarianceById =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === variantAction.UNMAP_GLOBAL_VARIANCE_BY_ID) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const VarianceList = await api.varianceAPI.UnMapGlobalVarianceById(
          action.payload
        );
        dispatch(GetVarianceByIdSuccess(VarianceList.result));
        ToastifyService.success("Operation Completed  Successfully!");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const UpdateBranchVariance =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === variantAction.UPDATE_BRANCH_VARIANCE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const VarianceList = await api.varianceAPI.UpdateBranchVariance(
          action.payload
        );
        dispatch(GetVarianceByIdSuccess(VarianceList.result));
        ToastifyService.success("Operation Completed  Successfully!");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
export default [
  UpdateBranchVariance,
  UnMapGlobalVarianceById,
  UnMapBranchVariance,
  GetVarianceList,
  AddVariance,
  ResetAllBranches,
  ResetAllByBranchId,
  SetVariantModeType,
  BranchVarianceFeaturesUpdate,
  GetCommonFieldsTypeByGroupCode,
  MapBranchVariance,
];
