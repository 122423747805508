import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Offcanvas, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CreateMaterial } from "../../../../application/actions/materialAction";
import AuthContext from "../../../../infrastructure/core/helpers/AuthContext";
import environment from "../../../../infrastructure/core/Environment";

export function AddNewMaterial(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const MeasurementList = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "MSRMNT"
      )
    : [];
  const materialTypeList = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "MATTY"
      )
    : [];

  const { user } = useContext(AuthContext);
  const initialState = {
    tenantId: "",
    branchId: "",
    isVisible: true,
    isDeleted: false,
    createdBy: "",
    productName: "",
    productDescription: "-",
    typeId: `${environment.defaultValue}`,
    stockMeasuredIn: `${environment.defaultValue}`,
  };
  const [formData, setFormData] = useState(initialState);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formObject = {
      tenantId: user?.tenanatId,
      branchId: user.branchId,
      isVisible: formData.isVisible,
      isDeleted: formData.isDeleted,
      createdBy: user.id,
      productName: formData.productName,
      productDescription: formData.productDescription,
      typeId: formData.typeId,
      stockMeasuredIn: formData.stockMeasuredIn,
    };
    dispatch(CreateMaterial(formObject));
    setFormData(initialState);
    props.closeFunction(false);
  };

  return (
    <React.Fragment>
      <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-30">
        <Offcanvas.Header
          closeButton>
          <Offcanvas.Title>Add New Material</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <div className="mb-3">
            <Form.Label>
              Name: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              name="productName"
              value={formData.productName}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <Form.Label>Material Type</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="typeId"
              value={formData.typeId}
              onChange={handleChange}
            >
              <option value={`${environment.defaultValue}`} disabled>
                Choose Material Type
              </option>
              {materialTypeList.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.fieldValue}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="mb-3">
            <Form.Label>Stock Out Measured In</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="stockMeasuredIn"
              value={formData.stockMeasuredIn}
              onChange={handleChange}
            >
              <option value={`${environment.defaultValue}`} disabled>
                Choose Measured In
              </option>
              {MeasurementList.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.fieldValue}
                </option>
              ))}
            </Form.Select>
          </div>
        </Offcanvas.Body>

        <div className="offcanvas-footer bg-white">
          <Button
            variant="primary"
            className="fs-14 d-flex align-items-center"
            onClick={handleSubmit}
          >
            <i className="ri-add-line fs-16 lh-1 align-middle me-1"></i>
            <span className="align-middle">Create New Material</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
