import Environment from "../../../core/Environment";
import jwtInterceptor from "./../../../core/helpers/jwtInterceptor";

const GetItemCatalog = async () => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));

  const response = await jwtInterceptor.get(`${Environment.Catalogue_Url}All`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    },
  });
  return response.data;
};
const GetItemCatalogPagination = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}all-catalogue`,
    data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};

const CreateItemCatalog = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}add-catalogue`,
    data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};

const UpdateItemCatalog = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));

  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}update-catalogue`,
    data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );

  return response.data;
};
const GetCatalogueItemById = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.Catalogue_Url}catalogue-detail/${data}`,
    {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const GetCatalogueByCatId = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.Catalogue_Url}catalogue-by-category/${data}`,
    {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const GetAddOnsList = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.Catalogue_Url}catalogue-addons-groups`,
    {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const DeleteItem = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}delete-catalogue/${data}`,
    {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const MapAddOnItem = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}map-addon-catalogue`,
    data,
    {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const UnMapAddOnItem = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}unmap-addon-catalogue/${data}`,
    {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const GetAddOnsMappedList = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.Catalogue_Url}addon-group-by-catalogue/${data}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const Imgaeupload = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const formData = new FormData();
  formData.append("id", data.id);
  formData.append("modelName", data.foldername);
  formData.append("image", data.image);
  const response = await jwtInterceptor.post(
    `${Environment.Setting_Url}upload-image`,
    formData,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );

  return response.data;
};
const ImageGalleryUpload = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const formData = new FormData();
  formData.append("Id", data.id);
  formData.append("ModelName", data.foldername);
  // formData.append("image", data.image);
  for(let i =0 ;i < data.images.length ;i++){
    formData.append(`GalleryImage `, data.images[i])
  }
  
  formData.forEach((value, key) => {
    console.log(key, value);
  });


  const response = await jwtInterceptor.post(
    `${Environment.Setting_Url}upload-image-gallery`,
    formData,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
    
  );

  return response.data;
};
const IsCatalogueCodeExist = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));

  const response = await jwtInterceptor.get(
    `${Environment.Catalogue_Url}catalogue-code`,
    {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const UpdateItemTaxGroup = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}update-catalogue-tax`,
    data,
    {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const UpdateCatalogueAction = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}update-catalogue-action`,
    data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );

  return response.data;
};
const UpdateAddOnsMode = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}update-catalogue-addon-mode/${data}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );

  return response.data;
};
const UpdateCatalogFeatures = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}update-catalogue-features`,
    data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );

  return response.data;
};
const UpdateKOTMode = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}update-catalogue-addon-mode/${data}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );

  return response.data;
};

export default {
  GetItemCatalog,
  CreateItemCatalog,
  GetCatalogueItemById,
  GetAddOnsList,
  UpdateItemCatalog,
  DeleteItem,
  UpdateAddOnsMode,
  UpdateCatalogFeatures,
  UpdateKOTMode,
  ImageGalleryUpload,
  GetItemCatalogPagination,
  MapAddOnItem,
  GetAddOnsMappedList,
  Imgaeupload,
  GetCatalogueByCatId,
  IsCatalogueCodeExist,
  UpdateItemTaxGroup,
  UpdateCatalogueAction,
  UnMapAddOnItem,
};
